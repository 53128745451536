import { memo } from 'react';
import { Outlet } from 'react-router-dom';
import { ErrorBoundary } from 'theRising-baseComponents';
import styles from './mainLayout.module.scss';
import Header from './header/Header';
import SideMenu from './sideMenu/SideMenu';

function MainLayout() {
    return (
        <div className={styles.mainLayout}>
            <Header />
            <div className={styles.content}>
                <div className={styles.sideMenuWrapper}>
                    <SideMenu />
                </div>
                <div className={styles.dynamicContentWrapper}>
                    <ErrorBoundary>
                        <Outlet />
                    </ErrorBoundary>
                </div>
            </div>
        </div>
    );
}

export default memo(MainLayout);
