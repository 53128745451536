/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-magic-numbers */
import { useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { useTranslate } from '@i18n';
import styles from './baseError.module.scss';

type BaseErrorPropsType = {
    errorCode: number,
    errorTitle?: string,
    errorMessage: string,
    isShowRefreshButton?: boolean,
    isShowReturnHomeButton?: boolean,
};

export default function BaseError(props: BaseErrorPropsType): React.ReactElement {
    const { errorCode, errorTitle = 'Hmmm...', errorMessage, isShowRefreshButton, isShowReturnHomeButton } = props;
    const visorRef = useRef<HTMLCanvasElement>(null);
    const cordRef = useRef<HTMLCanvasElement>(null);

    const { translater } = useTranslate();
    const navigate = useNavigate();
    const location = useLocation();

    const isDisabledBackToMainPage = useMemo(() => location.pathname === '/', [location.pathname]);

    //animációk
    useEffect(() => {
        function drawVisor() {
            const canvas = visorRef.current;
            const ctx = canvas?.getContext('2d') as CanvasRenderingContext2D;

            ctx.beginPath();
            ctx.moveTo(5, 45);
            ctx.bezierCurveTo(15, 64, 45, 64, 55, 45);

            ctx.lineTo(55, 20);
            ctx.bezierCurveTo(55, 15, 50, 10, 45, 10);

            ctx.lineTo(15, 10);

            ctx.bezierCurveTo(15, 10, 5, 10, 5, 20);
            ctx.lineTo(5, 45);

            ctx.fillStyle = '#2f3640';
            ctx.strokeStyle = '#f5f6fa';
            ctx.fill();
            ctx.stroke();
        }

        const cordCanvas = cordRef.current;
        const ctx = cordCanvas?.getContext('2d') as CanvasRenderingContext2D;

        let y1 = 160;
        let y2 = 100;
        let y3 = 100;

        let y1Forward = true;
        let y2Forward = false;
        let y3Forward = true;

        function animate() {
            requestAnimationFrame(animate);

            ctx.clearRect(0, 0, innerWidth, innerHeight);

            ctx.beginPath();
            ctx.moveTo(130, 170);
            ctx.bezierCurveTo(250, y1, 345, y2, 400, y3);

            ctx.strokeStyle = 'white';
            ctx.lineWidth = 8;
            ctx.stroke();

            if (y1 === 100) {
                y1Forward = true;
            }

            if (y1 === 300) {
                y1Forward = false;
            }

            if (y2 === 100) {
                y2Forward = true;
            }

            if (y2 === 310) {
                y2Forward = false;
            }

            if (y3 === 100) {
                y3Forward = true;
            }

            if (y3 === 317) {
                y3Forward = false;
            }

            y1Forward ? y1 += 1 : y1 -= 1;
            y2Forward ? y2 += 1 : y2 -= 1;
            y3Forward ? y3 += 1 : y3 -= 1;
        }

        drawVisor();
        animate();
    }, []);

    return (
        <div className={styles.errorWrapper}>
            <div className={styles.moon} />
            <div className={clsx(styles.moon__crater, styles.moon__crater1)} />
            <div className={clsx(styles.moon__crater, styles.moon__crater2)} />
            <div className={clsx(styles.moon__crater, styles.moon__crater3)} />

            <div className={clsx(styles.star, styles.star1)} />
            <div className={clsx(styles.star, styles.star2)} />
            <div className={clsx(styles.star, styles.star3)} />
            <div className={clsx(styles.star, styles.star4)} />
            <div className={clsx(styles.star, styles.star5)} />

            <div className={styles.error}>
                <div className={styles.error__title}>{errorCode}</div>
                <div className={styles.error__subtitle}>{errorTitle}</div>
                <div className={styles.error__description}>{errorMessage}</div>
                {isShowRefreshButton && (
                    <button
                        className={clsx(styles.error__button, isDisabledBackToMainPage && styles.disabledErrorButton)}
                        type="button"
                        onClick={() => navigate('/')}
                        disabled={isDisabledBackToMainPage}
                    >
                        {translater('errorScreen.refreshPage', 'Refresh page')}
                    </button>
                )}
                {isShowReturnHomeButton && (
                    <button
                        className={clsx(styles.error__button, isDisabledBackToMainPage && styles.disabledErrorButton)}
                        type="button"
                        onClick={() => navigate('/')}
                        disabled={isDisabledBackToMainPage}
                    >
                        {translater('errorScreen.backToMainPage', 'Back to the main page')}
                    </button>
                )}
            </div>

            <div className={styles.astronaut}>
                <div className={styles.astronaut__backpack} />
                <div className={styles.astronaut__body} />
                <div className={styles.astronaut__body__chest} />
                <div className={styles.astronaut__arm_left1} />
                <div className={styles.astronaut__arm_left2} />
                <div className={styles.astronaut__arm_right1} />
                <div className={styles.astronaut__arm_right2} />
                <div className={styles.astronaut__arm_thumb_left} />
                <div className={styles.astronaut__arm_thumb_right} />
                <div className={styles.astronaut__leg_left} />
                <div className={styles.astronaut__leg_right} />
                <div className={styles.astronaut__foot_left} />
                <div className={styles.astronaut__foot_right} />
                <div className={styles.astronaut__wrist_left} />
                <div className={styles.astronaut__wrist_right} />

                <div className={styles.astronaut__cord}>
                    <canvas id="cord" height="500px" width="500px" ref={cordRef} />
                </div>

                <div className={styles.astronaut__head}>
                    <div className={styles.container}>
                        <div className={styles.bubble}>{translater('errorScreen.houstonPopup', 'Ah, Houston, we\'ve had a problem')}</div>
                    </div>

                    <canvas id="visor" width="60px" height="60px" ref={visorRef} />
                    <div className={styles.astronaut__head_visor_flare1} />
                    <div className={styles.astronaut__head_visor_flare2} />
                </div>
            </div>
        </div>
    );
}
