import { useEffect, useState } from 'react';
import { isString } from 'lodash';
import { Theme, ThemeHandler, Themes, type } from './types';

/**
 * @lang hu:
 * Általános téma kezelő hook. Segítségével módosíthatjuk a sass file-ok számára a globális változók értékeit (színkódokat).
 *
 * @param {Theme} defaultTheme - opcionális paraméter amivel beállíthatjuk, hogy milyen témát használjon ('light' vagy 'dark')
 * @returns {ThemeHandler} object:
 * theme - egy változó amely a téma megnevezését tartalmazza ('light' vagy 'dark' értékű lehet)
 * changeTheme - egy funkció amely segítségével módosítani tudjuk a beállított témákat
 *
 *
 * @lang en:
 * General theme manager hook. It can be used to change the values (color codes) of global variables for sass files.
 *
 * @param {Theme} defaultTheme - optional parameter to set which theme to use ('light' or 'dark')
 * @returns {ThemeHandler} object:
 * theme - a variable that contains the name of the theme (can be 'light' or 'dark')
 * changeTheme - a function with which we can modify the set themes
 */
export default function useThemeHandler(defaultTheme?: Themes): ThemeHandler {
    const [theme, setTheme] = useState<Theme>(document?.querySelector('html')?.dataset?.theme as Theme || type.light);

    function changeTheme(themeToSet?: Themes): Theme {
        let newTheme: Theme = type.light;

        setTheme(currentState => {
            newTheme = currentState === type.light ? type.dark : type.light;

            if (themeToSet && isString(themeToSet)) {
                newTheme = themeToSet;
            }

            const htmlElement = document.querySelector('html');
            if (htmlElement) {
                htmlElement.dataset.theme = newTheme;
            }

            return newTheme;
        });

        return newTheme;
    }

    //theme init if exists
    useEffect(() => {
        if (defaultTheme) {
            changeTheme(defaultTheme);
        }
    }, [defaultTheme]);

    return {
        theme,
        changeTheme,
    };
}
