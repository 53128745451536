import { useContext, createContext, useState, useLayoutEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { clone, cloneDeep, isArray, isEmpty, isObject, isUndefined } from 'lodash';
import md5 from 'md5';
import { LoadingSpinner, useAlert } from 'theRising-baseComponents';
import LoginScreen from '@screens/login/LoginScreen';
import { sessionService, systemSettingsService, userService } from '@services';
import { useTranslate } from '@i18n';
import { useThemeHandler } from '@styles/theme';
import { dateTimeUtils } from '@utils';

export type SystemSettingsType = {
    isNonAccentSearch: boolean,
    isUsePeriodicPassword: boolean,
    periodicPasswordInterval: number,
    companyName: string,
    maxSpaceLevel: number,
} | null;

export type UserInterfaceSetting = {
    location: string,
    settingName: string,
    value: any,
} | null;

export type LoggedInUserType = {
    id: number,
    profilePicture: string,
    username: string,
    password: string,
    email: string,
    fullname: string,
    firstname: string,
    lastname: string,
    isUseTwoFactorAuth: boolean,
    isSuperUser: boolean,
    isUseDarkTheme: boolean,
    token: string,
    userTypeName: string,
    rightIds: Array<number>,
    currentPasswordResetDate: string,
    interfaceSettings: Array<UserInterfaceSetting>,
    //egyéb személyes adatok
    phone: string | null,
    countryId: number | null,
    settlementId: number | null,
    address: string | null,
    jobPositionId: number | null,
} | null;

export type AuthContextType = {
    user: LoggedInUserType,
    updateUserData: (userData: any) => void,
    login: (userData: { authToken: string }) => Promise<void>,
    logOut: () => Promise<void>,
    reloadUserDatas: () => Promise<void>,
    getIsValidPassword: (password: string) => any,
    setNewPassword: (password: string) => void,
    hasRight: (rightId: number | undefined) => boolean,
    hasRights: (rightId: Array<number>) => boolean,
    hasSomeRights: (rightId: Array<number>) => boolean,
    //rendszer
    systemSettings: SystemSettingsType,
    updateSystemSettings: (systemSettingsData: any) => void,
};

const AuthContext = createContext<AuthContextType>({
    user: null,
    updateUserData: () => {},
    login: async () => {},
    logOut: async () => {},
    reloadUserDatas: async () => {},
    getIsValidPassword: () => {},
    setNewPassword: () => {},
    hasRight: () => false,
    hasRights: () => false,
    hasSomeRights: () => false,
    //rendszer
    systemSettings: {
        isNonAccentSearch: false,
        isUsePeriodicPassword: false,
        periodicPasswordInterval: 1,
        companyName: '',
        maxSpaceLevel: 2,
    },
    updateSystemSettings: () => {},
});

function AuthProvider({ children }) {
    const [isRequiredPasswordChange, setIsRequiredPasswordChange] = useState<boolean>(false);
    const [user, setUser] = useState<any | null>(null);
    const [systemSettings, setSystemSettings] = useState<any | null>({
        isNonAccentSearch: false,
    });
    const [authToken, setAuthToken] = useState(sessionService.getAuthToken() || '');
    const navigate = useNavigate();
    const { errorAlert } = useAlert();
    const { translater } = useTranslate();
    const { changeTheme } = useThemeHandler();

    /*if (user) {
        console.log({user});
        //console.log({systemSettings});
    }*/

    //időszakos jelszóváltoztatás ellenőrzése
    useLayoutEffect(() => {
        if (systemSettings?.isUsePeriodicPassword) {
            const currentDate = dateTimeUtils.getDateTimeNow();
            const currentPaswordResetDate = dateTimeUtils.getDateTimeAddMonths(user?.currentPasswordResetDate, systemSettings?.periodicPasswordInterval);

            if (dateTimeUtils.isAfterDate(currentDate, currentPaswordResetDate)) {
                setIsRequiredPasswordChange(true);
            } else {
                setIsRequiredPasswordChange(false);
            }
        }
    }, [systemSettings?.isUsePeriodicPassword, systemSettings?.periodicPasswordInterval, user?.currentPasswordResetDate]);

    //userhez tartozó téma aktiválása
    useLayoutEffect(() => {
        const sessionTheme = sessionService.getTheme();
        if (sessionTheme && sessionTheme === 'dark' && user?.isUseDarkTheme) { //user theme megegyezik a session theme-el, nem kell módosítás
            return;
        }
        if (sessionTheme && sessionTheme === 'light' && !user?.isUseDarkTheme) { //user theme megegyezik a session theme-el, nem kell módosítás
            return;
        }

        if (user?.isUseDarkTheme) {
            changeTheme('dark');
            sessionService.setTheme('dark');
        } else {
            changeTheme('light');
            sessionService.setTheme('light');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.isUseDarkTheme]);

    //ha be vagyunk még jelentkezve, akkor betöltjük a user adatait
    useLayoutEffect(() => {
        let isCancelled = false;

        async function loadData() {
            try {
                const [loggedInUserData, systemSettingsData] = await Promise.all([
                    userService.getLoggedInUserDatas(),
                    systemSettingsService.getSystemSettings(),
                ]);

                if (!isCancelled && loggedInUserData && !isEmpty(loggedInUserData)) {
                    setUser({ ...loggedInUserData, token: authToken });

                    if (!isEmpty(systemSettingsData)) {
                        setSystemSettings(systemSettingsData);
                    }
                }

                //törölték/inaktiválták menet közben a usert -> vissza a login screen-re
                if (isEmpty(loggedInUserData)) {
                    sessionService.removeAuthToken();
                    setAuthToken('');
                    setUser(null);
                    setSystemSettings(null);
                    navigate('/login');
                }
            } catch (error) {
                errorAlert(translater('default.unsuccessfulOperation', 'Unsuccessful operation'));
                sessionService.removeAuthToken();
                setAuthToken('');
                setUser(null);
                setSystemSettings(null);
                navigate('/login');
            }
        }

        if (!isEmpty(authToken) && isEmpty(user)) {
            loadData();
        }

        return () => {
            isCancelled = true;
        };
    }, [authToken, errorAlert, navigate, translater, user]);

    //felhasználói adatok frissítése
    const updateUserData = useCallback((userData: any) => {
        if (!isEmpty(userData) && isObject(userData)) {
            setUser(currentState => {
                Object.entries(userData)?.forEach(([key, value]) => {
                    if (!isObject(value) && !isArray(value) && !isUndefined(currentState[key])) { //sima értékek frissítése (amelyekkel rendelkezik a user object is)
                        if (key === 'password' && !isEmpty(value)) {
                            currentState[key] = md5(value);
                            return;
                        }

                        if (key === 'password') {
                            return;
                        }

                        currentState[key] = value;
                    }
                });
                currentState.fullname = `${currentState.firstname} ${currentState.lastname}`;

                return cloneDeep(currentState);
            });
        }
    }, []);

    //rendszerbeállítás adatok frissítése
    const updateSystemSettings = useCallback((systemSettingsData: any) => {
        if (!isEmpty(systemSettingsData) && isObject(systemSettingsData)) {
            setSystemSettings(currentState => {
                Object.entries(systemSettingsData)?.forEach(([key, value]) => {
                    if (!isObject(value) && !isArray(value) && !isUndefined(currentState[key])) { //sima értékek frissítése (amelyekkel rendelkezik az object is)
                        currentState[key] = value;
                    }
                });

                return clone(currentState);
            });
        }
    }, []);

    //bejelentkezés
    const login = useCallback(async (userData: { authToken: string }) => {
        try {
            sessionService.setAuthToken(userData.authToken);

            const [loggedInUserData, systemSettingsData] = await Promise.all([
                userService.getLoggedInUserDatas(),
                systemSettingsService.getSystemSettings(),
            ]);

            setUser({ ...loggedInUserData, token: userData.authToken });

            if (!isEmpty(systemSettingsData)) {
                setSystemSettings(systemSettingsData);
            }
        } catch (error) {
            errorAlert(translater('default.unsuccessfulOperation', 'Unsuccessful operation'));
        }
    }, [errorAlert, translater]);

    //kijelentkezés
    const logOut = useCallback(() => {
        try {
            setIsRequiredPasswordChange(false);
            setUser(null);
            setAuthToken('');
            sessionService.removeAuthToken();
            navigate('/login');
        } catch (error) {
            errorAlert(translater('default.unsuccessfulOperation', 'Unsuccessful operation'));
        }
    }, [errorAlert, navigate, translater]);

    const reloadUserDatas = useCallback(async () => {
        try {
            const loggedInUserData = await userService.getLoggedInUserDatas();

            if (loggedInUserData) {
                setUser(currentState => ({ ...(currentState || {}), ...(loggedInUserData || {}) }));
            }
        } catch (error) {
            errorAlert(translater('default.unsuccessfulOperation', 'Unsuccessful operation'));
        }
    }, [errorAlert, translater]);

    //jelenlegi jelszó helyes-e
    const getIsValidPassword = useCallback((password: string): boolean => md5(password) === user?.password, [user?.password]);

    //időszakos új jelszó beállítása
    const setNewPassword = useCallback((password: string) => {
        user.password = md5(password);
        setIsRequiredPasswordChange(false);
    }, [user]);

    //megvizsgáljuk, hogy a felhasználó rendelkezik-e az adott joggal
    const hasRight = useCallback((rightId: number | undefined): boolean => {
        if (user?.isSuperUser || isUndefined(rightId)) {
            return true;
        }

        return !!user?.rightIds?.find(id => id === rightId);
    }, [user?.isSuperUser, user?.rightIds]);

    //megvizsgáljuk, hogy a felhasználó rendelkezik-e az adott jogokkal
    const hasRights = useCallback((rightIds: Array<number>): boolean => {
        if (user?.isSuperUser || isEmpty(rightIds)) {
            return true;
        }

        return rightIds?.every(rightId => !!user?.rightIds?.find(id => id === rightId));
    }, [user?.isSuperUser, user?.rightIds]);

    //megvizsgáljuk, hogy a felhasználó rendelkezik-e az adott jogok közül bármelyikkel
    const hasSomeRights = useCallback((rightIds: Array<number>): boolean => {
        if (user?.isSuperUser || isEmpty(rightIds)) {
            return true;
        }

        return rightIds?.some(rightId => !!user?.rightIds?.find(id => id === rightId));
    }, [user?.isSuperUser, user?.rightIds]);

    // --------- Interface settings ------------

    const getInterfaceSetting = useCallback((location: string, settingName: string): any => {
        if (user?.interfaceSettings) {
            const interfaceSetting = user.interfaceSettings.find((setting: UserInterfaceSetting) => setting?.location === location && setting?.settingName === settingName);
            return interfaceSetting?.value;
        }
        return undefined;
    }, [user?.interfaceSettings]);

    const setInterfaceSetting = useCallback(async (location: string, settingName: string, value: any): Promise<void> => {
        try {
            const saveResult = await userService.setInterfaceSetting({ location, settingName, value });

            if (saveResult && user?.interfaceSettings) {
                const interfaceSetting = user.interfaceSettings.find((setting: UserInterfaceSetting) => setting?.location === location && setting?.settingName === settingName);
                if (interfaceSetting) {
                    interfaceSetting.value = value;
                } else {
                    user.interfaceSettings.push({ location, settingName, value });
                }
            }
        } catch (error) {
            errorAlert(translater('default.unsuccessfulOperation', 'Unsuccessful operation'));
        }
    }, [errorAlert, translater, user?.interfaceSettings]);

    // --------- Context ------------

    const authContextProviderValue = useMemo(() => ({
        systemSettings,
        updateSystemSettings,
        user,
        login,
        logOut,
        reloadUserDatas,
        getIsValidPassword,
        setNewPassword,
        hasRight,
        hasRights,
        hasSomeRights,
        updateUserData,
        //interface settings
        getInterfaceSetting,
        setInterfaceSetting,
    }), [
        logOut,
        login,
        reloadUserDatas,
        getIsValidPassword,
        setNewPassword,
        hasRight,
        hasRights,
        hasSomeRights,
        updateUserData,
        user,
        systemSettings,
        updateSystemSettings,
        getInterfaceSetting,
        setInterfaceSetting,
    ]);

    //token alapján megpróbáljuk betölteni a user adatokat
    if (!user && authToken) {
        return (
            <AuthContext.Provider value={authContextProviderValue as any}>
                <div style={{ backgroundColor: 'transparent' }}>
                    <LoadingSpinner />
                </div>
            </AuthContext.Provider>
        );
    }

    //bejelentkező képernyő
    if ((!user && !authToken) || isRequiredPasswordChange) {
        return (
            <AuthContext.Provider value={authContextProviderValue as any}>
                <LoginScreen isRequiredPasswordChange={isRequiredPasswordChange} />
            </AuthContext.Provider>
        );
    }

    //belső route-ok
    return (
        <AuthContext.Provider value={authContextProviderValue as any}>
            {children}
        </AuthContext.Provider>
    );
}

export default AuthProvider;

/**
 * User információkat és handler funkciókat tartalmazó context
 * @param systemSettings - Rendszerbeállítás adatokat tartalmazó object
 * @param updateSystemSetting - Segítségével frissíthetőek a rendszerbeállítás adatok a contexten belül
 * @param user - User adatokat tartalmazó object
 * @param getIsValidPassword - Jelszót megadva ellenőrzi, hogy megegyezik-e az aktuális jelszóval
 * @param updateUserData - Segítségével frissíthetőek a felhasználói adatok a contexten belül
 * @param hasRight - Segítségével lekérdezhető, hogy adott jogosultsághoz van-e hozzáférése a felhasználónak
 */
export const useAuth = () => useContext(AuthContext);
