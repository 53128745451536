import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { isArray, isEmpty, isFunction, size } from 'lodash';
import clsx from 'clsx';
import { PopoverButton, Svg } from 'theRising-baseComponents';
import { useTranslate } from '@i18n';
import { routerHelper } from '@modules';
import { TICKET_LIST } from '@shared/contents';
import { useAuth } from '@screens/login/authProvider/AuthProvider';
import { RIGHTS } from '@constants';
import { DEFAULT_SPACE_LEVEL } from '@contents/settings/defaultSettings/DefaultSettings';
import { SpaceType } from 'src/types/Space.types';
import styles from './menuItem.module.scss';
import OtherFunctionMenuItem from './otherFunctionMenuItem/OtherFunctionMenuItem';
import useSpaceFunctions from '../useSpaceFunctions';

type MenuItemPropsType = {
    menuItem: SpaceType,
    isCollapsed: boolean,
};

//megnézi, hogy a menüpont nyitva van-e
function getIsOpenedSpace(menuItem: any, location: any): boolean {
    const { pathname, search } = location;
    const currentContentUrl = routerHelper.getContentUrl(TICKET_LIST, TICKET_LIST.CONTENTS.INDEX);

    if (currentContentUrl !== pathname || isEmpty(search)) {
        return false;
    }

    const searchParams = routerHelper.getSearchParamsAsObject(search) as any;
    if (!searchParams?.id) {
        return false;
    }

    const ticketListId = +searchParams.id;
    if ((menuItem.id === ticketListId || menuItem.children?.some((subMenuItem: any) => subMenuItem.id === ticketListId)) && isArray(menuItem.children)) {
        return true;
    }

    return false;
}

function MenuItem(props: MenuItemPropsType): React.ReactElement {
    const { menuItem, isCollapsed } = props;
    const { translater } = useTranslate();
    const { systemSettings, hasRight } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const [isOpened, setIsOpened] = useState(getIsOpenedSpace(menuItem, location));
    const [isHover, setIsHover] = useState(false);
    const popupStateRef = useRef(false);

    const OtherFunctionButton = useCallback((key: string, setIsShowed?: React.Dispatch<React.SetStateAction<boolean>>) => (
        <div
            key={key}
            role="button"
            tabIndex={0}
            className={styles.functionButton}
            onClick={event => {
                document.body.click(); //ha másik popover nyitva van, akkor azt bezárjuk
                event.stopPropagation();

                if (isFunction(setIsShowed)) {
                    setIsShowed(currentState => {
                        popupStateRef.current = !currentState;
                        return !currentState;
                    });
                }
            }}
            title={translater('sideMenu.spaceFunction', 'Functions')}
        >
            <Svg iconId="icon-more" />
        </div>
    ), [translater]);

    const onClickMenuItem = useCallback((event: any) => {
        event.stopPropagation();
        event.preventDefault();

        const contentUrl = routerHelper.getContentUrl(TICKET_LIST, TICKET_LIST.CONTENTS.INDEX);
        const url = routerHelper.getUrlWithParameters(contentUrl, { id: menuItem.id });
        navigate(url);
    }, [menuItem.id, navigate]);

    //megnézi, hogy a menüpontnak van-e gyereke -> ha van, akkor mappát jelöl
    const isSpace = useMemo(() => isArray(menuItem.children), [menuItem.children]);

    const isHasSpaceEditRight = useMemo(() => hasRight(RIGHTS.SIDE_MENU_SPACE_ADD_OR_EDIT), [hasRight]);
    const isHasTicketListEditRight = useMemo(() => hasRight(RIGHTS.SIDE_MENU_TICKET_LIST_ADD_OR_EDIT), [hasRight]);

    //megnézi, hogy a menüpont ki van-e választva
    const isSelectedTicketList = useMemo(() => {
        if (isSpace) {
            return false;
        }

        const { pathname, search } = location;
        const currentContentUrl = routerHelper.getContentUrl(TICKET_LIST, TICKET_LIST.CONTENTS.INDEX);

        if (currentContentUrl !== pathname || isEmpty(search)) {
            return false;
        }

        const searchParams = routerHelper.getSearchParamsAsObject(search) as any;
        if (!searchParams?.id) {
            return false;
        }

        const ticketListId = +searchParams.id;
        if (menuItem.id === ticketListId) {
            return true;
        }

        return false;
    }, [isSpace, location, menuItem.id]);

    const isDisabledCreateSpaceMenuItem = useMemo(() => (systemSettings?.maxSpaceLevel || DEFAULT_SPACE_LEVEL) <= menuItem.menuLevel, [menuItem.menuLevel, systemSettings?.maxSpaceLevel]);

    const {
        onClickEditSpace,
        onClickCreateSpace,
        onClickDeleteSpace,
        onClickCreateTicketList,
        onClickEditTicketList,
        onClickDeleteTicketList,
    } = useSpaceFunctions();

    return (
        <div className={styles.menuItemWrapper}>
            <button
                onClick={() => !isCollapsed && menuItem.children && setIsOpened(!isOpened)}
                type="button"
                className={clsx(
                    styles.menuItem,
                    isCollapsed && styles.collapsedMenuItem,
                    isSelectedTicketList && styles.menuItemSelected
                )}
                title={menuItem.name}
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => {
                    if (!popupStateRef.current) {
                        setIsHover(false);
                    }
                }}
            >
                {/* Menu item pre icon */}
                {isSpace ? (
                    <Svg iconId={isOpened ? 'icon-folder-open' : 'icon-folder'} className={styles.menuItemIcon} />
                ) : (
                    <Svg
                        iconId={menuItem.isArchived ? 'icon-archive' : 'icon-list-bulleted'}
                        className={clsx(styles.menuItemIcon, menuItem.isArchived && styles.menuItemIconArchived)}
                    />
                )}

                {/*Jogosultsághoz kötött space jelölése*/}
                {menuItem.isRestrictedAccess && (
                    <div
                        className={clsx(
                            styles.menuItemIconLockedWrapper,
                            (menuItem?.menuLevel > 1 || !isSpace) && styles.subMenuItemIconLocked
                        )}
                        title={translater('sideMenu.restrictedAccess', 'Restricted access')}
                    >
                        <Svg iconId="icon-filled-lock" className={styles.menuItemIconLocked} />
                    </div>
                )}

                {/* Menu name */}
                {!isCollapsed && (
                    isSpace ? (
                        <span
                            className={clsx(
                                styles.menuItemText,
                                styles.overflowDots,
                                isOpened && styles.menuItemTextOpened
                            )}
                        >
                            {menuItem.name}
                        </span>
                    ) : (
                        <a
                            className={clsx(
                                styles.menuItemText,
                                styles.overflowDots,
                                isOpened && styles.menuItemTextOpened,
                                menuItem.isArchived && styles.menuItemIconArchived
                            )}
                            href={`/ticketList/index?id=${menuItem.id}`}
                            onClick={onClickMenuItem}
                        >
                            {menuItem.name}
                        </a>
                    )
                )}

                {/* Other functions */}
                {!isCollapsed && isHover && ((isSpace && isHasSpaceEditRight) || (!isSpace && isHasTicketListEditRight)) && (
                    <PopoverButton
                        renderText={OtherFunctionButton}
                        placement="auto"
                        popoverClassName={styles.popoverClassName}
                        className={styles.toggleButton}
                        onChange={toggleState => {
                            if (!toggleState) {
                                popupStateRef.current = false;
                                setIsHover(false);
                            }
                        }}
                    >
                        <div className={styles.popover}>
                            {isSpace ? (
                                <>
                                    <OtherFunctionMenuItem
                                        iconId="icon-edit"
                                        text={translater('sideMenu.editSpace', 'Edit space')}
                                        onClick={() => onClickEditSpace(menuItem.id, menuItem.name, menuItem)}
                                        isDisabled={!hasRight(RIGHTS.SIDE_MENU_SPACE_ADD_OR_EDIT)}
                                    />
                                    <div className={styles.separator} />
                                    <OtherFunctionMenuItem
                                        iconId="icon-folder-open"
                                        text={translater('sideMenu.createSpace', 'Create space')}
                                        onClick={() => onClickCreateSpace(menuItem)}
                                        isDisabled={!hasRight(RIGHTS.SIDE_MENU_SPACE_ADD_OR_EDIT) || isDisabledCreateSpaceMenuItem}
                                    />
                                </>
                            ) : (
                                <>
                                    <OtherFunctionMenuItem
                                        iconId="icon-edit"
                                        text={translater('sideMenu.editTicketList', 'Edit ticket list')}
                                        onClick={() => onClickEditTicketList(menuItem.id, menuItem.name, menuItem.parentSpaceId)}
                                        isDisabled={!hasRight(RIGHTS.SIDE_MENU_SPACE_ADD_OR_EDIT)}
                                    />
                                    <div className={styles.separator} />
                                </>
                            )}
                            <OtherFunctionMenuItem
                                iconId="icon-list-bulleted"
                                text={translater('sideMenu.createTicketList', 'Create ticket list')}
                                onClick={() => onClickCreateTicketList(menuItem.parentSpaceId)}
                                isDisabled={!hasRight(RIGHTS.SIDE_MENU_TICKET_LIST_ADD_OR_EDIT)}
                            />

                            <div className={styles.separator} />

                            {isSpace ? (
                                <OtherFunctionMenuItem
                                    iconId="icon-bin"
                                    text={translater('sideMenu.deleteSpace', 'Delete space')}
                                    onClick={() => onClickDeleteSpace(menuItem.id)}
                                    isDisabled={!hasRight(RIGHTS.SIDE_MENU_SPACE_DELETE)}
                                    isDestructive
                                />
                            ) : (
                                <OtherFunctionMenuItem
                                    iconId="icon-bin"
                                    text={translater('sideMenu.deleteTicketList', 'Delete ticket list')}
                                    onClick={() => onClickDeleteTicketList(menuItem.id)}
                                    isDisabled={!hasRight(RIGHTS.SIDE_MENU_TICKET_LIST_DELETE)}
                                    isDestructive
                                />
                            )}
                        </div>
                    </PopoverButton>
                )}
            </button>

            {isArray(menuItem.children) && !isCollapsed && (
                <div className={clsx(styles.subMenu, !isOpened && styles.subMenuShrunk)}>
                    {size(menuItem?.children) > 0 ? (
                        menuItem.children.map((subMenuItem: any, index: number) => (
                            <MenuItem key={index} menuItem={subMenuItem} isCollapsed={isCollapsed} />
                        ))
                    ) : (
                        <button className={clsx(styles.menuItem, styles.emptyItem)} type="button">
                            {`-- ${translater('default.empty', 'Empty')} --`}
                        </button>
                    )}
                </div>
            )}
        </div>
    );
}

export default memo(MenuItem);
