import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export default function useTranslate() {
    const { t: translater, i18n } = useTranslation();

    const t = useCallback((moduleAndKey : string, defaultText : string): JSX.Element => (
        <span>
            {translater(moduleAndKey, defaultText)}
        </span>
    ), [translater]);

    return {
        t,
        translater,
        i18n,
    };
}
