/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import { useState, CSSProperties, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { Button } from 'theRising-baseComponents';
import { RIGHTS } from '@constants';
import { SYSTEM_SETTINGS } from '@shared/contents';
import { routerHelper } from '@modules';
import { useTranslate } from '@i18n';
import { useAuth } from '@screens/login/authProvider/AuthProvider';
import logoSrc from '@styles/img/logo_icon.png';
import styles from './header.module.scss';
import UserMenu from './userMenu/UserMenu';

function Header(): React.ReactElement {
    const navigate = useNavigate();
    const { translater } = useTranslate();
    const { user, hasRight } = useAuth();

    return (
        <div className={styles.header}>
            <div className={styles.leftWrapper}>
                <img
                    src={logoSrc}
                    className={styles.logo}
                    alt=""
                    /*onClick={() => navigate('/')}
                    role="button"
                    tabIndex={0}
                    title={translater('header.backToHomepage', 'Back to homepage')}*/
                />
                <span className={clsx(styles.pageTitle, styles.disableSelectionColor)}>
                    Ticket System
                </span>
            </div>
            <div className={styles.middleWrapper}>
                Center
            </div>
            <div className={styles.rightWrapper}>
                {hasRight(SYSTEM_SETTINGS.CONTENTS.INDEX.ACCESS_RIGHT_ID) && (
                    <Button
                        type="secondary"
                        iconId="icon-settings"
                        className={styles.headerMenuItem}
                        title={translater('header.systemSettings', 'System settings')}
                        onClick={() => navigate(routerHelper.getContentUrl(SYSTEM_SETTINGS, SYSTEM_SETTINGS.CONTENTS.INDEX))}
                    />
                )}
                <UserMenu />
            </div>
        </div>
    );
}

export default memo(Header);
