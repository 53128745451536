import { useLayoutEffect, useState } from 'react';
import i18n from '@i18n/i18n';
import i18NextOptions from '@i18n/i18NextOptions';
import { languageService } from '@services';
import { LogHandler } from '@modules';

export default function useTranslater() {
    const [hasTranslaterError, setHasTranslaterError] = useState<boolean>();

    useLayoutEffect(() => {
        async function initTranslater() {
            try {
                const versions = await languageService.getVersions();

                const translaterOptions = i18NextOptions;
                translaterOptions.backend.backendOptions[0].versions = versions;

                i18n.init(translaterOptions, error => {
                    if (error) {
                        setHasTranslaterError(true);
                    } else {
                        setHasTranslaterError(false);
                    }
                });
            } catch (error) {
                LogHandler.Error('Translater init error: ', error);
                setHasTranslaterError(true);
            }
        }

        initTranslater();
    }, []);

    return {
        hasTranslaterError,
        i18n,
    };
}
