import { lazy, useLayoutEffect, useState } from 'react';
import { isEmpty, isNumber } from 'lodash';
import { useAuth } from '@screens/login/authProvider/AuthProvider';
import * as allContentRoutes from '@shared/contents/index';
import { ModuleType, ContentType } from '@shared/contents/ModuleContentTypes';
import { LogHandler } from '@modules';

type RouteItemType = {
    path: string,
    component: React.ComponentType<any>,
    isHasPermission: boolean,
};

export default function useRouter() {
    const { hasRight } = useAuth();
    const [routeList, setRouteList] = useState<Array<RouteItemType>>();

    useLayoutEffect(() => {
        const loadComponent = (componentUrl: string) => lazy(() => import(`../../components/${componentUrl}`).catch(error => {
            console.error('Content dynamic import error:', error);
            return { default: () => null };
        }));
        const routerItems: Array<RouteItemType> = [];
        const indexedPathList = {};

        Object.values(allContentRoutes)?.forEach((module: ModuleType) => { //végig megyünk minden module-on
            if (!isEmpty(module.CONTENTS)) {
                Object.values(module.CONTENTS)?.forEach((content: ContentType) => { //végig megyünk adott modul minden content-jén
                    const path = `/${module.MODULE_NAME}/${content.CONTENT_NAME}`;

                    if (isEmpty(module.MODULE_NAME) || isEmpty(content.CONTENT_NAME) || isEmpty(content.COMPONENT_URL)) {
                        return;
                    }

                    if (!isEmpty(indexedPathList[path])) {
                        LogHandler.Error('The following route path already has a route rule created: ', path);
                        return;
                    }

                    indexedPathList[path] = true;

                    routerItems.push({
                        path,
                        component: loadComponent(content.COMPONENT_URL),
                        isHasPermission: isNumber(content.ACCESS_RIGHT_ID) ? hasRight(content.ACCESS_RIGHT_ID) : true,
                    });
                });
            }
        });

        setRouteList(routerItems);
    }, [hasRight]);

    return {
        routeList,
    };
}
