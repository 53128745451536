import { memo } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from './AuthProvider';

function LoginRoute() {
    const { user } = useAuth();
    const location = useLocation().pathname;

    if (user?.token && location === '/login') {
        return <Navigate to="/" />;
    }

    return <Outlet />;
}

export default memo(LoginRoute);
