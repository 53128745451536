import styles from './home.module.scss';

type HomePropsType = {
    //name: string
};

export default function Home(props: HomePropsType): React.ReactElement {
    //const { name } = props;

    return (
        <div className={styles.home}>
            Home
        </div>
    );
}
