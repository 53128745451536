export const WEB_SOCKET = {
    READY_STATE: {
        CONNECTING: 0,
        OPEN: 1,
        CLOSING: 2,
        CLOSED: 3,
    },
    TYPE: {
        UPDATE_SPACES: 'updateSpaces',
        UPDATE_USER_DATA: 'updateUserData',
        UPDATE_SYSTEM_SETTINGS: 'updateSystemSettings',
        UPDATE_SYSTEM_SETTINGS_USER: 'updateSystemSettingsUserDatas',
        USER: {
            PRESENCE: 'USER_PRESENCE',
            PRESENCE_UPDATE: 'USER_PRESENCE_UPDATE',
            JOINED: 'USER_PRESENCE_JOINED',
            LEFT: 'USER_PRESENCE_LEFT',
        },
    },
};
