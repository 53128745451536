export const KEY_CODE = {
    END: 'End',
    HOME: 'Home',
    BACKSPACE: 'Backspace',
    DELETE: 'Delete',
    ARROW_LEFT: 'ArrowLeft',
    ARROW_RIGHT: 'ArrowRight',
    ARROW_UP: 'ArrowUp',
    ARROW_DOWN: 'ArrowDown',
    SHIFT: 'Shift',
    SHIFT_RIGHT: 'ShiftRight',
    SHIFT_LEFT: 'ShiftLeft',
    ALT_LEFT: 'AltLeft',
    ALT_RIGHT: 'AltRight',
    CTRL_LEFT: 'ControlLeft',
    CTRL_RIGHT: 'ControlRight',
    CAPS_LOCK: 'CapsLock',
    TAB: 'Tab',
    ENTER: 'Enter',
    NUMPAD_ENTER: 'NumpadEnter',
    SLASH: 'Slash', //-
    NUMPAD_SLASH: 'NumpadSubtract', //-
    COMMA: 'Comma', //,
    NUMPAD_DECIMAL: 'NumpadDecimal', //,
    PERIOD: 'Period', //.
};
