import { RIGHTS } from '../../constants';
import { ModuleType } from '../ModuleContentTypes';

export const SYSTEM_SETTINGS: ModuleType = {
    MODULE_ID: 7,
    MODULE_NAME: 'systemSettings',
    CONTENTS: {
        INDEX: {
            CONTENT_ID: 1,
            CONTENT_NAME: 'index',
            COMPONENT_URL: 'contents/settings/systemSettingsIndex/SystemSettings',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.systemSettings',
                TRANSLATOR_DEFAULT: 'System settings',
            },
            ACCESS_RIGHT_ID: RIGHTS.SYSTEM_SETTINGS_ACCESS,
        },
    },
};
