import { useMemo, useState } from 'react';
import clsx from 'clsx';
import { isArray, size } from 'lodash';
import { Svg } from 'theRising-baseComponents';
import { useAuth } from '@screens/login/authProvider/AuthProvider';
import { DEFAULT_SPACE_LEVEL } from '@contents/settings/defaultSettings/DefaultSettings';
import styles from './spaceMenuItem.module.scss';

function isIdInChildren(space, id) {
    //ellenőrizzük, hogy van-e "children"
    if (!space || !isArray(space?.children)) {
        return false; //ha nincs children vagy üres a children, akkor nem található meg az id
    }

    //ellenőrizzük, hogy valamelyik gyermek id-je megegyezik-e a keresett id-vel
    return space.children.some(child => child.id === id);
}

type SpaceMenuItemPropsType = {
    menuItem: any,
    onClick: (spaceId: number) => void,
    selectedSpaceId?: number,
};

export default function SpaceMenuItem(props: SpaceMenuItemPropsType): React.ReactElement {
    const { menuItem, selectedSpaceId, onClick } = props;
    const { systemSettings } = useAuth();
    const [isOpened, setIsOpened] = useState(isIdInChildren(menuItem, selectedSpaceId));

    const isDisabledMenuItem = useMemo(() => (systemSettings?.maxSpaceLevel || DEFAULT_SPACE_LEVEL) <= menuItem.menuLevel, [menuItem.menuLevel, systemSettings?.maxSpaceLevel]);

    const onClickSpace = () => {
        if (isDisabledMenuItem) {
            return;
        }

        if (menuItem.children) {
            setIsOpened(!isOpened);
        }

        if (selectedSpaceId === menuItem.id && !isOpened) {
            return;
        }

        onClick(menuItem.id);
    };

    return (
        <div className={styles.menuItemWrapper}>
            <button
                onClick={onClickSpace}
                type="button"
                className={clsx(
                    styles.menuItem,
                    selectedSpaceId === menuItem.id && styles.menuItemSelected,
                    isDisabledMenuItem && styles.menuItemDisabled
                )}
                title={menuItem.name}
                disabled={isDisabledMenuItem}
            >
                {/* Menu item pre icon */}
                <Svg iconId={isOpened ? 'icon-folder-open' : 'icon-folder'} className={styles.menuItemIcon} />

                {/* Menu name */}
                <span
                    className={clsx(
                        styles.menuItemText,
                        styles.overflowDots,
                        isOpened && styles.menuItemTextOpened
                    )}
                >
                    {menuItem.name}
                </span>
            </button>

            {isArray(menuItem.children) && (
                <div className={clsx(styles.subMenu, !isOpened && styles.subMenuShrunk)}>
                    {size(menuItem?.children) > 0 && (
                        menuItem.children.map((subMenuItem: any, index: number) => (
                            <SpaceMenuItem
                                key={index}
                                menuItem={subMenuItem}
                                onClick={onClick}
                                selectedSpaceId={selectedSpaceId}
                            />
                        ))
                    )}
                </div>
            )}
        </div>
    );
}
