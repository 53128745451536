import { ModuleType } from '../ModuleContentTypes';

export const HOME: ModuleType = {
    MODULE_ID: 3,
    MODULE_NAME: 'home',
    CONTENTS: {
        INDEX: {
            CONTENT_ID: 1,
            CONTENT_NAME: 'index',
            COMPONENT_URL: 'contents/home/Home',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.home',
                TRANSLATOR_DEFAULT: 'Home',
            },
        },
    },
};
