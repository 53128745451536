import styles from './registrationPanel.module.scss';

/*type RegistrationPanelPropsType = {
    //name: string
};*/

export default function RegistrationPanel(/*props: RegistrationPanelPropsType*/): React.ReactElement {
    //const { name } = props;

    return (
        <div className={styles.registrationPanel}>
            test
        </div>
    );
}
