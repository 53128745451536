import LocalStorageBackend from 'i18next-localstorage-backend';
import Backend from 'i18next-fetch-backend';
import { first, last, toUpper } from 'lodash';
import translationHu from '@i18n/locales/hu.json';
import translationEN from '@i18n/locales/en.json';
import { LANGUAGE, TRANSLATER_LANGUAGE_CODES } from '@constants';
import { LogHandler } from '@modules';
import { sessionService } from '@services';

// eslint-disable-next-line no-magic-numbers
const captionsExpirationTime = 1 * 24 * 60 * 60 * 1000; //1 day

const resourcesFromFile = {
    en: {
        translation: translationEN,
    },
    hu: {
        translation: translationHu,
    },
};

const getResourceFromLocalStorage = (langCode: string) => localStorage.getItem(`i18next_res_${langCode}-translation`);
const getResourceFromLocalFile = (langCode: string) => resourcesFromFile[langCode]?.translation || resourcesFromFile.en.translation;

const resources = {};
TRANSLATER_LANGUAGE_CODES?.forEach(langCode => {
    resources[langCode] = {
        translation: getResourceFromLocalStorage(langCode) || getResourceFromLocalFile(langCode),
    };
});

const i18NextOptions: any = {
    lng: LANGUAGE.CODE.HUNGARIAN.toLowerCase(),
    fallbackLng: [LANGUAGE.DEFAULT_LANGUAGE_CODE.toLowerCase()],
    preload: [
        LANGUAGE.CODE.ENGLISH.toLowerCase(),
        LANGUAGE.CODE.HUNGARIAN.toLowerCase(),
    ],
    keySeparator: '.',
    whitelist: [
        LANGUAGE.CODE.ENGLISH.toLowerCase(),
        LANGUAGE.CODE.HUNGARIAN.toLowerCase(),
    ],
    debug: false,
    interpolation: {
        escapeValue: false,
    },
    saveMissing: true,
    saveMissingTo: 'fallback',
    updateMissing: true,
    backend: {
        backends: [
            LocalStorageBackend,
            Backend,
        ],
        backendOptions: [{
            expirationTime: captionsExpirationTime,
            prefix: 'i18next_res_',
        }, {
            loadPath: '/api/language/getCaptions?langCode={{lng}}',
        }],
    },
    react: {
        useSuspense: false,
        wait: false,
    },
    //missingKeyHandler: (lng, ns, key, value) => value,
    missingKeyHandler: (lng, _ns, key, value) => {
        const langCode = toUpper(first(lng));
        const keyParts = key?.split('.');
        const reactModule = first(keyParts);
        const reactKey = last(keyParts);

        //ha más nyelv van kiválasztva, akkor a default nyelv miatt hisztizik
        const currentLangCode = sessionService.getLangCode() || LANGUAGE.DEFAULT_LANGUAGE_CODE;

        //default nyelvi címkéhez sql parancs
        if (toUpper(currentLangCode) === LANGUAGE.DEFAULT_LANGUAGE_CODE && langCode === LANGUAGE.DEFAULT_LANGUAGE_CODE && reactModule && reactKey && value && langCode) {
            LogHandler.DevLog(`Missing captions!
                \nCALL createCaption('${reactModule}', '${reactKey}', "${value}");\nCALL updateCaptionDate('${langCode}');
            `);
        }

        //sendServerRequest('POST', `/api/translations/${lng}/${key}/${value}`);
        return value;
    },
    //resources, //default captions
};

export default i18NextOptions;
