import { useTranslate } from '@i18n';
import { HTTP_STATUS } from '@constants';
import BaseError from '../baseError/BaseError';

export default function Error404(): React.ReactElement {
    const { translater } = useTranslate();

    return (
        <BaseError
            errorCode={HTTP_STATUS.CODE.NOT_FOUND}
            errorTitle={translater(
                'errorScreen.errorTitle404',
                'Oh no, Houston, we can\'t find that page!'
            )}
            errorMessage={translater(
                'errorScreen.errorMessage404',
                'We\'re sorry, but it looks like this page is lost in space. Please check the URL or navigate back to home base.'
            )}
            isShowReturnHomeButton
        />
    );
}
