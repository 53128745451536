import { toLower } from 'lodash';

//langCode
function setLangCode(langCode: string): void {
    localStorage.setItem('langCode', toLower(langCode));
}

function getLangCode(): string | null {
    return localStorage.getItem('langCode');
}

//auth
function setAuthToken(token: string): void {
    localStorage.setItem('authToken', token);
}

function getAuthToken(): string | null {
    return localStorage.getItem('authToken');
}

function removeAuthToken(): void {
    localStorage.removeItem('authToken');
}

//auth remember me
function setRememberMe(username: string, password: string): void {
    const rememberMeString = JSON.stringify({ username, password, isRememberMe: true });
    localStorage.setItem('rememberMe', rememberMeString);
}

function getRememberMe(): { username: string, password: string, isRememberMe: boolean } | null {
    const rememberMeJson = localStorage.getItem('rememberMe');

    if (rememberMeJson) {
        return JSON.parse(rememberMeJson);
    }

    return null;
}

function removeRememberMe() {
    localStorage.removeItem('rememberMe');
}

//theme
function setTheme(theme: string) {
    localStorage.setItem('theme', theme);
}

function getTheme(): string | null {
    return localStorage.getItem('theme');
}

function removeTheme() {
    localStorage.removeItem('theme');
}

export const sessionService = {
    //langCode
    setLangCode,
    getLangCode,
    //auth
    setAuthToken,
    getAuthToken,
    removeAuthToken,
    //auth remember me
    setRememberMe,
    getRememberMe,
    removeRememberMe,
    //theme
    setTheme,
    getTheme,
    removeTheme,
};
