import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Button, Input, LoadingSpinner, Svg, useAlert } from 'theRising-baseComponents';
import { useTranslate } from '@i18n';
import { userService } from '@services';
import { KEY_CODE } from '@constants';
import styles from './forgetPasswordPanel.module.scss';

export default function ForgetPasswordPanel(): React.ReactElement {
    const navigate = useNavigate();
    const { errorAlert, successAlert } = useAlert();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const emailInputRef = useRef<string>('');

    const { t, translater } = useTranslate();

    async function onSubmit(event?: any) {
        try {
            setIsLoading(true);
            if (event) {
                event.preventDefault();
            }

            const resetPasswordProps = {
                email: emailInputRef.current,
            };
            const resetResult = await userService.setResetPassword(resetPasswordProps);

            if (resetResult.isSuccess) {
                successAlert(translater('forgetPassword.newPasswordEmail', 'We sent you an email with a new password!'));
                navigate('#');
                return;
            }

            if (resetResult.errorMessage && !isEmpty(resetResult.errorMessage)) {
                errorAlert(resetResult.errorMessage);
            }
        } catch (error) {
            errorAlert(translater('default.unsuccessfulOperation', 'Unsuccessful operation'));
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className={styles.forgetPasswordPanel}>
            <LoadingSpinner value={isLoading === false} />
            <form className={styles.resetPasswordForm} onSubmit={onSubmit}>
                <h2 className={styles.resetYourPassword}>
                    {t('forgetPassword.resetPassword', 'Reset Your Password')}
                </h2>
                <p className={styles.enterEmailText}>
                    {t('forgetPassword.enterEmailText', 'Please enter your email address and we will send you a password reset link')}
                </p>
                <div className={styles.formInput}>
                    <Svg iconId="icon-email" className={styles.icon} />
                    <Input
                        type="email"
                        placeholder={translater('forgetPassword.emailAddress', 'Email Address')}
                        inputGroupClassName={styles.input}
                        required
                        isAutoFocus
                        onBlur={value => { emailInputRef.current = value; }}
                        onKeyDown={(_value, event) => (event.code === KEY_CODE.ENTER || event.code === KEY_CODE.NUMPAD_ENTER) && onSubmit()}
                    />
                </div>
                <div className={styles.sendButtonWrapper}>
                    <Button
                        text={t('forgetPassword.sendResetLink', 'Send Reset link')}
                        isSubmitButton
                    />
                </div>

                <div className={styles.whiteText}>{t('forgetPassword.haveAccountQuestion', 'Do you already have an account?')}
                    <a href="#login" className={styles.loginLink} title={translater('forgetPassword.loginHere', 'Login here')}>
                        {t('forgetPassword.loginHere', 'Login here')}
                    </a>
                </div>
            </form>
        </div>
    );
}
