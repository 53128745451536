import { isNull, isUndefined, isNaN } from 'lodash';

type BooleanValue = string | number | boolean | null | undefined;

/**
 * @lang hu:
 * A megadott értéket boolean típussá alakítja
 *
 * @param {BooleanValue} value Az érték bármilyen formátumban megadható
 * @param {boolean} defaultValue Ha az érték nem konvertálható, akkor milyen értéket kell visszaadnia alapértelmezetten (alapértelmezett: false)
 * @returns {boolean} boolean value
 *
 *
 * @lang en:
 * Converts the specified value to boolean type
 *
 * @param {BooleanValue} value The value can be given in any format
 * @param {boolean} defaultValue If the value is of non-convertible type, what value should it return by default (Default: false)
 * @returns {boolean} boolean value
 */
function getBoolean(value: BooleanValue, defaultValue = false) : boolean {
    switch (value) {
        case true:
        case 'true':
        case 'TRUE':
        case 1:
        case '1':
        case 'on':
        case 'ON':
        case 'yes':
        case 'YES':
            return true;
        default:
            return defaultValue;
    }
}

/**
 * @lang hu:
 * Megvizsgálja, hogy a megadott változó boolean értéket tartalmaz-e
 *
 * @param {BooleanValue} value Az érték bármilyen formátumban megadható
 * @returns {boolean} boolean: true/false
 *
 *
 * @lang en:
 * Tests whether the specified variable contains a boolean value
 *
 * @param {BooleanValue} value The value can be given in any format
 * @returns {boolean} boolean: true/false
 */
function isBoolean(value: BooleanValue) {
    switch (value) {
        case true:
        case 'true':
        case 'TRUE':
        case 1:
        case '1':
        case 'on':
        case 'ON':
        case 'yes':
        case 'YES':
        case false:
        case 'false':
        case 'FALSE':
        case 0:
        case '0':
        case 'off':
        case 'OFF':
        case 'no':
        case 'NO':
            return true;
        default:
            return false;
    }
}

/**
 * @lang hu:
 * A kapott paramétert number típusban adja vissza.
 * Null, undefined, NaN esetén a default értéket (0) adja vissza.
 *
 * @param {any} numberValue Az érték amit szám formátummá szeretnénk konvertálni.
 * @param {number} defaultValue Hibás érték (null, undefined, NaN) esetén mit adjunk vissza
 * @returns {number} number
 *
 *
 * @lang en:
 * Returns the received parameter in number type.
 * In the case of null, undefined, or NaN, it returns the default value (0).
 *
 * @param {any} numberValue The value we want to convert to number format.
 * @param {number} defaultValue What to return in the case of an incorrect value (null, undefined, NaN).
 * @returns {number} number
 */
function getNumber(numberValue: any, defaultValue: number | null = 0): number | null {
    if (isNull(numberValue) || isUndefined(numberValue)) {
        return defaultValue;
    }

    const numberAsString = numberValue.toString().replaceAll(',', '.'); //ha decimális szám
    if (isNaN(+numberAsString)) {
        return defaultValue;
    }

    return +numberAsString;
}

/**
 * @lang hu:
 * A kapott paraméter alapján megvizsgálja, hogy a kapott érték szám-e vagy átalakítható-e számmá
 *
 * @param {any} numberValue Az érték amit meg szeretnénk vizsgálni
 * @returns {boolean} booolean
 *
 *
 * @lang en:
 * Examines based on the received parameter whether the received value is a number or convertible to a number
 *
 * @param {any} numberValue The value we want to examine
 * @returns {boolean} booolean
 */
function isNumber(numberValue: any): boolean {
    if (isNull(numberValue)) {
        return false;
    }

    return !Number.isNaN(Number(numberValue));
}

/**
 * @lang hu:
 * A megadott decimális számok alapján kerekíti a paraméterként átadott szám értékét
 *
 * @param {number} numberValue Az érték amit kerekíteni szeretnénk
 * @param {number} decimals A decimális számok mennyisége, amennyire kerekíteni szeretnénk a megadott számot
 * @returns {number} number - A kerekített érték
 *
 *
 * @lang en:
 * Rounds the value of the number passed as a parameter based on the provided decimal places.
 *
 * @param {number} numberValue The value to round.
 * @param {number} decimals The number of decimal places to round the given number to.
 * @returns {number} number - The rounded value.
 */
function decimalRound(numberValue: number, decimals: number): number {
    if (!isNumber(numberValue)) {
        return numberValue;
    }

    if (Number(decimals) === 0) {
        return Math.round(numberValue);
    }

    if ((`${numberValue}`).includes('e')) {
        const arr = (`${numberValue}`).split('e');
        const numberValueFirstPart = +arr[0];
        const numberValueSecondPart = +arr[1];
        const sig = (numberValueSecondPart + decimals > 0) ? '+' : '';
        const roundedValue = Math.round(`${numberValueFirstPart}e${sig}${numberValueSecondPart + decimals}` as unknown as number);
        const decimalValue = (`e-${decimals}` as unknown as number);

        return +(roundedValue + decimalValue);
    }

    const roundedValue = Math.round(`${numberValue}e${decimals}` as unknown as number);
    return Number(`${roundedValue}e-${decimals}`);
}

/**
 * @lang hu:
 * A megadott szám alapján visszaadjuk, hogy float típusú-e a kapott érték.
 *
 * @param {number} numberValue Az érték amit vizsgálni szeretnénk.
 * @returns {boolean}
 *
 *
 * @lang en:
 * Based on the provided number, we return whether the received value is of type float.
 *
 * @param {number} numberValue The value we want to examine.
 * @returns {boolean}
 */
function isFloat(numberValue: number): boolean {
    if (!numberValue) {
        return false;
    }
    return Number(numberValue) % 1 !== 0;
}

export const numberUtils = {
    getBoolean,
    isBoolean,
    getNumber,
    isNumber,
    decimalRound,
    isFloat,
};
