import { useRef, useState } from 'react';
import clsx from 'clsx';
import { Button, Checkbox, ConfirmDialogButtonType, Input, LoadingSpinner, renderConfirmDialog, Svg, useAlert } from 'theRising-baseComponents';
import { isEmpty, isFunction, size, toLower } from 'lodash';
import BackButton from '@baseComponents/buttons/backButton/BackButton';
import { useAuth } from '@screens/login/authProvider/AuthProvider';
import { i18n, useTranslate } from '@i18n';
import { sessionService, userService } from '@services';
import { KEY_CODE } from '@constants';
import styles from './loginPanel.module.scss';

const maxFailedCount = 3;

export default function LoginPanel(): React.ReactElement {
    const { t, translater } = useTranslate();
    const { errorAlert, successAlert } = useAlert();
    const { login } = useAuth();
    const [rememberMeStorageValue] = useState(sessionService.getRememberMe());
    const [isShowTwoStepAuthPanel, setIsShowTwoStepAuthPanel] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabledNewQrCode, setIsDisabledNewQrCode] = useState(false);
    const [failedLoginCount, setFailedLoginCount] = useState<number>(0);
    const authCodeInputRef = useRef<string>('');
    const authTokenRef = useRef<string>('');
    const formValuesRef = useRef({
        username: rememberMeStorageValue?.username || '',
        password: rememberMeStorageValue?.password || '',
        isRememberMe: rememberMeStorageValue?.isRememberMe || false,
    });

    function setFormValue(key, value) {
        formValuesRef.current[key] = value;
    }

    const onLogin = () => {
        if (isFunction(login)) {
            login({
                authToken: authTokenRef.current,
            });
        }
    };

    //vissza gomb - login képernyőre navigál
    const onClickBackToLogin = () => {
        if (!sessionService.getRememberMe()) {
            formValuesRef.current = {
                username: '',
                password: '',
                isRememberMe: false,
            };
        }

        authTokenRef.current = '';

        setIsDisabledNewQrCode(false);
        setIsShowTwoStepAuthPanel(false);
        setFailedLoginCount(0);
    };

    //bejelentkezés
    async function onSubmit(event?: any) {
        try {
            setIsLoading(true);
            if (event) {
                event.preventDefault();
            }

            const formValues = formValuesRef.current;

            if (formValues.isRememberMe) {
                sessionService.setRememberMe(formValues.username, formValues.password);
            } else {
                sessionService.removeRememberMe();
            }

            const userDatas = {
                username: formValuesRef.current.username,
                password: formValuesRef.current.password,
                grant_type: 'password',
                langcode: toLower(i18n.language),
            };
            const user = await userService.login(userDatas);

            //hibás user adatok
            if (isEmpty(user?.access_token?.bearerToken)) {
                errorAlert(translater('login.incorrectData', 'Incorrect username or password'));
                return;
            }

            //elmentjük az auth tokent a baseService számára
            authTokenRef.current = user.access_token.bearerToken;

            //kétlépcsős authentikáció
            if (user?.access_token?.isUseTwoFactorAuth) {
                setIsShowTwoStepAuthPanel(true);
                return;
            }

            onLogin();
        } catch (error) {
            errorAlert(translater('default.unsuccessfulOperation', 'Unsuccessful operation'));
        } finally {
            setIsLoading(false);
        }
    }

    //2 lépcsős auth - bejelentkezés
    async function onSubmitTwoStepAuth(event?: any) {
        try {
            setIsLoading(true);
            if (event) {
                event.preventDefault();
            }

            const userToken = authCodeInputRef.current?.replaceAll(' ', '');
            const twoFactorProps = {
                userToken,
            };
            const isValid = await userService.getIsValidTwoFactorCode(twoFactorProps, authTokenRef.current);

            if (failedLoginCount + 1 > maxFailedCount) {
                errorAlert(translater('login.manyFailedLogin', 'Too many failed login attempts!'));
                setFailedLoginCount(0); //reseteljük a számlálót

                //reseteljük a user adatokat
                formValuesRef.current = {
                    username: '',
                    password: '',
                    isRememberMe: false,
                };

                //vissza megyünk a login képernyőre
                setIsShowTwoStepAuthPanel(false);
                return;
            }

            if (!isValid) {
                setFailedLoginCount(current => current + 1);
            }

            onLogin();
        } catch (error) {
            errorAlert(translater('default.unsuccessfulOperation', 'Unsuccessful operation'));
        } finally {
            setIsLoading(false);
        }
    }

    //ha beírtunk minden karaktert, akkor automatikusan submit
    const checkAuthCodeInputLength = (value: string) => {
        const userToken = value?.replaceAll(' ', '')?.replaceAll('_', '');
        const tokenCorrectLength = 6;

        if (size(userToken) === tokenCorrectLength) {
            authCodeInputRef.current = userToken;
            onSubmitTwoStepAuth();
        }
    };

    //e-mailben kérünk úgy qr kódot a kétlépcsős azonosításhoz
    const onClickCantUseApp = () => {
        if (isDisabledNewQrCode) {
            return;
        }

        renderConfirmDialog({
            questionText: translater('login.newQrCode', 'Generate a new QR code'),
            questionDescription: translater('login.newQrCodeQuestion', 'Would you like us to send you a new QR code for the authenticator app via email?'),
            confirmButtonType: ConfirmDialogButtonType.yesNo,
            onConfirm: async () => {
                try {
                    setIsLoading(true);
                    const isSuccess = await userService.getNewQrCode(authTokenRef.current);
                    if (isSuccess) {
                        successAlert(translater('login.reminderEmailSent', 'The reminder email has been sent successfully!'));
                        setIsDisabledNewQrCode(true);
                    } else {
                        errorAlert(translater('default.unsuccessfulOperation', 'Unsuccessful operation'));
                    }
                } catch (error) {
                    errorAlert(translater('default.unsuccessfulOperation', 'Unsuccessful operation'));
                } finally {
                    setIsLoading(false);
                }
            },
        });
    };

    return (
        <div className={styles.loginPanel}>
            <LoadingSpinner value={isLoading === false} />
            <div className={styles.loginPanelContainer}>

                <div className={styles.header}>
                    <h2 className={styles.title}>
                        {isShowTwoStepAuthPanel
                            ? (
                                <>
                                    <BackButton onClick={onClickBackToLogin} title={translater('login.backToLogin', 'Back to login')} />
                                    {t('login.twoFactorAuthentication', 'Two factor authentication')}
                                </>
                            )
                            : t('login.loginIntoAccount', 'Login into your account')
                        }
                    </h2>
                </div>

                {!isShowTwoStepAuthPanel ? (
                    <form onSubmit={onSubmit}>
                        <div className={styles.formInput}>
                            <Svg iconId="icon-person" className={styles.icon} />
                            <Input
                                placeholder={translater('login.username', 'Username')}
                                inputGroupClassName={styles.input}
                                required
                                onBlur={value => setFormValue('username', value)}
                                defaultText={formValuesRef.current?.username}
                                isAutoFocus
                                onKeyDown={(_value, event) => (event.code === KEY_CODE.ENTER || event.code === KEY_CODE.NUMPAD_ENTER) && onSubmit()}
                            />
                        </div>
                        <div className={styles.formInput}>
                            <Svg iconId="icon-lock_locked" className={styles.icon} />
                            <Input
                                type="password"
                                placeholder={translater('login.password', 'Password')}
                                inputGroupClassName={styles.input}
                                required
                                onBlur={value => setFormValue('password', value)}
                                defaultText={formValuesRef.current?.password}
                                onKeyDown={(_value, event) => (event.code === KEY_CODE.ENTER || event.code === KEY_CODE.NUMPAD_ENTER) && onSubmit()}
                            />
                        </div>
                        <div className={styles.row}>
                            <div className={styles.rowColumn}>
                                <Checkbox
                                    text={t('login.rememberMe', 'Remember me')}
                                    className={styles.rememberMe}
                                    onChange={state => setFormValue('isRememberMe', state)}
                                    isDefaultChecked={formValuesRef.current?.isRememberMe}
                                />
                            </div>
                            <div className={clsx(styles.rowColumn, styles.alignRight)} title={translater('login.forgetPassword', 'Forget password')}>
                                <a href="#forgetPassword" className={styles.forgetPassword}>{t('login.forgetPassword', 'Forget password')}</a>
                            </div>
                        </div>

                        <div className={styles.loginButtonWrapper}>
                            <Button text={t('login.loginButton', 'Login')} isSubmitButton />
                        </div>

                        <div className={clsx(styles.whiteText, styles.disabled)}>{t('login.dontHaveAccountQuestion', 'Dont have an account?')}
                            <a href="#register" className={clsx(styles.registerLink, styles.noSelect)} title={translater('login.registerHere', 'Register here')}>
                                {t('login.registerHere', 'Register here')}
                            </a>
                            (temp disabled)
                        </div>
                    </form>
                ) : (
                    <form onSubmit={onSubmitTwoStepAuth}>
                        <h4 className={styles.pleaseEnterCodeText}>
                            {t('login.pleaseEnterCode', 'Please enter the code you were sent:')}
                        </h4>
                        <div className={styles.formInput}>
                            <Input
                                mask="000 000"
                                inputGroupClassName={styles.authCodeInput}
                                onChange={checkAuthCodeInputLength}
                                onBlur={value => { authCodeInputRef.current = value; }}
                                required
                            />
                        </div>

                        {failedLoginCount > 0 && (
                            <div className={styles.failedLoginText}>
                                {t('login.incorrentCode', 'The provided code is incorrect!')} ({failedLoginCount}/{maxFailedCount})
                            </div>
                        )}

                        <div className={styles.loginButtonWrapper}>
                            <Button text={t('login.checkToken', 'Check token')} isSubmitButton />
                        </div>

                        <div className={styles.whiteText}>
                            <span
                                className={clsx(styles.cantUseAppLinkButton, styles.noSelect, isDisabledNewQrCode && styles.disabled)}
                                title={translater('login.cantUseApp', 'Can\'t use the app?')}
                                onClick={onClickCantUseApp}
                                role="button"
                                tabIndex={0}
                            >
                                {t('login.cantUseApp', 'Can\'t use the app?')}
                            </span>
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
}
