export const RIGHTS = {
    //beállítások menü
    SYSTEM_SETTINGS_ACCESS: 1,
    SYSTEM_SETTINGS_DEFAULT_SETTINGS_ACCESS: 2,

    //országok
    SYSTEM_SETTINGS_COUNTRY_LIST_ACCESS: 3,
    SYSTEM_SETTINGS_COUNTRY_ADD_OR_EDIT: 4,
    SYSTEM_SETTINGS_COUNTRY_DELETE: 5,

    //települések
    SYSTEM_SETTINGS_SETTLEMENT_LIST_ACCESS: 6,
    SYSTEM_SETTINGS_SETTLEMENT_ADD_OR_EDIT: 7,
    SYSTEM_SETTINGS_SETTLEMENT_DELETE: 8,

    //jogosultságok
    SYSTEM_SETTINGS_RIGHT_LIST_ACCESS: 9,
    SYSTEM_SETTINGS_RIGHT_ADD_OR_EDIT: 10,
    SYSTEM_SETTINGS_RIGHT_DELETE: 11,

    //beosztások
    SYSTEM_SETTINGS_JOB_POSITION_LIST_ACCESS: 12,
    SYSTEM_SETTINGS_JOB_POSITION_ADD_OR_EDIT: 13,
    SYSTEM_SETTINGS_JOB_POSITION_DELETE: 14,

    //felhasználók
    SYSTEM_SETTINGS_USER_LIST_ACCESS: 15,
    SYSTEM_SETTINGS_USER_ADD_OR_EDIT: 16,
    SYSTEM_SETTINGS_USER_DELETE: 17,

    //--------------------------------

    //side menü
    SIDE_MENU_SPACE_ADD_OR_EDIT: 18,
    SIDE_MENU_TICKET_LIST_ADD_OR_EDIT: 19,
    SIDE_MENU_SPACE_DELETE: 20,
    SIDE_MENU_TICKET_LIST_DELETE: 21,
};
