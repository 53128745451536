import { isNull, isUndefined } from 'lodash';

/**
 * @lang hu:
 * Visszaadja, hogy a megadott két string megegyezik-e
 *
 * @param {string} firstString Összehasonlításhoz az első string
 * @param {string} secondString Összehasonlításhoz a második string
 * @returns {boolean} boolean
 *
 *
 * @lang en:
 * Returns whether the specified two strings are the same
 *
 * @param {string} firstString For comparison, the first string
 * @param {string} secondString For comparison, the second string
 * @returns {boolean} boolean
 */
function isSame(firstString: string, secondString: string): boolean {
    return firstString?.toString() === secondString?.toString();
}

/**
 * @lang hu:
 * Visszaadja, hogy a megadott két string megegyezik-e figyelmen kívül hagyva a kis-nagybetűket
 *
 * @param {string} firstString Összehasonlításhoz az első string
 * @param {string} secondString Összehasonlításhoz a második string
 * @returns {boolean} boolean
 *
 *
 * @lang en:
 * Returns whether the specified two strings match, ignoring case
 *
 * @param {string} firstString For comparison, the first string
 * @param {string} secondString For comparison, the second string
 * @returns {boolean} boolean
 */
function isSameNoCase(firstString: string, secondString: string): boolean {
    return firstString?.toString()?.toLowerCase() === secondString?.toString()?.toLowerCase();
}

/**
 * @lang hu:
 * Megvizsgálja, hogy az első paraméterben megadott string tartalmazza a második paraméterben kapott string-et.
 * A vizsgálat során figyelmen kívül hagyja a kis-nagybetűket.
 * A harmadik paraméter "true" értékével figyelmen kívül tudjuk hagyni az ékezetes karaktereket is
 *
 * @param {string} firstString Összehasonlításhoz az első string aminek tartalmaznia kell a második string-et
 * @param {string} secondString Összehasonlításhoz a második string aminek benne kell lennie az első string-ben
 * @param {boolean} isNonAccentSearch Az ékezetes karakterek figyelmen kívül hagyása
 * @returns {boolean} boolean
 *
 *
 * @lang en:
 * Checks whether the string specified in the first parameter contains the string received in the second parameter.
 * It is case-insensitive during the scan.
 * With the value "true" of the third parameter, we can also ignore accented characters
 *
 * @param {string} firstString For comparison, the first string must contain the second string
 * @param {string} secondString For comparison, the second string that must be included in the first string
 * @param {boolean} isNonAccentSearch Ignore accented characters
 * @returns {boolean} boolean
 */
function isIncludesNoCase(firstString: string, secondString: string, isNonAccentSearch = false): boolean {
    function formatToSearch(string: string): string { //kereséshez azonos formára hozzuk
        return string.toString()?.toLowerCase();
    }

    function removeAccents(string: string): string { //ékezetes karakterek törlése
        const nonAccentString = string.toString().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        return formatToSearch(nonAccentString);
    }

    if (firstString && secondString) {
        const firstStringToCheck = isNonAccentSearch ? removeAccents(firstString) : formatToSearch(firstString);
        const secondStringToCheck = isNonAccentSearch ? removeAccents(secondString) : formatToSearch(secondString);

        return firstStringToCheck.includes(secondStringToCheck);
    }

    return false;
}

/**
 * @lang hu:
 * A kapott paramétert string típusban adja vissza.
 * Null vagy undefined esetén üres stringet ad vissza.
 *
 * @param {any} stringValue Kapott paraméter amit string típussá szeretnénk alakítani
 * @returns {string} string
 *
 *
 * @lang en:
 * Returns the received parameter in string type.
 * If null or undefined, it returns an empty string.
 *
 * @param {any} stringValue Received parameter that we want to convert to string type
 * @returns {string} string
 */
function getString(stringValue: any): string {
    if (isNull(stringValue) || isUndefined(stringValue)) {
        return '';
    }

    return stringValue.toString();
}

/**
 * A paraméterként megadott string-et kisbetűs formára alakítja, majd az első karaktert nagy betűre alakítja
 * @param {string} stringValue - A szöveg amit formázni szeretnénk
 * @returns {string} string
 */
function capitalizeFirstLetterUpper(stringValue: string): string {
    const lowerCaseString = stringValue ? stringValue.toLowerCase() : stringValue;
    return lowerCaseString ? lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1) : lowerCaseString;
}

export const stringUtils = {
    isSame,
    isSameNoCase,
    isIncludesNoCase,
    getString,
    capitalizeFirstLetterUpper,
};
