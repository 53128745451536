import { ModuleType } from '../ModuleContentTypes';

export const CONTACT_INFORMATION: ModuleType = {
    MODULE_ID: 1,
    MODULE_NAME: 'contactInformation',
    CONTENTS: {
        INDEX: {
            CONTENT_ID: 1,
            CONTENT_NAME: 'index',
            COMPONENT_URL: 'contents/contactInformation/ContactInformation',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.contactInformation',
                TRANSLATOR_DEFAULT: 'Contact information',
            },
        },
    },
};
