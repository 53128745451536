import { useMemo } from 'react';
import clsx from 'clsx';
import { isEmpty, toLower, toUpper } from 'lodash';
import { Simpleselect, SearchDataListItem } from 'theRising-baseComponents';
import { LANGUAGE, TRANSLATER_LANGUAGE_CODES } from '@constants';
import { useTranslate, i18n } from '@i18n';
import { dateTimeUtils } from '@utils';
import { sessionService } from '@services';
import styles from './languageSelector.module.scss';

type LanguageSelectorPropsType = {
    isShowSelectedIcon?: boolean,
    isShowOnlySelectedIcon?: boolean,
    className?: string,
};

export default function LanguageSelector(props: LanguageSelectorPropsType): React.ReactElement {
    const { isShowOnlySelectedIcon = true, isShowSelectedIcon, className } = props;
    const { translater } = useTranslate();

    const dataList = useMemo(() => TRANSLATER_LANGUAGE_CODES?.map(langCode => {
        let value = '';

        switch (toUpper(langCode)) {
            case LANGUAGE.CODE.ARGENTINA:
                value = translater('language.argentina', 'Spanish');
                break;
            case LANGUAGE.CODE.BULGARIAN:
                value = translater('language.bulgarian', 'Bulgarian');
                break;
            case LANGUAGE.CODE.GERMAN:
                value = translater('language.german', 'German');
                break;
            case LANGUAGE.CODE.ENGLISH:
                value = translater('language.english', 'English');
                break;
            case LANGUAGE.CODE.HUNGARIAN:
                value = translater('language.hungarian', 'Hungarian');
                break;
            case LANGUAGE.CODE.ROMANIAN:
                value = translater('language.romanian', 'Romanian');
                break;
            case LANGUAGE.CODE.RUSSIAN:
                value = translater('language.russian', 'Russian');
                break;
            case LANGUAGE.CODE.SLOVAK:
                value = translater('language.slovak', 'Slovak');
                break;
            case LANGUAGE.CODE.UKRAINIAN:
                value = translater('language.ukrainian', 'Ukrainian');
                break;
            case LANGUAGE.CODE.AZERBAIJANI:
                value = translater('language.azerbaijani', 'Azerbaijani');
                break;
            case LANGUAGE.CODE.SERBIAN:
                value = translater('language.serbian', 'Serbian');
                break;
            default:
                break;
        }

        return {
            key: langCode,
            value,
            iconId: `icon-flag-${langCode}`,
        };
    }), [translater]);

    const onChangeLanguage = (selectedItem: SearchDataListItem | null) => {
        if (selectedItem && !isEmpty(selectedItem) && TRANSLATER_LANGUAGE_CODES?.includes(selectedItem.key as string)) {
            const langCode = selectedItem.key as string;
            i18n.changeLanguage(langCode);
            dateTimeUtils.setMomentLocale(langCode);
            sessionService.setLangCode(langCode);
        }
    };

    return (
        <Simpleselect
            defaultSelectedKey={sessionService.getLangCode() || i18n.language || toLower(LANGUAGE.DEFAULT_LANGUAGE_CODE)}
            dataList={dataList}
            ishasSearchInput={false}
            isShowDeleteButton={false}
            className={clsx(styles.languageSelector, className)}
            isShowOnlySelectedIcon={isShowOnlySelectedIcon}
            isShowSelectedIcon={isShowSelectedIcon}
            onChange={onChangeLanguage}
            isIgnoreDocumentBodyClickOnToggle
        />
    );
}
