/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { isEmpty, size } from 'lodash';
import { Button,
    FormColumn,
    FormGroup,
    FormRow,
    Input,
    LoadingSpinner,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalWrapper,
    renderModal,
    Svg,
    ToggleSwitch,
    useAlert,
    WorkerAvatar } from 'theRising-baseComponents';
import { userService } from '@services';
import { useTranslate } from '@i18n';
import { useAuth } from '@screens/login/authProvider/AuthProvider';
import { UploadProfilePictureModal } from '@customComponents/modals';
import { CountrySimpleSelect, JobPositionSimpleSelect, SettlementSimpleSelect } from '@customComponents/simpleSelects';
import styles from './userProfileModal.module.scss';
import CurrentPasswordModal from '../currentPasswordModal/CurrentPasswordModal';
import useUserProfileForm from './useUserProfileForm';

type UserProfileModalPropsType = {
    onClose: (isIgnoreOnBeforeClose?: boolean) => void,
    setIsHasUnsavedData: (state: boolean) => void,
};

export default function UserProfileModal(props: UserProfileModalPropsType): React.ReactElement {
    const { onClose, setIsHasUnsavedData } = props;
    const { translater } = useTranslate();
    const { errorAlert } = useAlert();
    const { user } = useAuth();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    //two step auth
    const [isEnabledTwoStepAuth, setIsEnabledTwoStepAuth] = useState(user?.isUseTwoFactorAuth);
    const [isValidQrCode, setIsValidQrCode] = useState<boolean>(user?.isUseTwoFactorAuth || false);
    const [qrCodeImgSrc, setQrCodeImgSrc] = useState('');
    const authCodeInputValueRef = useRef<string>('');
    const authCodeInputRef = useRef<HTMLInputElement>(null);
    const isValidCurrentPasswordRef = useRef<boolean>(user?.isUseTwoFactorAuth || false);

    const {
        trigger,
        onSubmit,
        handleSubmit,
        setValue,
        setFormValue,
        getValues,
        errors,
        isLoadedForm,
        changeTheme,
    } = useUserProfileForm({ setIsHasUnsavedData, onClose });

    //két lépcsős azonosításhoz a qr kód (kép) lekérdezése
    const loadTwoFactorQRCode = useCallback(async () => {
        try {
            setIsLoading(true);
            const qrCodeData = await userService.getTwoFactorQRCode();
            setQrCodeImgSrc(qrCodeData.imgSrc);

            if (!user?.isUseTwoFactorAuth && authCodeInputRef.current) {
                authCodeInputRef.current.focus();
                authCodeInputRef.current.setSelectionRange(0, 0);
            }
        } catch (error) {
            errorAlert(translater('default.unsuccessfulOperation', 'Unsuccessful operation'));
        } finally {
            setIsLoading(false);
        }
    }, [errorAlert, translater, user?.isUseTwoFactorAuth]);

    //csak akkor engedjük bekapcsolni a két lépcsős azonosítást, ha megadjuk a korábbi jelszavunk
    const onBeforeChangeTwoFactorAuthEnabledState = async (isEnabled: boolean, changeCallback: any) => {
        if (isEnabled && !isValidCurrentPasswordRef.current) {
            renderModal({
                component: CurrentPasswordModal,
                componentProps: {
                    confirmCallbackFunction: async () => {
                        changeCallback();
                        setIsEnabledTwoStepAuth(true);
                        loadTwoFactorQRCode();
                        isValidCurrentPasswordRef.current = true;
                        setValue('isUseTwoFactorAuth', isEnabled, { shouldDirty: true });
                    },
                },
            });
            return;
        }

        //ide-oda kapcsolgatja a kétlépcső toggle-t
        if (isEnabled && isValidCurrentPasswordRef.current) {
            changeCallback();
            setIsEnabledTwoStepAuth(true);
            loadTwoFactorQRCode();
            isValidCurrentPasswordRef.current = true;
            setValue('isUseTwoFactorAuth', isEnabled, { shouldDirty: true });
            return;
        }

        changeCallback();
        setIsEnabledTwoStepAuth(false);
        setValue('isUseTwoFactorAuth', isEnabled, { shouldDirty: true });
    };

    //2 lépcsős auth - bejelentkezés
    async function onSubmitTwoStepAuth() {
        try {
            setIsLoading(true);
            const userToken = authCodeInputValueRef.current?.replaceAll(' ', '');
            const twoFactorProps = {
                userToken,
            };
            const isValid = await userService.getIsValidTwoFactorCode(twoFactorProps, user?.token as string);
            setIsValidQrCode(isValid);
            setFormValue('isValidQrCode', isValid);
        } catch (error) {
            errorAlert(translater('default.unsuccessfulOperation', 'Unsuccessful operation'));
        } finally {
            setIsLoading(false);
        }
    }

    //ha beírtunk minden karaktert, akkor automatikusan submit
    const checkAuthCodeInputLength = (value: string) => {
        const userToken = value?.replaceAll(' ', '')?.replaceAll('_', '');
        const tokenCorrectLength = 6;

        if (size(userToken) === tokenCorrectLength) {
            authCodeInputValueRef.current = userToken;
            onSubmitTwoStepAuth();
        }
    };

    //ha első betöltésnél be van kapcsolva a két lépcsős azonosítás, akkor lekérdezzük a képet
    useLayoutEffect(() => {
        if (user?.isUseTwoFactorAuth && isEmpty(qrCodeImgSrc)) {
            loadTwoFactorQRCode();
        }
    }, [loadTwoFactorQRCode, qrCodeImgSrc, user?.isUseTwoFactorAuth]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ModalWrapper>
                <ModalHeader className={styles.header}>
                    <Svg iconId="icon-person" className={styles.titleIcon} />
                    {translater('userProfile.myProfile', 'My profile')}
                </ModalHeader>
                <ModalBody>
                    <LoadingSpinner value={isLoadedForm} />

                    {/* Felhasználói adatok */}
                    <FormRow className={styles.formRowGroup}>
                        <FormColumn className={clsx(styles.leftSide, styles.informationLeftSide)}>
                            <span className={styles.leftSideTitle}>{translater('userProfile.profile', 'Profile')}</span>
                            <span className={styles.leftSideTitleDescription}>{translater('userProfile.personalInformation', 'Your personal information and account security settings.')}</span>
                        </FormColumn>

                        <FormColumn className={clsx(styles.rightSide, styles.dataRightSide, styles.userDefaultInfoWrapper)}>
                            <FormColumn className={clsx(styles.rightSide, styles.halfColumn)}>
                                <FormGroup text={translater('userProfile.avatar', 'Avatar')}>
                                    <label
                                        htmlFor="photo-upload"
                                        className={styles.avatarUpload}
                                        onClick={() => {
                                            renderModal({
                                                component: UploadProfilePictureModal,
                                                componentProps: {
                                                    //imageUrl: getValues('profilePicture'),
                                                    onSave: (imageUrl: string) => {
                                                        setFormValue('profilePicture', imageUrl);
                                                    },
                                                },
                                                width: 730,
                                                height: 680,
                                            });
                                        }}
                                    >
                                        <div className={clsx(styles.imageWrapper, styles.imageUpload)}>
                                            <WorkerAvatar
                                                imageUrl={getValues('profilePicture')}
                                                name={`${getValues('firstname')} ${getValues('lastname')}`}
                                                className={styles.avatar}
                                            />
                                        </div>
                                    </label>
                                    {!isEmpty(getValues('profilePicture')) && (
                                        <div className={styles.removeAvatar}>
                                            <span onClick={() => setFormValue('profilePicture', undefined)}>
                                                {translater('userProfile.removeAvatar', 'Remove avatar')}
                                            </span>
                                        </div>
                                    )}
                                </FormGroup>
                                <FormGroup text={translater('userProfile.username', 'Username')} isRequired isInvalid={isEmpty(getValues('username'))} errorMessage={errors.username?.message}>
                                    <Input
                                        placeholder={translater('userProfile.username', 'Username')}
                                        defaultText={getValues('username')}
                                        onBlur={value => setFormValue('username', value)}
                                        isDisabled
                                    />
                                </FormGroup>
                                <FormGroup text={translater('userProfile.firstName', 'First name')} isRequired isInvalid={isEmpty(getValues('firstname'))} errorMessage={errors.firstname?.message}>
                                    <Input
                                        placeholder={translater('userProfile.firstName', 'First name')}
                                        defaultText={getValues('firstname')}
                                        onBlur={value => setFormValue('firstname', value)}
                                    />
                                </FormGroup>
                                <FormGroup text={translater('userProfile.lastName', 'Last name')} isRequired isInvalid={isEmpty(getValues('lastname'))} errorMessage={errors.lastname?.message}>
                                    <Input
                                        placeholder={translater('userProfile.lastName', 'Last name')}
                                        defaultText={getValues('lastname')}
                                        onBlur={value => setFormValue('lastname', value)}
                                    />
                                </FormGroup>
                                <FormGroup text={translater('userProfile.email', 'Email')} isRequired isInvalid={isEmpty(getValues('email'))} errorMessage={errors.email?.message}>
                                    <Input
                                        placeholder={translater('userProfile.email', 'Email')}
                                        defaultText={getValues('email')}
                                        onBlur={value => setFormValue('email', value)}
                                    />
                                </FormGroup>
                                <FormGroup text={translater('userProfile.password', 'Password')} isRequired isInvalid={!isEmpty(errors.passwords?.message)}>
                                    <Input
                                        type="password"
                                        placeholder="********"
                                        defaultText={getValues('password')}
                                        onBlur={value => {
                                            setValue('password', value, { shouldDirty: true });

                                            if (!isEmpty(getValues('passwordAgain'))) {
                                                trigger(['passwords']);
                                            }
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup text={translater('userProfile.passwordAgain', 'Password again')} isRequired errorMessage={errors.passwords?.message}>
                                    <Input
                                        type="password"
                                        placeholder="********"
                                        defaultText={getValues('passwordAgain')}
                                        onBlur={value => {
                                            setValue('passwordAgain', value, { shouldDirty: true });

                                            if (!isEmpty(getValues('password'))) {
                                                trigger(['passwords']);
                                            }
                                        }}
                                    />
                                </FormGroup>
                            </FormColumn>
                            <FormColumn className={clsx(styles.rightSide, styles.halfColumn)} style={{ paddingTop: 110 }}>
                                <FormGroup text={translater('userProfile.phone', 'Phone')}>
                                    <Input
                                        placeholder={translater('userProfile.phone', 'Phone')}
                                        defaultText={getValues('phone')}
                                        onBlur={value => setFormValue('phone', value)}
                                    />
                                </FormGroup>
                                <FormGroup text={translater('userProfile.country', 'Country')}>
                                    <CountrySimpleSelect
                                        /*placeholder={translater('userProfile.phone', 'phone')}
                                        defaultText={getValues('phonef')}
                                        onBlur={value => setFormValue('lastname', value)}*/
                                    />
                                </FormGroup>
                                <FormGroup text={translater('userProfile.settlement', 'Settlement')}>
                                    <SettlementSimpleSelect
                                        /*placeholder={translater('userProfile.phone', 'phone')}
                                        defaultText={getValues('phonef')}
                                        onBlur={value => setFormValue('lastname', value)}*/
                                    />
                                </FormGroup>
                                <FormGroup text={translater('userProfile.address', 'Address')}>
                                    <Input
                                        placeholder={translater('userProfile.address', 'Address')}
                                        defaultText={getValues('address')}
                                        onBlur={value => setFormValue('address', value)}
                                    />
                                </FormGroup>
                                <FormGroup text={translater('userProfile.jobPosition', 'Job position')}>
                                    <JobPositionSimpleSelect
                                        /*placeholder={translater('userProfile.phone', 'phone')}
                                        defaultText={getValues('phonef')}
                                        onBlur={value => setFormValue('lastname', value)}*/
                                    />
                                </FormGroup>
                            </FormColumn>
                        </FormColumn>
                    </FormRow>

                    <div className={styles.separator} />

                    {/* 2 lépcsős authentikáció */}
                    <FormRow className={styles.formRowGroup}>
                        <FormColumn className={clsx(styles.leftSide, styles.informationLeftSide)}>
                            <span className={styles.leftSideTitle}>{translater('userProfile.twoFactorAuth', 'Two-factor authentication (2FA)')}</span>
                            <span className={styles.leftSideTitleDescription}>
                                {translater(
                                    'userProfile.keepYourAccountSecure',
                                    'Keep your account secure by enabling 2FA using a temporary one-time passcode (TOTP) from an authenticator app.'
                                )}
                            </span>
                        </FormColumn>

                        <FormColumn className={clsx(styles.rightSide, styles.dataRightSide)}>
                            <LoadingSpinner value={isLoading === false} />
                            <FormGroup text={translater('userProfile.enableWwoFactorAuth', 'Enable the two-factor authentication')}>
                                <ToggleSwitch onBeforeChange={onBeforeChangeTwoFactorAuthEnabledState} isDefaultChecked={user?.isUseTwoFactorAuth} />
                            </FormGroup>
                            {isEnabledTwoStepAuth && (
                                <FormGroup
                                    text={translater('userProfile.scanQRCode', 'Scan the QR code below with the authenticator app')}
                                    isRequired
                                    className={styles.scanQRCodeWrapper}
                                    isInvalid={!isValidQrCode}
                                    errorMessage={errors.isValidQrCode?.message}
                                >
                                    <img src={qrCodeImgSrc} alt="" width="220" height="220" />
                                    {!user?.isUseTwoFactorAuth && (
                                        <Input
                                            mask="000 000"
                                            inputGroupClassName={styles.authCodeInput}
                                            onChange={checkAuthCodeInputLength}
                                            onBlur={value => {
                                                authCodeInputValueRef.current = value;
                                            }}
                                            required
                                            isDisabled={isValidQrCode}
                                            ref={authCodeInputRef}
                                        />
                                    )}
                                </FormGroup>
                            )}
                        </FormColumn>
                    </FormRow>

                    <div className={styles.separator} />

                    {/* Téma használata */}
                    <FormRow className={styles.formRowGroup}>
                        <FormColumn className={clsx(styles.leftSide, styles.informationLeftSide)}>
                            <span className={styles.leftSideTitle}>{translater('userProfile.theme', 'Theme')}</span>
                            <span className={styles.leftSideTitleDescription}>
                                {translater(
                                    'userProfile.chooseTheme',
                                    'Choose a preferred theme for the app.'
                                )}
                            </span>
                        </FormColumn>

                        <FormColumn className={clsx(styles.rightSide, styles.dataRightSide)}>
                            <FormGroup text={translater('userProfile.usingDarkTheme', 'Using a dark theme')}>
                                <ToggleSwitch
                                    onChange={(isChecked: boolean) => {
                                        setValue('isUseDarkTheme', isChecked);

                                        if (isChecked) {
                                            changeTheme('dark');
                                        } else {
                                            changeTheme('light');
                                        }
                                    }}
                                    isDefaultChecked={user?.isUseDarkTheme}
                                />
                            </FormGroup>
                        </FormColumn>
                    </FormRow>
                </ModalBody>
                <ModalFooter className={styles.footer}>
                    <Button type="primary" style={{ width: 180 }} text={translater('default.save', 'Save')} isSubmitButton />
                    <Button type="secondary" style={{ width: 160 }} onClick={() => onClose()} text={translater('default.cancel', 'Cancel')} />
                </ModalFooter>
            </ModalWrapper>
        </form>
    );
}
