import { ContentType, ModuleType } from '@shared/contents/ModuleContentTypes';

/**
 * Visszaadja az URL-t a paraméterekkel együtt
 * @param urlString - URL string
 * @param parameters - Paraméterek
 * @returns Az URL a paraméterekkel együtt
 */
function getUrlWithParameters(urlString: string, parameters: any): string {
    let url = urlString;

    if (parameters) {
        Object.entries(parameters).forEach((parameter, index) => {
            if (parameter[0] && parameter[1] !== null) {
                if (index > 0) {
                    url += '&';
                } else {
                    url += '?';
                }

                url += `${parameter[0]}=${parameter[1]}`;
            }
        });
    }

    return url;
}

/**
 * Visszaadja a modul és a tartalom URL-jét egybe fésülve
 * @param module - Modul object
 * @param content - Content object
 * @returns A modul és a tartalom URL-je egybe fésülve
 */
function getContentUrl(module: ModuleType, content: ContentType): string {
    return `/${module.MODULE_NAME}/${content.CONTENT_NAME}`;
}

/**
 * A kapott URL keresési paramétereit objektummá alakítja
 * @param search - URL keresési paraméterek
 * @returns URL keresési paraméterek objektummá alakítva
 */
function getSearchParamsAsObject(search: string): any {
    //eltávolítjuk a "?" jelet az elejéről, ha van
    const queryString = search.startsWith('?') ? search.slice(1) : search;

    //URL keresési paramétereket objektummá alakítjuk
    const params = new URLSearchParams(queryString);
    const paramsObject = Array.from(params.entries()).reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
    }, {});

    return paramsObject;
}

export const routerHelper = {
    getUrlWithParameters,
    getContentUrl,
    getSearchParamsAsObject,
};
