import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { Svg } from 'theRising-baseComponents';
import styles from './sideMenuItem.module.scss';

type SideMenuItemPropsType = {
    iconId?: string,
    text: string,
    href?: string,
    isCollapsed?: boolean,
    isSelected?: boolean,
};

export default function SideMenuItem(props: SideMenuItemPropsType): React.ReactElement {
    const { iconId, text, href, isCollapsed, isSelected } = props;
    const navigate = useNavigate();

    const onClickMenuItem = (event: any) => {
        event.preventDefault();
        if (isSelected) {
            return;
        }

        if (href && !isEmpty(href)) {
            navigate(href);
        }
    };

    return (
        <a
            className={clsx(
                styles.menuItem,
                styles.disableSelectionColor,
                isCollapsed && styles.isCollapsed,
                isSelected && styles.isSelected
            )}
            href={href}
            title={text}
            onClick={onClickMenuItem}
        >
            {!isEmpty(iconId) && <Svg iconId={iconId as string} className={styles.menuItemIcon} />}
            {!isCollapsed && (
                <span className={clsx(styles.text, styles.overflowDots)}>
                    {text}
                </span>
            )}
        </a>
    );
}
