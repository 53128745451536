import { isEmpty, isNumber } from 'lodash';
import { useTranslate } from '@i18n';
import { Button,
    FormGroup,
    Input,
    LoadingSpinner,
    ModalLeftPanel,
    ModalLeftPanelBody,
    ModalLeftPanelFooter,
    ModalLeftPanelHeader,
    ModalWrapper,
    TextBox,
    ToggleSwitch,
    WorkerAvatar } from 'theRising-baseComponents';
import SpaceSelector from '@customComponents/spaceSelector/SpaceSelector';
import { SpaceType } from 'src/types/Space.types';
import styles from './ticketListEditModal.module.scss';
import ModalRightSide from './modalRightSide/ModalRightSide';
import useTicketListEditModal from './useTicketListEditModal';

type TicketListEditModalPropsType = {
    onClose: (isIgnoreOnBeforeClose?: boolean) => void,
    setIsHasUnsavedData: (state: boolean) => void,
    id: number,
    name: string,
    spaces: Array<SpaceType>,
    parentSpaceId?: number,
};

const TABLE_KEY = 'ticketListEditModal';

export default function TicketListEditModal(props: TicketListEditModalPropsType): React.ReactElement {
    const { onClose, setIsHasUnsavedData } = props;
    const { id, name, spaces, parentSpaceId } = props;
    const { translater } = useTranslate();

    const {
        onSubmit,
        handleSubmit,
        setFormValue,
        setValue,
        getValues,
        watch,
        errors,
        isLoadedForm,
        isLoadedRightSide,
        usersWithJobPositionList,
    } = useTicketListEditModal({ onClose, setIsHasUnsavedData, id, parentSpaceId });

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <ModalWrapper>
                <LoadingSpinner value={isLoadedForm} />
                <ModalLeftPanel>
                    <ModalLeftPanelHeader
                        title={id
                            ? translater('ticketListEditModal.editTicketList', 'Edit ticket list')
                            : translater('ticketListEditModal.createTicketList', 'Create ticket list')}
                        subTitle={name}
                    />
                    <ModalLeftPanelBody>
                        <FormGroup text={translater('ticketListEditModal.ticketListOwner', 'Ticket list owner')}>
                            <WorkerAvatar
                                name={getValues('ownerFullname') || undefined}
                                imageUrl={getValues('ownerProfilePics') || undefined}
                                isShowName
                            />
                        </FormGroup>
                        <FormGroup
                            text={translater('ticketListEditModal.parentSpace', 'Parent space')}
                            isInvalid={!isNumber(getValues('parentSpaceId'))}
                            errorMessage={errors.parentSpaceId?.message}
                            isRequired
                        >
                            <SpaceSelector
                                spaces={spaces}
                                defaultSelectedSpaceId={getValues('parentSpaceId')}
                                onChange={space => setFormValue('parentSpaceId', space.id)}
                            />
                        </FormGroup>
                        <FormGroup
                            text={translater('ticketListEditModal.name', 'Name')}
                            isInvalid={isEmpty(getValues('name'))}
                            errorMessage={errors.name?.message}
                            isRequired
                        >
                            <Input
                                placeholder={translater('ticketListEditModal.name', 'Name')}
                                defaultText={getValues('name')}
                                onBlur={value => setFormValue('name', value)}
                            />
                        </FormGroup>
                        <FormGroup text={translater('ticketListEditModal.description', 'Description')}>
                            <TextBox
                                defaultValue={getValues('description') || undefined}
                                className={styles.descriptionTextBox}
                                onBlur={value => setFormValue('description', value)}
                            />
                        </FormGroup>
                        <FormGroup text={translater('ticketListEditModal.restrictionOfAccess', 'Restriction of access')}>
                            <ToggleSwitch
                                isChecked={getValues('isRestrictedAccess')}
                                onChange={state => setFormValue('isRestrictedAccess', state)}
                            />
                        </FormGroup>
                        <FormGroup text={translater('ticketListEditModal.archived', 'Archived')}>
                            <ToggleSwitch
                                isChecked={getValues('isArchived')}
                                onChange={state => setFormValue('isArchived', state)}
                            />
                        </FormGroup>
                    </ModalLeftPanelBody>
                    <ModalLeftPanelFooter>
                        <Button text={translater('default.save', 'Save')} isSubmitButton />
                    </ModalLeftPanelFooter>
                </ModalLeftPanel>
                {watch('isRestrictedAccess') && (
                    <ModalRightSide
                        dataList={usersWithJobPositionList}
                        tableKey={TABLE_KEY}
                        selectedRowIds={getValues('accessUserIds')}
                        setValue={setValue}
                        isLoadedRightSide={isLoadedRightSide}
                    />
                )}
            </ModalWrapper>
        </form>
    );
}
