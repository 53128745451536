import { ModuleType } from '../ModuleContentTypes';

export const TIME_TRACKING: ModuleType = {
    MODULE_ID: 5,
    MODULE_NAME: 'timeTracking',
    CONTENTS: {
        INDEX: {
            CONTENT_ID: 1,
            CONTENT_NAME: 'index',
            COMPONENT_URL: 'contents/timeTracking/TimeTracking',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.timeTracking',
                TRANSLATOR_DEFAULT: 'Time tracking',
            },
        },
    },
};
