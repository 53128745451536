import { sendServerRequest } from '../baseService';

/**
 * Minden nyelvi kódhoz tartozó utolsó frissítés dátuma
 */
async function getVersions() {
    const response = await sendServerRequest('GET', '/api/language/getVersions');
    return response;
}

export const languageService = {
    getVersions,
};
