import { useRef } from 'react';
import { isFunction } from 'lodash';
import { Button, ImageCrop, ModalBody, ModalFooter, ModalHeader, ModalWrapper } from 'theRising-baseComponents';
import { useTranslate } from '@i18n';
import styles from './uploadProfilePictureModal.module.scss';

type UploadProfilePictureModalPropsType = {
    imageUrl?: string | null,
    onClose: (isIgnoreOnBeforeClose?: boolean) => void,
    onSave: (imageCropSrc: string | null) => void,
};

export default function UploadProfilePictureModal(props: UploadProfilePictureModalPropsType): React.ReactElement {
    const { imageUrl, onClose, onSave } = props;
    const { translater } = useTranslate();
    const imageCropSrc = useRef<string | null>(null);

    const onClickSave = () => {
        if (isFunction(onSave)) {
            onSave(imageCropSrc.current);
        }

        if (isFunction(onClose)) {
            onClose();
        }
    };

    return (
        <ModalWrapper>
            <ModalHeader>
                {translater('userProfile.uploadProfilePicture', 'Upload profile picture')}
            </ModalHeader>
            <ModalBody>
                <ImageCrop
                    src={imageUrl}
                    onChange={croppedImage => {
                        imageCropSrc.current = croppedImage;
                    }}
                    isUseCircle
                />
            </ModalBody>
            <ModalFooter className={styles.footer}>
                <Button type="primary" style={{ width: 180 }} text={translater('default.save', 'Save')} onClick={onClickSave} />
                <Button type="secondary" style={{ width: 160 }} onClick={() => onClose()} text={translater('default.cancel', 'Cancel')} />
            </ModalFooter>
        </ModalWrapper>
    );
}
