import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { isEmpty, size } from 'lodash';
import logoSrc from '@styles/img/logo.png';
import styles from './loginScreen.module.scss';
import LoginPanel from './loginScreenParts/loginPanel/LoginPanel';
import LanguageSelector from './languageSelector/LanguageSelector';
import ForgetPasswordPanel from './loginScreenParts/forgetPasswordPanel/ForgetPasswordPanel';
import RegistrationPanel from './loginScreenParts/registrationPanel/RegistrationPanel';
import ResetPasswordPanel from './loginScreenParts/resetPasswordPanel/ResetPasswordPanel';
import SetNewPasswordPanel from './loginScreenParts/setNewPasswordPanel/SetNewPasswordPanel';

const resetPasswordHashPartNumber = 2;

type LoginScreenPropsType = {
    isRequiredPasswordChange?: boolean,
};

export default function LoginScreen(props: LoginScreenPropsType): JSX.Element {
    const { isRequiredPasswordChange } = props;
    const location = useLocation();
    const isResetPasswordPanel = useMemo(() => location.pathname === '/resetPassword'
        && !isEmpty(location.hash)
        && size(location.hash.split('-')) === resetPasswordHashPartNumber
        && !isRequiredPasswordChange, [isRequiredPasswordChange, location]);
    const isLoginPanel = useMemo(() => (location.hash === '#login' || location.hash === '')
        && !isRequiredPasswordChange, [isRequiredPasswordChange, location]);
    const isForgetPasswordPanel = useMemo(() => location.hash === '#forgetPassword' && !isRequiredPasswordChange, [isRequiredPasswordChange, location]);
    const isRegisterPanel = useMemo(() => location.hash === '#register' && !isRequiredPasswordChange, [isRequiredPasswordChange, location]);

    return (
        <div className={styles.loginScreen}>
            <div className={styles.wrapper}>
                <div className={clsx(styles.column, styles.formGroup)}>
                    <div className={styles.columnContent}>
                        <div className={styles.logoWrapper}>
                            <img src={logoSrc} className={styles.logo} alt="" />
                        </div>

                        <div className={styles.contentWrapper}>
                            {isLoginPanel && (
                                <LoginPanel />
                            )}

                            {isForgetPasswordPanel && (
                                <ForgetPasswordPanel />
                            )}

                            {isRegisterPanel && (
                                <RegistrationPanel />
                            )}

                            {isResetPasswordPanel && (
                                <ResetPasswordPanel />
                            )}

                            {isRequiredPasswordChange && (
                                <SetNewPasswordPanel />
                            )}
                        </div>

                        <div className={styles.footer}>
                            <LanguageSelector />
                        </div>
                    </div>
                </div>

                <div className={clsx(styles.column, styles.imageContainer)} />
            </div>
        </div>
    );
}
