import { useContext, createContext, useLayoutEffect, useMemo, useState } from 'react';
//import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useTranslate } from '@i18n';
import { numberUtils } from '@utils';
import { LogHandler } from '@modules';
import { WEB_SOCKET } from '@shared/constants/webSocket';
import { useAlert } from 'theRising-baseComponents';
import { useAuth } from '../authProvider/AuthProvider';

export type WebSocketContextType = {
    notifications: string[],
    ws: WebSocket | undefined,
};

const WebSocketContext = createContext<WebSocketContextType>({
    notifications: [],
    ws: undefined,
});

const maxReconnectCount = 6;
const reconnectTime = 10000; //10 másodperc

function WebSocketProvider({ children }) {
    /*const navigate = useNavigate();*/
    const { translater } = useTranslate();
    const { infoAlert } = useAlert();
    const { user, reloadUserDatas } = useAuth();
    const [webSocket, setWebSocket] = useState();

    //websocket
    useLayoutEffect(() => {
        let isCancelled = false;

        function getPortFromLocation(): string {
            if (typeof window !== 'undefined' && window.location && window.location.port) {
                return `:${window.location.port}`;
            }
            return '';
        }

        if (!isEmpty(process.env?.WEB_SOCKET_URL) && numberUtils.getBoolean(process.env?.IS_USE_WEB_SOCKET)) {
            const port = getPortFromLocation();
            const proxyString = numberUtils.getBoolean(process.env?.IS_USE_WEB_SOCKET_PROXY) ? `${port}/ws` : '';
            let reconnectPeriod = 1;
            let reconnectCount = 0;
            let ws;

            const connect = () => {
                try {
                    ws = new WebSocket(`${process.env?.WEB_SOCKET_URL}${proxyString}`, `${user?.token}`);

                    ws.onopen = () => {
                        LogHandler.DevLog('[WS]Connected to WebSocket server');
                        reconnectCount = 0;
                        reconnectPeriod = 0;
                    };

                    ws.onmessage = event => {
                        const message = JSON.parse(event.data);
                        if (message.type === WEB_SOCKET.TYPE.UPDATE_SYSTEM_SETTINGS_USER) {
                            reloadUserDatas();
                            infoAlert(translater('websocket.userDataUpdated', 'User data updated by another user.'));
                        }
                    };

                    const tryReconnect = () => setTimeout(connect, reconnectTime * reconnectPeriod);

                    ws.onclose = event => {
                        if (isCancelled) {
                            LogHandler.DevLog('[WS]Disconnected from WebSocket server.');
                            return;
                        }

                        if (reconnectCount >= maxReconnectCount) {
                            LogHandler.DevLog('[WS]Reached max reconnect count.');
                            reconnectPeriod++; //növeljük a csatlakozási kíséreletek időközét
                            reconnectCount = 0;
                            tryReconnect();
                            return;
                        }

                        //megszakadt a kapcsolat
                        if (event.wasClean) {
                            LogHandler.DevLog('[WS]Disconnected from WebSocket server. Reconnecting...');
                            tryReconnect();
                            return;
                        }

                        LogHandler.DevLog('[WS]Try to reconnect...');
                        tryReconnect();
                        reconnectCount++;
                    };

                    /*ws.onerror = error => {
                        LogHandler.Error('[WS] ', error);
                    };*/

                    setWebSocket(ws);

                    return ws;
                } catch (error) {
                    setWebSocket(undefined);
                    //LogHandler.Error('[WS] ', error);
                }
            };

            connect();

            return () => {
                isCancelled = true;
                ws?.close();
            };
        }
    }, [infoAlert, reloadUserDatas, translater, user?.token]);

    const authContextProviderValue = useMemo(() => ({
        ws: webSocket,
    }), [webSocket]);

    return (
        <WebSocketContext.Provider value={authContextProviderValue as any}>
            {children}
        </WebSocketContext.Provider>
    );
}

export default WebSocketProvider;

/**
 * WebSocket értesítéseket és handler funkciókat tartalmazó context
 */
export const useWebSocket = () => {
    const context = useContext(WebSocketContext);

    if (context === undefined) {
        LogHandler.Error('useWebSocket must be used within a WebSocketProvider');
        return {
            ws: undefined,
        } as WebSocketContextType;
    }

    return context;
};
