import { memo } from 'react';
import { SpaceType } from 'src/types/Space.types';
import MenuItem from '../menuItem/MenuItem';
import styles from './menu.module.scss';

type MenuPropsType = {
    items: Array<SpaceType> | undefined,
    isCollapsed: boolean,
};

function Menu(props: MenuPropsType): React.ReactElement {
    const { items, isCollapsed } = props;

    return (
        <div className={styles.wrapper}>
            {items?.[0]?.children?.map((menuItem: SpaceType, index: number) => (
                <MenuItem
                    key={index}
                    menuItem={menuItem}
                    isCollapsed={isCollapsed}
                />
            ))}
        </div>
    );
}

export default memo(Menu);
