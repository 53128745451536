import { useState } from 'react';
import { isEmpty, isFunction } from 'lodash';
import { Button, FormGroup, Input, ModalBody, ModalFooter, ModalHeader, ModalWrapper } from 'theRising-baseComponents';
import { KEY_CODE } from '@constants';
import { useTranslate } from '@i18n';
import { useAuth } from '@screens/login/authProvider/AuthProvider';
import styles from './currentPasswordModal.module.scss';

type CurrentPasswordModalPropsType = {
    onClose: () => void,
    confirmCallbackFunction: () => void,
};

export default function CurrentPasswordModal(props: CurrentPasswordModalPropsType): React.ReactElement {
    const { onClose, confirmCallbackFunction } = props;
    const { translater } = useTranslate();
    const [password, setPassword] = useState<string>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const { getIsValidPassword } = useAuth();

    const onClickSave = (submitPassword?: string) => {
        if (isFunction(getIsValidPassword)) {
            const isValidPassword = getIsValidPassword(submitPassword || password || '');
            if (!isValidPassword) {
                setErrorMessage(translater('currentPasswordModal.invalidPassword', 'Invalid password!'));
                return;
            }
            setErrorMessage(undefined);
        }

        if (isFunction(confirmCallbackFunction)) {
            confirmCallbackFunction();
        }

        if (isFunction(onClose)) {
            onClose();
        }
    };

    return (
        <ModalWrapper>
            <ModalHeader>
                {translater('currentPasswordModal.enterPassword', 'Enter your password to setup 2FA')}
            </ModalHeader>
            <ModalBody>
                <FormGroup text={translater('currentPasswordModal.password', 'Password')} isRequired isInvalid={isEmpty(password)} errorMessage={errorMessage}>
                    <Input
                        type="password"
                        placeholder={translater('currentPasswordModal.password', 'Password')}
                        required
                        isAutoFocus
                        onChange={value => {
                            if (isEmpty(password) && !isEmpty(value)) { //első karakter után aktiváljuk már a mentés gombot
                                setPassword(value);
                            }
                        }}
                        onBlur={value => setPassword(value)}
                        onKeyDown={(_value, event: any) => {
                            if (event.code === KEY_CODE.ENTER || event.code === KEY_CODE.NUMPAD_ENTER) {
                                setPassword(event.target?.value);
                                onClickSave(event.target?.value);
                            }
                        }}
                    />
                </FormGroup>
            </ModalBody>
            <ModalFooter className={styles.footer}>
                <Button
                    type="primary"
                    style={{ width: 180 }}
                    onClick={() => onClickSave()}
                    text={translater('currentPasswordModal.confirmPassword', 'Confirm password')}
                    isDisabled={isEmpty(password)}
                />

                <Button type="secondary" style={{ width: 160 }} onClick={onClose} text={translater('default.cancel', 'Cancel')} />
            </ModalFooter>
        </ModalWrapper>
    );
}
