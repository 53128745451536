import { SpaceFormDataToSaveType } from '@screens/mainLayout/sideMenu/spaces/spaceEditModal/useSpaceEditModal';
import { TicketListFormDataToSaveType, TicketListType as TicketListTypeFromEditModal } from '@screens/mainLayout/sideMenu/spaces/ticketListEditModal/useTicketListEditModal';
import { SpaceType, TicketListType } from 'src/types/Space.types';
import { routerHelper, sendServerRequest } from '../baseService';

// ---------  getSpaces ------------

type GetSpacesPropsType = {
    isShowArchived?: boolean,
};

/**
 * Minden space-t lekér a szerverről
 */
async function getSpaces(props: GetSpacesPropsType): Promise<Array<SpaceType>> {
    const url = routerHelper.getUrlWithParameters('/api/space/getSpaces', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

// ---------  getSpaceById ------------

type GetSpaceById = {
    id: number,
};

/**
 * Id alapján space adatait lekéri
 */
async function getSpaceById(props: GetSpaceById): Promise<SpaceType> {
    const url = routerHelper.getUrlWithParameters('/api/space/getSpaceById', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

// ---------  setSpace ------------

/**
 * Space mentése
 */
async function setSpace(props: SpaceFormDataToSaveType): Promise<boolean> {
    const response = await sendServerRequest('POST', '/api/space/setSpace', props);
    return response;
}

// ---------  deleteSpace ------------

type DeleteSpacePropsType = {
    id: number,
};

/**
 * Space törlése
 */
async function deleteSpace(props: DeleteSpacePropsType): Promise<boolean> {
    const response = await sendServerRequest('DELETE', '/api/space/deleteSpace', props);
    return response;
}

// ---------  getTicketListById ------------

type GetTicketListByIdPropsType = {
    id: number,
};

/**
 * Ticket lista lekérdezése id alapján
 */
async function getTicketListById(props: GetTicketListByIdPropsType): Promise<TicketListTypeFromEditModal> {
    const url = routerHelper.getUrlWithParameters('/api/space/getTicketListById', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

// ---------  setTicketList ------------

/**
 * Ticket lista mentése
 */
async function setTicketList(props: TicketListFormDataToSaveType): Promise<boolean> {
    const response = await sendServerRequest('POST', '/api/space/setTicketList', props);
    return response;
}

// ---------  deleteTicketList ------------

type DeleteTicketListPropsType = {
    id: number,
};

/**
 * Ticket lista törlése
 */
async function deleteTicketList(props: DeleteTicketListPropsType): Promise<boolean> {
    const response = await sendServerRequest('DELETE', '/api/space/deleteTicketList', props);
    return response;
}

export const spaceService = {
    //space
    getSpaces,
    getSpaceById,
    setSpace,
    deleteSpace,
    //ticketlist
    getTicketListById,
    setTicketList,
    deleteTicketList,
};
