/* eslint-disable import/no-cycle */
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { isArray, isEmpty, isEqual, isFunction } from 'lodash';
import { Button, PopoverButton, useAlert } from 'theRising-baseComponents';
import { useTranslate } from '@i18n';
import { spaceService } from '@services';
import { SpaceType } from 'src/types/Space.types';
import styles from './spaceSelector.module.scss';
import SpaceMenu from './spaceMenu/SpaceMenu';

//megnézi, hogy a menüpontnak van-e gyereke -> ha van, akkor mappát jelöl
export function filterTreeWithoutChildren(menuItem: any) {
    //ha nincs "children" mező
    if (!menuItem || !isArray(menuItem.children)) {
        return null;
    }

    //ha van "children", rekurzívan feldolgozzuk az összes gyereket
    const filteredChildren = menuItem.children
        .map(child => filterTreeWithoutChildren(child)) //rekurzívan meghívjuk minden gyermekre
        .filter(child => child !== null); //null értékeket kiszűrjük

    //visszaadjuk a jelenlegi elemet a szűrt gyerekekkel
    return {
        ...menuItem,
        children: filteredChildren,
    };
}

//név alapján leszűri a fastruktúrát
export function filterTreeByName(space: any, nameSubstring: string | null) {
    if (!nameSubstring) {
        return space;
    }

    //ellenőrizzük, hogy a név tartalmazza-e a keresett töredéket
    const nodeMatches = space.name.toLowerCase().includes(nameSubstring.toLowerCase());

    //ha vannak gyerekek, akkor rekurzívan szűrjük őket is
    const filteredChildren = (space?.children || [])
        .map(child => filterTreeByName(child, nameSubstring))
        .filter(child => child !== null); //csak a nem-null értékeket tartjuk meg

    //ha a csomópont vagy valamelyik gyermeke megfelel, megtartjuk a csomópontot
    if (nodeMatches || filteredChildren.length > 0) {
        return {
            ...space,
            children: filteredChildren, //frissítjük a gyerekeket a szűrt gyerekekkel
        };
    }

    //ha sem a csomópont, sem a gyerekei nem felelnek meg, null-t adunk vissza
    return null;
}

//id alapján keresünk egy csomópontot a fastruktúrában
export function findSpaceById(space: any, id: number) {
    if (!space) {
        return space;
    }

    //ellenőrizzük, hogy a jelenlegi csomópont id-je megegyezik-e a keresett id-vel
    if (space.id === id && isArray(space.children)) {
        return space;
    }

    //ha vannak gyerekek, rekurzívan keresünk bennük
    if (space.children && space.children.length > 0) {
        // eslint-disable-next-line no-restricted-syntax
        for (const child of space.children) {
            const found = findSpaceById(child, id);
            if (found) {
                return found;
            }
        }
    }

    //ha sehol nem találtuk meg, null-t adunk vissza
    return null;
}

//kivétel id-k alapján szűri a fastruktúrát
export function filterTreeByExceptId(space: any, exceptSpaceIds: Array<number>) {
    if (!exceptSpaceIds) {
        return space;
    }

    //ellenőrizzük, hogy a név tartalmazza-e a keresett töredéket
    const isHasPermission = !exceptSpaceIds.includes(space.id);

    if (!isHasPermission) {
        return null;
    }

    //ha vannak gyerekek, akkor rekurzívan szűrjük őket is
    const filteredChildren = (space?.children || [])
        .map(child => filterTreeByExceptId(child, exceptSpaceIds))
        .filter(child => child !== null); //csak a nem-null értékeket tartjuk meg

    //ha a csomópont vagy valamelyik gyermeke megfelel, megtartjuk a csomópontot
    if (isHasPermission || filteredChildren.length > 0) {
        return {
            ...space,
            children: isArray(space.children) ? filteredChildren : undefined, //frissítjük a gyerekeket a szűrt gyerekekkel
        };
    }

    //ha sem a csomópont, sem a gyerekei nem felelnek meg, null-t adunk vissza
    return null;
}

type SpaceSelectorPropsType = {
    isDisabled?: boolean,
    spaces?: Array<SpaceType>,
    defaultSelectedSpaceId?: number,
    exceptSpaceIds?: Array<number>,
    onChange?: (space: SpaceType) => void,
};

export const rootItemId = 1;

export default function SpaceSelector(props: SpaceSelectorPropsType): React.ReactElement {
    const { isDisabled, spaces, defaultSelectedSpaceId, onChange, exceptSpaceIds } = props;
    const { translater } = useTranslate();
    const { errorAlert } = useAlert();
    const [rootSpace] = useState(({
        id: rootItemId,
        name: translater('spaceSelector.root', 'Root'),
    }));

    const [spaceList, setSpaceList] = useState<Array<SpaceType>>(spaces || []); //az összes space
    const defaultSelectedSpace = useMemo(() => (defaultSelectedSpaceId
        ? findSpaceById(spaceList?.[0], defaultSelectedSpaceId) || rootSpace
        : rootSpace
    ), [rootSpace, defaultSelectedSpaceId, spaceList]); //alapértelmezetten ez a space lesz kiválasztva

    const [selectedSpace, setSelectedSpace] = useState<any>(defaultSelectedSpace);
    const [selectedSpaceName, setSelectedSpaceName] = useState<string>(defaultSelectedSpace?.name);
    const exceptSpaceIdsCacheRef = useRef<Array<number> | undefined>();
    const spaceListCacheRef = useRef<Array<SpaceType>>([]);

    const onChangeSelectedSpace = useCallback((space: SpaceType) => {
        if (isFunction(onChange)) {
            onChange(space);
        }

        setSelectedSpaceName(space.name);
        setSelectedSpace(space);
    }, [onChange]);

    const CustomButton = useCallback((key: string) => (
        <div
            className={styles.showButtonWrapper}
            title={translater('spaceSelector.selectSpace', 'Select space')}
        >
            <Button
                key={key}
                iconId="icon-folder"
                className={clsx(styles.showButton, !isEmpty(selectedSpaceName) && styles.showButtonLeftAlign)}
                type="secondary"
                isDisabled={isDisabled}
                text={selectedSpaceName}
                isIgnoreUpperCase
            />
        </div>
    ), [isDisabled, selectedSpaceName, translater]);

    //ha props-ból nem kapja meg a space listát, akkor lekéri a szervertől
    useLayoutEffect(() => {
        let isCancelled = false;

        if (isEmpty(spaces)) {
            try {
                (async () => {
                    const newSpaceList = await spaceService.getSpaces({});

                    if (!isCancelled && newSpaceList) {
                        spaceListCacheRef.current = newSpaceList;
                        setSpaceList(newSpaceList);
                    }
                })();
            } catch (error) {
                errorAlert(translater('default.unsuccessfulOperation', 'Unsuccessful operation'));
            }
        }

        return () => {
            isCancelled = true;
        };
    }, [errorAlert, spaces, translater]);

    //ha le kell szűrni a listát az exceptSpaceIds alapján
    useEffect(() => {
        let isCancelled = false;

        if (exceptSpaceIds && !isEmpty(spaceList) && (!isEqual(spaceList, spaceListCacheRef.current) || !isEqual(exceptSpaceIds, exceptSpaceIdsCacheRef.current))) {
            const filteredNewSpaceList: Array<SpaceType> = [];

            spaceList?.forEach(space => {
                const filteredList = filterTreeByExceptId(space, exceptSpaceIds);
                if (filteredList) {
                    filteredNewSpaceList.push(filteredList);
                }
            });

            if (!isCancelled) {
                exceptSpaceIdsCacheRef.current = exceptSpaceIds;
                spaceListCacheRef.current = filteredNewSpaceList;
                setSpaceList(filteredNewSpaceList);

                if (defaultSelectedSpaceId) {
                    const space = findSpaceById(filteredNewSpaceList?.[0], defaultSelectedSpaceId) || rootSpace;
                    setSelectedSpace(space);
                    setSelectedSpaceName(space?.name);
                }
            }
        } else if (defaultSelectedSpaceId && isEmpty(exceptSpaceIds) && !isEmpty(spaceList) && isEqual(spaceList, spaceListCacheRef.current) && !isCancelled) {
            const space = findSpaceById(spaceList?.[0], defaultSelectedSpaceId) || rootSpace;
            setSelectedSpace(space);
            setSelectedSpaceName(space?.name);
        }

        return () => {
            isCancelled = true;
        };
    }, [defaultSelectedSpaceId, exceptSpaceIds, rootSpace, spaceList]);

    return (
        <div className={styles.spaceSelector}>
            <PopoverButton renderText={CustomButton} popoverClassName={styles.popoverContent}>
                <SpaceMenu items={spaceList} onChange={onChangeSelectedSpace} selectedId={selectedSpace?.id} />
            </PopoverButton>
        </div>
    );
}
