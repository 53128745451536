import { memo, useEffect, useLayoutEffect, useState } from 'react';
import clsx from 'clsx';
import { Button, LoadingSpinner, useAlert } from 'theRising-baseComponents';
import { useTranslate } from '@i18n';
import { spaceService } from '@services';
import { useAuth } from '@screens/login/authProvider/AuthProvider';
import { RIGHTS } from '@constants';
import { useWebSocket } from '@screens/login/webSocketProvider/WebSocketProvider';
import { WEB_SOCKET } from '@shared/constants/webSocket';
import { LogHandler } from '@modules';
import { SpaceType } from 'src/types/Space.types';
import styles from './spaces.module.scss';
import Menu from './menu/Menu';
import useSpaceFunctions from './useSpaceFunctions';

type SpacesPropsType = {
    isCollapsed: boolean,
};

function Spaces(props: SpacesPropsType): React.ReactElement {
    const { isCollapsed } = props;
    const { translater } = useTranslate();
    const { hasRight } = useAuth();
    const { ws } = useWebSocket();

    const { errorAlert } = useAlert();
    const [isLoaded, setIsLoaded] = useState(false);
    const [isShowArchived, setIsShowArchived] = useState(false);
    const [spaces, setSpaces] = useState<Array<SpaceType>>();
    const { onClickCreateSpace } = useSpaceFunctions();

    //space-ek betöltése
    useLayoutEffect(() => {
        let isCancelled = false;

        try {
            setIsLoaded(false);

            (async () => {
                const endPointParams = {
                    isShowArchived,
                };
                const spaceList = await spaceService.getSpaces(endPointParams);

                if (!isCancelled && spaceList) {
                    setSpaces(spaceList);
                }
            })();
        } catch (error) {
            errorAlert(translater('default.unsuccessfulOperation', 'Unsuccessful operation'));
        } finally {
            setIsLoaded(true);
        }

        return () => {
            isCancelled = true;
        };
    }, [errorAlert, isShowArchived, translater]);

    //websocket figyelése - space frissítés
    useEffect(() => {
        let isCancelled = false;

        try {
            if (!ws) {
                return;
            }

            const handleMessage = async event => {
                const message = JSON.parse(event.data);
                if (message.type === WEB_SOCKET.TYPE.UPDATE_SPACES) {
                    try {
                        setIsLoaded(false);
                        const endPointParams = {
                            isShowArchived,
                        };
                        const spaceList = await spaceService.getSpaces(endPointParams);

                        if (spaceList && !isCancelled) {
                            setSpaces(spaceList);
                        }
                    } catch (error) {
                        errorAlert(translater('default.unsuccessfulOperation', 'Unsuccessful operation'));
                    } finally {
                        setIsLoaded(true);
                    }
                }
            };

            ws.addEventListener('message', handleMessage);

            return () => {
                ws.removeEventListener('message', handleMessage);
                isCancelled = true;
            };
        } catch (error: any) {
            LogHandler.Error(error);
        }
    }, [errorAlert, isShowArchived, translater, ws]);

    return (
        <div className={styles.spacesWrapper}>
            <LoadingSpinner value={isLoaded} />

            <div className={styles.spaceHeaderWrapper}>
                {!isCollapsed && (
                    <span className={clsx(styles.spacesHeader, styles.disableSelectionColor)}>
                        {translater('sideMenu.spaces', 'Spaces')}
                    </span>
                )}
                <div className={styles.rightSide}>
                    {hasRight(RIGHTS.SIDE_MENU_SPACE_ADD_OR_EDIT) && (
                        <Button
                            iconId="icon-add"
                            type="secondary"
                            className={styles.spaceAddButton}
                            title={translater('sideMenu.addSpace', 'Add space')}
                            onClick={() => onClickCreateSpace(spaces?.[0], spaces)}
                        />
                    )}
                    {!isCollapsed && (
                        <Button
                            iconId={isShowArchived ? 'icon-visibility_show' : 'icon-visibility_hide'}
                            type="secondary"
                            className={clsx(styles.spaceAddButton, styles.spaceShowArchivedButton)}
                            title={isShowArchived
                                ? translater('sideMenu.hideArchivedTicketList', 'Hide archived ticket list')
                                : translater('sideMenu.showArchivedTicketList', 'Show archived ticket list')}
                            onClick={() => setIsShowArchived(currentState => !currentState)}
                        />
                    )}
                </div>
            </div>
            <div className={clsx(styles.spacesList, styles.verticalScrollbar)}>
                <Menu items={spaces} isCollapsed={isCollapsed} />
            </div>
        </div>
    );
}

export default memo(Spaces);
