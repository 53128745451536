//import 'theRising-baseComponents/style.css';
//import 'theRising-simpleTable/style.css';
import { memo, useLayoutEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ModalProvider } from 'theRising-baseComponents';
import TranslaterProvider from '@i18n/TranslaterProvider';
import { useThemeHandler } from '@styles/theme';
import { sessionService } from '@services';
import { LogHandler } from '@modules';
import AuthProvider from '@screens/login/authProvider/AuthProvider';
import WebSocketProvider from '@screens/login/webSocketProvider/WebSocketProvider';
import { withSimpleTable } from 'theRising-simpleTable';
import RouterProvider from './router/RouterProvider';
import './app.scss';

function App() {
    useThemeHandler(sessionService.getTheme());

    //svg-k betöltése ha még nincsenek betöltve
    useLayoutEffect(() => {
        if (!(window as any).isLoadingAllSvg && !document.getElementById('__svg__icons__dom__')) { //window használata kell, hogy más package ne írja felül a listát
            (window as any).isLoadingAllSvg = true;
            import('virtual:svg-icons-register').catch((error: any) => LogHandler.Error('Hiba történt az SVG ikonok regisztrálása közben:', error));
        }
    }, []);

    return (
        <Router>
            <TranslaterProvider>
                <AuthProvider>
                    <WebSocketProvider>
                        <ModalProvider>
                            <RouterProvider />
                        </ModalProvider>
                    </WebSocketProvider>
                </AuthProvider>
            </TranslaterProvider>
        </Router>
    );
}

export default memo(withSimpleTable(App));
