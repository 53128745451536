/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-magic-numbers */
import moment from 'moment/min/moment-with-locales';
import { lowerCase, isString, isEmpty, isObject, isUndefined, isNull, first, last } from 'lodash';
import { stringUtils } from './index';
import { DATE } from '../constants';

moment.locale('hu');
moment.suppressDeprecationWarnings = true;

// --------- General date functions ------------

/**
 * @lang hu:
 * @private Ezt csak utils funkción belül használjuk
 *
 *
 * @lang en:
 * @private This is only used within the utils function
 */
function getLangCodeFormat(langCode: string): string {
    let lowerCaseLangCode = lowerCase(langCode);

    if (stringUtils.isSame(lowerCaseLangCode, 'ar')) {
        lowerCaseLangCode = 'es';
    }

    return langCode;
}

/**
 * @lang hu:
 * @private Ezt csak utils funkción belül használjuk
 *
 *
 * @lang en:
 * @private This is only used within the utils function
 */
function getMomentObjectFromDateString(date: string): moment.Moment {
    return moment(date, 'L');
}

/**
 * @lang hu:
 * @private Ezt csak utils funkción belül használjuk
 *
 *
 * @lang en:
 * @private This is only used within the utils function
 */
function getDateMomentObject(date: string | null | undefined): moment.Moment | null {
    if (!date || isEmpty(date)) {
        return null;
    }

    if (!isValidDate(date)) {
        return null;
    }

    if (isDefaultDate(date)) {
        return moment(date, DATE.DEFAULT_DATE_FORMAT);
    }

    return moment(date, 'L');
}

/**
 * @lang hu:
 * @private Ezt csak utils funkción belül használjuk
 *
 *
 * @lang en:
 * @private This is only used within the utils function
 */
function getDateTimeMomentObject(date: string | null | undefined): moment.Moment | null {
    if (!date || isEmpty(date)) {
        return null;
    }

    if (!isValidDate(date)) {
        return null;
    }

    if (isDefaultDate(date)) {
        return moment(date, DATE.DEFAULT_DATE_TIME_FORMAT);
    }

    return moment(date, 'LLL');
}

function getDateNow() {
    return moment(new Date(), 'L').format('L');
}

function getDateTimeNow() {
    return moment(new Date(), 'L H:mm:ss').format('L H:mm:ss');
}

function getDateTimeNowAddMinutes(minutes = 0) {
    const date = getDateNowObject().add(minutes, 'minutes');
    return moment(date, 'L H:mm:ss').format(DATE.DEFAULT_DATE_TIME_FORMAT);
}

function getDefaultDateTimeNow() {
    const dateTime = getDateTimeNow();
    const defaultDateTime = getDefaultDateTimeFormat(dateTime);
    return defaultDateTime;
}

function getDateTimeAddMonths(dateTime: string, months = 0) {
    const date = moment(dateTime).add(months, 'M');
    return moment(date, 'L H:mm:ss').format(DATE.DEFAULT_DATE_TIME_FORMAT);
}

function getDateNowObject() {
    return moment(new Date(), 'L');
}

function getDateNowFirstDayOfMonth() {
    return getDate(moment().startOf('month') as any);
}

function getDateNowFirstDayOfYear() {
    return getDate(moment().startOf('year') as any);
}

function getDateNowAndAddDays(days = 0) {
    const date = getDateNowObject().add(days, 'days');
    return getDate(date as any);
}

function getDateNowAndAddMonths(months = 0) {
    const date = getDateNowObject().add(months, 'months');
    return getDate(date as any);
}

function getDefaultDateFormat(date: string) {
    if (isUndefined(date) || isNull(date)) {
        return null;
    }

    const formattedDate = getDateMomentObject(date);
    if (formattedDate) {
        return formattedDate.format(DATE.DEFAULT_DATE_FORMAT);
    }

    return formattedDate;
}

function getDefaultDateTimeFormat(date: string) {
    if (isUndefined(date) || isNull(date)) {
        return null;
    }

    const dateTimeParts = date.split(' ');
    const dateString = first(dateTimeParts);
    const timeString = last(dateTimeParts);
    const formattedDate = getDateMomentObject(dateString);
    if (formattedDate) {
        return `${formattedDate.format(DATE.DEFAULT_DATE_FORMAT)} ${timeString}`;
    }

    return formattedDate;
}

function getUTCFormat(date: string): number | null | undefined {
    if (!isValidDate(date)) {
        return null;
    }

    return getDateTimeMomentObject(date)?.utc()?.valueOf();
}

// --------- Other date functions ------------

/**
 * @lang hu:
 * Nyelvi kód alapján beállítjuk a moment számára a lokációt
 *
 * @param {string} langCode Nyelvi kód szöveges formátumban
 * @return {void}
 *
 *
 * @lang en:
 * We set the location for the moment based on the language code
 *
 * @param {string} langCode Language code in text format
 * @return {void}
 */
function setMomentLocale(langCode: string): void {
    const momentLangCode = getLangCodeFormat(langCode);
    moment.locale(momentLangCode);
}

/**
 * @lang hu:
 * Megvizsgálja, hogy a kapott dátum default (YYYY-MM-DD) formátumban van-e
 *
 * @param {string} date Dátum amit vizsgálni szeretnénk
 * @returns {boolean} boolean
 *
 *
 * @lang en:
 * Checks if the received date is in the default (YYYY-MM-DD) format
 *
 * @param {string} date Date we want to check
 * @returns {boolean} boolean
 */
function isDefaultDate(date: string): boolean {
    if (date && isString(date) && !isEmpty(date)) {
        const dateParts = date.split(' ')?.[0]?.split('-'); //2023-05-03 22:31:00 - ha van idő, akkor levágjuk a végéről, csak a dátumot nézzük

        if (dateParts.length === 3) {
            const yearPart = dateParts[0];
            const monthPart = dateParts[1];
            const dayPart = dateParts[2];

            if (yearPart.length === 4 && monthPart.length === 2 && dayPart.length === 2) {
                const fullDate = `${yearPart}-${monthPart}-${dayPart}`;

                const isValidMomentDate = moment(fullDate, DATE.DEFAULT_DATE_FORMAT, true).isValid();

                return isValidMomentDate;
            }
        }
    }

    return false;
}

/**
 * @lang hu:
 * A kapott dátum paramétert megvizsgálja, hogy valid dátum-e
 *
 * @param {string} date Dátum paraméter string formátumban, amit vizsgálni szeretnénk (lehet default formátumban is YYYY-MM-DD)
 * @returns {boolean} boolean
 *
 *
 * @lang en:
 * Checks the received date parameter to see if it is a valid date
 *
 * @param {string} date Date parameter in string format that we want to check (can also be in default format YYYY-MM-DD)
 * @returns {boolean} boolean
 */
function isValidDate(date: string): boolean {
    if (isEmpty(date) && !isObject(date)) {
        return false;
    }

    if (isDefaultDate(date)) {
        const dateObject = moment(date, DATE.DEFAULT_DATE_FORMAT);
        return dateObject.isValid();
    }

    if (isObject(date)) {
        return getMomentObjectFromDateString(date).isValid();
    }

    return date.includes('_')
        ? false
        : getMomentObjectFromDateString(date).isValid();
}

/**
 * @lang hu:
 * A kapott dátum paramétert a beállított nyelvnek megfelelő formátummá alakítja és string típusként visszaadja
 *
 * @param date Dátum paraméter string formátumban, amit formázni szeretnénk (lehet default formátumban is YYYY-MM-DD)
 * @returns {string} string
 *
 *
 * @lang en:
 * It converts the received date parameter into a format corresponding to the set language and returns it as a string type
 *
 * @param date Date parameter in string format that we want to format (it can also be in default format YYYY-MM-DD)
 * @returns {string} string
 */
function getDate(date: string | null | undefined): string {
    if (!date || (isEmpty(date) && !isObject(date))) {
        return '';
    }

    if (!isValidDate(date)) {
        return '';
    }

    if (isObject(date)) {
        const isValied = getMomentObjectFromDateString(date).isValid();
        if (isValied) {
            return moment(date, 'L').format('L');
        }
    }

    if (isDefaultDate(date)) {
        return moment(date, DATE.DEFAULT_DATE_FORMAT).format('L');
    }

    return moment(date, 'L').format('L');
}

/**
 * @lang hu:
 * A kapott dátum-idő paramétert a beállított nyelvnek megfelelő formátummá alakítja és string típusként visszaadja
 *
 * @param dateTime Dátum-idő paraméter string formátumban, amit formázni szeretnénk (lehet default formátumban is YYYY-MM-DD hh:mm:ss)
 * @returns {string} string
 *
 *
 * @lang en:
 * It converts the received date-time parameter into a format corresponding to the set language and returns it as a string type
 *
 * @param date Date-time parameter in string format that we want to format (it can also be in default format YYYY-MM-DD hh:mm:ss)
 * @returns {string} string
 */
function getDateTime(dateTime: string | null | undefined): string {
    if (!dateTime || (isEmpty(dateTime) && !isObject(dateTime))) {
        return '';
    }

    if (!isValidDate(dateTime)) {
        return '';
    }

    if (isDefaultDate(dateTime)) {
        return moment(dateTime, DATE.DEFAULT_DATE_TIME_FORMAT).format('L LTS');
    }

    return moment(dateTime, 'LLL').format('L LTS');
}

function getDateTimeFromDateObj(date: any): string {
    return moment(date, 'LLL').format('L LTS');
}

/**
 * @lang hu:
 * Megvizsgálja, hogy az első (date1) paraméter a második (date2) paraméter utáni dátumot tartalmaz-e
 *
 * @param {string} date1 Dátum ami vizsgálunk, hogy későbbi időpontot mutat-e mint a másik paraméter
 * @param {string} date2 Dátum amihez viszonyítunk
 * @returns {boolean} boolean
 *
 *
 * @lang en:
 * Checks if the first (date1) parameter contains a date after the second (date2) parameter
 *
 * @param {string} date1 Date, which we check to see if it shows a later date than the other parameter
 * @param {string} date2 Date to which we compare
 * @returns {boolean} boolean
 */
function isAfterDate(date1: string, date2: string): boolean {
    const dateFormatted = getDateMomentObject(date1);
    const afterDateFormatted = getDateMomentObject(date2);

    if (!dateFormatted) {
        return false;
    }

    if (!afterDateFormatted) {
        return true;
    }

    return dateFormatted.valueOf() > afterDateFormatted.valueOf();
}

export const dateTimeUtils = {
    setMomentLocale,
    isValidDate,
    isAfterDate,
    isDefaultDate,
    //formatter
    getDate,
    getDateTime,
    getDefaultDateFormat,
    getDefaultDateTimeFormat,
    getUTCFormat,
    //getter
    getDateNowFirstDayOfMonth,
    getDateNowFirstDayOfYear,
    getDateNow,
    getDateTimeNow,
    getDateTimeNowAddMinutes,
    getDateTimeAddMonths,
    getDateNowAndAddDays,
    getDateNowAndAddMonths,
    getDateNowObject,
    getMomentObjectFromDateString,
    getDateMomentObject,
    getDateTimeMomentObject,
    getDateTimeFromDateObj,
    getDefaultDateTimeNow,
};
