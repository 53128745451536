import { memo, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { LoadingSpinner } from 'theRising-baseComponents';
import LoginScreen from '@screens/login/LoginScreen';
import LoginRoute from '@screens/login/authProvider/LoginRoute';
import MainLayout from '@screens/mainLayout/MainLayout';
import Home from '@contents/home/Home';
import { Error404, Error401 } from '@screens/errors';
import useRouter from './useRouter';
import styles from './routerProvider.module.scss';

function RouterProvider(): React.ReactElement {
    const { routeList } = useRouter();

    return (
        <Suspense fallback={<LoadingSpinner className={styles.loadingSpinner} />}>
            <Routes>
                <Route element={<LoginRoute />}>
                    <Route path="/resetPassword" element={<LoginScreen />} />
                    <Route path="/login" element={<LoginScreen />} />
                    <Route path="/register" element={<LoginScreen />} />
                    <Route path="/forgetPassword" element={<LoginScreen />} />
                </Route>

                <Route element={<MainLayout />}>
                    <Route path="/" element={<Home />} />
                    {routeList?.map((routerObj, index) => (
                        <Route key={index} path={routerObj.path} element={routerObj.isHasPermission ? <routerObj.component /> : <Error401 />} />
                    ))}
                </Route>

                {/* Catch-all route for undefined routes */}
                <Route path="*" element={<Error404 />} />
            </Routes>
        </Suspense>
    );
}

export default memo(RouterProvider);
