import { useLayoutEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import { SearchDataListItem, Simpleselect, useAlert } from 'theRising-baseComponents';
import { useTranslate } from '@i18n';
import { dataListService } from '@services';

type SettlementSimpleSelectPropsType = {
    onChange: (selectedItem: SearchDataListItem | null) => void,
    dataList?: Array<SearchDataListItem>,
    countryId?: number,
    selectedKey?: number | string | undefined | null;
};

export default function SettlementSimpleSelect(props: SettlementSimpleSelectPropsType): React.ReactElement {
    const { onChange, dataList, countryId, selectedKey } = props;
    const { errorAlert } = useAlert();
    const { translater } = useTranslate();
    const [settlements, setSettlements] = useState<Array<SearchDataListItem>>([]);
    const simpleSelectRef = useRef<any>(null);

    //adatlista betöltése
    useLayoutEffect(() => {
        let isCancelled = false;

        try {
            if (simpleSelectRef.current) {
                simpleSelectRef.current?.changeSelectedText(undefined);
            }

            if (!countryId) {
                return;
            }

            //ha kívülről kapja meg az adatlistát
            if (!isEmpty(dataList) && !isCancelled) {
                setSettlements((currentState: Array<SearchDataListItem>) => {
                    if (!isEmpty(currentState)) {
                        return currentState;
                    }
                    return dataList || [];
                });
                return;
            }

            (async () => {
                let settlementList = await dataListService.getSettlements({
                    countryId,
                });
                settlementList = settlementList?.map((resultItem: any) => ({
                    key: resultItem.id,
                    value: `${resultItem.name} (${resultItem.zipCode})`,
                }));

                if (!isCancelled && settlementList) {
                    setSettlements(settlementList);
                }
            })();
        } catch (error) {
            errorAlert(translater('default.unsuccessfulOperation', 'Unsuccessful operation'));
        }

        return () => {
            isCancelled = true;
        };
    }, [countryId, dataList, errorAlert, translater]);

    return (
        <Simpleselect
            selectedKey={selectedKey}
            dataList={settlements}
            onChange={onChange}
            ref={simpleSelectRef}
        />
    );
}
