import { useTranslate } from '@i18n';
import { HTTP_STATUS } from '@constants';
import BaseError from '../baseError/BaseError';

export default function Error503(): React.ReactElement {
    const { translater } = useTranslate();

    return (
        <BaseError
            errorCode={HTTP_STATUS.CODE.SERVICE_UNAVAILABLE}
            errorTitle={translater(
                'errorScreen.errorTitle503',
                'Houston, we have a server malfunction!'
            )}
            errorMessage={translater(
                'errorScreen.errorMessage503',
                `We're sorry, but the server is taking a coffee break. 
                It's hard work keeping the galaxy in order! Please try again later.`
            )}
        />
    );
}
