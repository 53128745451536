import { ModuleType } from '../ModuleContentTypes';

export const TRAVEL_LOG: ModuleType = {
    MODULE_ID: 6,
    MODULE_NAME: 'travelLog',
    CONTENTS: {
        INDEX: {
            CONTENT_ID: 1,
            CONTENT_NAME: 'index',
            COMPONENT_URL: 'contents/travelLog/TravelLog',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.travelLog',
                TRANSLATOR_DEFAULT: 'Travel log',
            },
        },
    },
};
