import { useState } from 'react';
import clsx from 'clsx';
import { PopoverButton, renderModal, Svg, WorkerAvatar } from 'theRising-baseComponents';
import { useAuth } from '@screens/login/authProvider/AuthProvider';
import { useTranslate } from '@i18n';
import LanguageSelector from '@screens/login/languageSelector/LanguageSelector';
import menuItemStyles from './menuItem/menuItem.module.scss';
import styles from './userMenu.module.scss';
import MenuItem from './menuItem/MenuItem';
import UserProfileModal from '../userProfileModal/UserProfileModal';

export default function UserMenu(): React.ReactElement {
    const [isShowedMenu, setIsShowedMenu] = useState(false);
    const { user, logOut } = useAuth();
    const { translater } = useTranslate();

    return (
        <PopoverButton
            className={styles.popoverButton}
            popoverClassName={styles.popoverContent}
            placement="bottom"
            onChange={state => setIsShowedMenu(state)}
            renderText={() => (
                <button key="userMenu" className={styles.userMenuToggle} type="button">
                    <WorkerAvatar
                        imageUrl={user?.profilePicture}
                        name={user?.fullname}
                        className={styles.avatar}
                    />
                    <Svg iconId={isShowedMenu ? 'icon-arrow_drop_up' : 'icon-arrow_drop_down'} className={styles.dropdownIcon} />
                </button>
            )}
        >
            <div className={styles.popoverContentWrapper}>
                <div className={styles.menuHeader}>
                    <WorkerAvatar
                        imageUrl={user?.profilePicture}
                        name={user?.fullname}
                        company={user?.userTypeName}
                        className={styles.avatarBig}
                        isShowName
                    />
                </div>
                <div className={styles.menuBody}>
                    <MenuItem
                        iconId="icon-person"
                        text={translater('userMenu.profile', 'Profile')}
                        onClick={() => renderModal({
                            component: UserProfileModal,
                            width: 1400,
                            //height: 500,
                            isShowCloseConfirm: true,
                        })}
                    />
                    <MenuItem
                        iconId="icon-settings"
                        text={translater('userMenu.settings', 'Settings')}
                        onClick={() => console.log('Settings')}
                    />
                </div>
                <div className={clsx(styles.menuBody, styles.separator, styles.flex)}>
                    <div className={styles.menuItemTextWrapper}>
                        <Svg iconId="icon-flag_active" className={menuItemStyles.menuItemIcon} />
                        <span className={clsx(menuItemStyles.menuItemText, styles.disableSelectionColor)}>
                            {translater('userMenu.language', 'Language')}
                        </span>
                    </div>
                    <LanguageSelector isShowOnlySelectedIcon={false} className={styles.userMenuLanguageSelector} isShowSelectedIcon />
                </div>
                <div className={styles.menuFooter}>
                    <MenuItem iconId="icon-logout" text={translater('userMenu.logout', 'Log out')} onClick={() => logOut()} />
                </div>
            </div>
        </PopoverButton>
    );
}
