import { useLayoutEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { SearchDataListItem, Simpleselect, useAlert } from 'theRising-baseComponents';
import { useTranslate } from '@i18n';
import { dataListService } from '@services';

type CountrySimpleSelectPropsType = {
    onChange: (selectedItem: SearchDataListItem | null) => void;
    dataList?: Array<SearchDataListItem>;
    selectedKey?: number | string | undefined;
    isShowDeleteButton?: boolean;
};

export default function CountrySimpleSelect(props: CountrySimpleSelectPropsType): React.ReactElement {
    const { onChange, dataList, selectedKey, isShowDeleteButton = false } = props;
    const { errorAlert } = useAlert();
    const { translater } = useTranslate();
    const [countries, setCountries] = useState<Array<SearchDataListItem>>([]);

    //adatlista betöltése
    useLayoutEffect(() => {
        let isCancelled = false;

        try {
            //ha kívülről kapja meg az adatlistát
            if (!isEmpty(dataList) && !isCancelled) {
                setCountries((currentState: Array<SearchDataListItem>) => {
                    if (!isEmpty(currentState)) {
                        return currentState;
                    }
                    return dataList || [];
                });
                return;
            }

            (async () => {
                let countryList = await dataListService.getCountries();
                countryList = countryList?.map((resultItem: any) => ({ key: resultItem.id, value: resultItem.name, langCode: resultItem.langCode }));

                if (!isCancelled && countryList) {
                    setCountries(countryList);
                }
            })();
        } catch (error) {
            errorAlert(translater('default.unsuccessfulOperation', 'Unsuccessful operation'));
        }

        return () => {
            isCancelled = true;
        };
    }, [dataList, errorAlert, translater]);

    return (
        <Simpleselect
            selectedKey={selectedKey}
            dataList={countries}
            onChange={onChange}
            isShowDeleteButton={isShowDeleteButton}
        />
    );
}
