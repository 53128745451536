import { useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import { Button, Input, LoadingSpinner, useAlert } from 'theRising-baseComponents';
import { useTranslate } from '@i18n';
import { sessionService, userService } from '@services';
import BackButton from '@baseComponents/buttons/backButton/BackButton';
import { useAuth } from '@screens/login/authProvider/AuthProvider';
import { GENERAL, KEY_CODE } from '@constants';
import styles from './setNewPasswordPanel.module.scss';

const passwordMinCharacter = GENERAL.PASSWORD_MIN_CHARACTER;

export default function SetNewPasswordPanel(): React.ReactElement {
    const { t, translater } = useTranslate();
    const { errorAlert, successAlert } = useAlert();
    const { user, logOut, getIsValidPassword, setNewPassword } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const formValuesRef = useRef({
        password: '',
        passwordAgain: '',
    });

    function setFormValue(key, value) {
        formValuesRef.current[key] = value;
    }

    async function onSubmit(event?: any) {
        try {
            setIsLoading(true);
            if (event) {
                event.preventDefault();
            }

            const { password, passwordAgain } = formValuesRef.current;

            //hibaüzenetek
            if (password !== passwordAgain) {
                setErrorMessage(translater('setNewPassword.passwordsNotMatch', 'The two passwords do not match!'));
                return;
            }

            const numberPattern = /[0-9]/;
            const uppercasePattern = /[A-Z]/;
            if (password.length < passwordMinCharacter || !numberPattern.test(password) || !uppercasePattern.test(password)) {
                setErrorMessage(translater(
                    'setNewPassword.passwordError',
                    'The password must be at least 8 characters long and include at least one number, one lowercase letter, and one uppercase letter.'
                ));
                return;
            }

            if (getIsValidPassword(password)) {
                setErrorMessage(translater('setNewPassword.samePasswordWithPreviously', 'The provided password cannot be the same as any of your previously used passwords!'));
                return;
            }

            //mentés
            const newPasswordProps = {
                password,
                isPeriodicChange: true,
            };
            const isSuccessReset = await userService.setPeriodicPassword(newPasswordProps);

            if (isSuccessReset) {
                successAlert(translater('setNewPassword.successfullPasswordChange', 'Successful password change!'));
                setNewPassword(password);

                //emlékezz rám storage frissítése
                const rememberMe = sessionService.getRememberMe();
                if (rememberMe?.isRememberMe) {
                    sessionService.setRememberMe(rememberMe.username, password);
                }
            } else {
                errorAlert(translater('default.unsuccessfulOperation', 'Unsuccessful operation'));
            }
        } catch (error) {
            errorAlert(translater('default.unsuccessfulOperation', 'Unsuccessful operation'));
        } finally {
            setIsLoading(false);
        }
    }

    //vissza gomb - main képernyőre navigál
    const onClickBackToLogin = () => {
        logOut();
    };

    return (
        <div className={styles.resetPasswordPanel}>
            <LoadingSpinner value={isLoading === false} />
            <form onSubmit={onSubmit}>
                <div className={styles.titleWrapper}>
                    <BackButton onClick={onClickBackToLogin} title={translater('login.backToLogin', 'Back to login')} />
                    <h2 className={styles.resetYourPassword}>
                        {t('setNewPassword.enterNewPassword', 'Enter new password')}
                    </h2>
                </div>

                <div>
                    <h3 className={styles.dearUserText}>
                        {`${translater('setNewPassword.dear', 'Dear')} ${user?.username}!`}
                    </h3>
                    <h4 className={styles.expiredPasswordText}>
                        {t('setNewPassword.expiredPasswordText', 'The password you are using has expired due to the periodic password change policy. Please enter a new password.')}
                    </h4>
                </div>

                <div className={styles.labelWrapper}>
                    <span className={styles.label}>
                        {t('setNewPassword.password', 'Password')}
                    </span>
                </div>
                <div className={styles.formInput}>
                    <Input
                        type="password"
                        inputGroupClassName={styles.passwordInput}
                        onBlur={value => setFormValue('password', value)}
                        required
                        isAutoFocus
                        onKeyDown={(_value, event) => (event.code === KEY_CODE.ENTER || event.code === KEY_CODE.NUMPAD_ENTER) && onSubmit()}
                    />
                </div>

                <div className={styles.labelWrapper}>
                    <span className={styles.label}>
                        {t('setNewPassword.passwordAgain', 'Password again')}
                    </span>
                </div>
                <div className={styles.formInput}>
                    <Input
                        type="password"
                        inputGroupClassName={styles.passwordInput}
                        onBlur={value => setFormValue('passwordAgain', value)}
                        required
                        onKeyDown={(_value, event) => (event.code === KEY_CODE.ENTER || event.code === KEY_CODE.NUMPAD_ENTER) && onSubmit()}
                    />
                </div>

                {!isEmpty(errorMessage) && (
                    <span className={styles.errorMessage}>
                        {errorMessage}
                    </span>
                )}

                <div className={styles.saveButtonWrapper}>
                    <Button text={t('default.save', 'Save')} isSubmitButton />
                </div>
            </form>
        </div>
    );
}
