import { useCallback, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { LoadingSpinner, ModalRightPanel } from 'theRising-baseComponents';
import { ColumnPropsType, columnTypes, GlobalSearcherInput, SimpleTable } from 'theRising-simpleTable';
import { useTranslate } from '@i18n';
import styles from './modalRightSide.module.scss';

type ModalRightSidePropsType = {
    tableKey: string,
    dataList: Array<any>,
    selectedRowIds?: Array<number>,
    setValue: UseFormSetValue<any>,
    isLoadedRightSide: boolean,
};

export default function ModalRightSide(props: ModalRightSidePropsType): React.ReactElement {
    const { tableKey, dataList, selectedRowIds, setValue, isLoadedRightSide } = props;
    const { translater } = useTranslate();

    const [defaultGroupBy] = useState<Array<string>>(['jobPositionName']);
    const [columns] = useState<Array<ColumnPropsType>>([
        {
            ...columnTypes.simpleText,
            columnId: 'name',
            columnName: translater('spaceEditModal.name', 'Name'),
            isLeftFixed: true,
            width: 280,
        },
        {
            ...columnTypes.simpleText,
            columnId: 'email',
            columnName: translater('spaceEditModal.email', 'Email'),
            maxWidth: 240,
        },
        {
            ...columnTypes.simpleText,
            columnId: 'jobPositionName',
            columnName: translater('spaceEditModal.jobPosition', 'Job position'),
            maxWidth: 240,
        },
    ]);

    const getRowId = useCallback(dataRow => dataRow.userId, []);

    return (
        <ModalRightPanel>
            <div className={styles.rightSideHeader}>
                <span className={styles.rightsLabel}>
                    {translater('spaceEditModal.spaceAccess', 'Space access')}
                </span>
            </div>
            <div className={styles.rightSideWrapper}>
                <LoadingSpinner value={isLoadedRightSide} />
                <GlobalSearcherInput tableKey={tableKey} className={styles.globalSearcherInput} />
                <SimpleTable
                    tableKey={tableKey}
                    columns={columns}
                    dataList={dataList}
                    groupBy={defaultGroupBy}
                    selectedRowIds={selectedRowIds}
                    isShowedTableFooter={false}
                    isUseDndColumnOrder={false}
                    isUseRightClickCustomColumnView={false}
                    isShowedColumnOrderButton={false}
                    isEnabledRowSelection
                    onChangeSelectedRowIds={selectedRows => {
                        const selectedUserIds: Array<number> = [];

                        selectedRows?.forEach(row => {
                            if (row.userId && !selectedUserIds.includes(row.userId)) {
                                selectedUserIds.push(row.userId);
                            }
                        });

                        setValue('accessUserIds', selectedUserIds);
                    }}
                    getRowId={getRowId}
                />
            </div>
        </ModalRightPanel>
    );
}
