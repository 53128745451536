import { ModuleType } from '../ModuleContentTypes';

export const TICKET_LIST: ModuleType = {
    MODULE_ID: 8,
    MODULE_NAME: 'ticketList',
    CONTENTS: {
        INDEX: {
            CONTENT_ID: 1,
            CONTENT_NAME: 'index',
            COMPONENT_URL: 'contents/ticketList/TicketList',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.ticketList',
                TRANSLATOR_DEFAULT: 'Ticket list',
            },
        },
    },
};
