export const type: { light: 'light', dark: 'dark' } = {
    light: 'light',
    dark: 'dark',
};

export type Theme = 'light' | 'dark';

export type ThemeHandler = {
    theme: Theme;
    changeTheme: (themeToSet?: Theme) => Theme;
};
