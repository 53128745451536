/* eslint-disable object-shorthand */
/* eslint-disable func-names */
import { useEffect, useRef, useState } from 'react';
import { isEmpty, isFunction } from 'lodash';
import { useAlert } from 'theRising-baseComponents';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslate } from '@i18n';
import { useAuth } from '@screens/login/authProvider/AuthProvider';
import { sessionService, userService } from '@services';
import { useThemeHandler } from '@styles/theme';
import { GENERAL } from '@constants';

type UseUserProfileFormPropsType = {
    onClose: (isIgnoreOnBeforeClose?: boolean) => void,
    setIsHasUnsavedData: (state: boolean) => void,
};

export type UserFormDataToSaveType = {
    profilePicture: string | null | undefined,
    username: string,
    firstname: string,
    lastname: string,
    email: string,
    password: string,
    passwordAgain: string,
    isUseDarkTheme: boolean,
    //two factor auth
    isUseTwoFactorAuth: boolean,
    isValidQrCode: boolean,
    //egyéb személyes adatok
    phone: string | null,
    countryId: number | null,
    settlementId: number | null,
    address: string | null,
    jobPositionId: number | null,
};

export default function useUserProfileForm(props: UseUserProfileFormPropsType) {
    const { onClose, setIsHasUnsavedData } = props;
    const { user, updateUserData } = useAuth();
    const { translater } = useTranslate();
    const { errorAlert, successAlert } = useAlert();
    const { changeTheme } = useThemeHandler();
    const isInitializedFormRef = useRef(false);
    const isSubmittedFormRef = useRef(false);
    const [isLoadedForm, setIsLoadedForm] = useState<boolean>(true);

    const [passwordValidation] = useState(yup.string()
        .test({
            name: 'passwordSame',
            exclusive: false,
            message: translater('userProfile.twoPasswordNotMatch', 'The two passwords do not match!'),
            test: function () {
                const { password, passwordAgain } = this.parent;

                // Nem kell menteni, ha mindkettő üres
                if (!passwordAgain && !password) {
                    return true;
                }

                if (password !== passwordAgain) {
                    return false;
                }

                return true;
            },
        })
        .test({
            name: 'passwordForm',
            exclusive: false,
            message: translater('userProfile.invalidPassword', 'The password must be at least 8 characters long and include at least one number, one lowercase letter, and one uppercase letter.'),
            test: function () {
                const { password } = this.parent;

                // Nem kell menteni, ha mindkettő üres
                if (!password) {
                    return true;
                }

                const numberPattern = /[0-9]/;
                const uppercasePattern = /[A-Z]/;
                const passwordMinCharacter = GENERAL.PASSWORD_MIN_CHARACTER;

                if (password.length < passwordMinCharacter || !numberPattern.test(password) || !uppercasePattern.test(password)) {
                    return false;
                }

                return true;
            },
        }));

    const [FormSchema] = useState(yup.object().shape({
        profilePicture: yup.string(),
        username: yup
            .string()
            .required(translater('userProfile.usernameRequired', 'Username is a required field')),
        firstname: yup
            .string()
            .required(translater('userProfile.firstnameRequired', 'First name is a required field')),
        lastname: yup
            .string()
            .required(translater('userProfile.lastnameRequired', 'Last name is a required field')),
        email: yup
            .string()
            .required(translater('userProfile.emailRequired', 'Email is a required field')),
        password: yup.string(),
        passwordAgain: yup.string(),
        passwords: passwordValidation,
        isUseDarkTheme: yup.boolean(),
        //two factor auth
        isUseTwoFactorAuth: yup.boolean(),
        isValidQrCode: yup
            .boolean()
            .test({
                name: 'passwordSame',
                exclusive: false,
                message: translater('userProfile.incorrectQRCode', 'The code provided for the QR code is incorrect'),
                test: function (value) {
                    const { isUseTwoFactorAuth } = this.parent;

                    if (!isUseTwoFactorAuth) {
                        return true;
                    }

                    return value;
                },
            }),
        //egyéb személyes információk
        phone: yup.string().optional(),
        countryId: yup.number().optional(),
        settlementId: yup.number().optional(),
        address: yup.string().optional(),
        jobPositionId: yup.number().optional(),
    }));

    const { trigger, handleSubmit, setValue, getValues, watch, formState: { errors, dirtyFields } } = useForm({
        //mode: 'onChange',
        defaultValues: {
            profilePicture: user?.profilePicture,
            username: user?.username,
            firstname: user?.firstname,
            lastname: user?.lastname,
            email: user?.email,
            password: '',
            passwordAgain: '',
            isUseDarkTheme: user?.isUseDarkTheme,
            //two factor auth
            isUseTwoFactorAuth: user?.isUseTwoFactorAuth,
            isValidQrCode: user?.isUseTwoFactorAuth ? true : undefined,
            //egyéb személyes információk
            phone: user?.phone || undefined,
            countryId: user?.countryId || undefined,
            settlementId: user?.settlementId || undefined,
            address: user?.address || undefined,
            jobPositionId: user?.jobPositionId || undefined,
        },
        resolver: yupResolver(FormSchema),
    });

    //modal bezárásához beállítjuk a kérdést, hogy biztosan bezárjuk-e, vannak nem mentett adatok
    useEffect(() => {
        if (isInitializedFormRef.current) {
            if (!isEmpty(dirtyFields)) {
                setIsHasUnsavedData(true);
            } else {
                setIsHasUnsavedData(false);
            }
        }
        isInitializedFormRef.current = true;
    }, [dirtyFields, setIsHasUnsavedData]);

    //theme reset modal bezárásakor
    useEffect(() => () => {
        if (!isSubmittedFormRef.current && getValues('isUseDarkTheme') !== user?.isUseDarkTheme) {
            if (user?.isUseDarkTheme) {
                changeTheme('dark');
            } else {
                changeTheme('light');
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getValues, user?.isUseDarkTheme]);

    //mentés
    const onSubmit = async (formData: any) => {
        try {
            setIsLoadedForm(false);

            const saveResult = await userService.setUserData(formData as UserFormDataToSaveType);

            //sikeres mentés
            if (saveResult?.isSuccess) {
                if (formData.isUseDarkTheme) {
                    sessionService.setTheme('dark');
                } else {
                    sessionService.setTheme('light');
                }

                updateUserData(formData);

                successAlert(translater('default.successfulSaving', 'Successful saving'));
                isSubmittedFormRef.current = true;

                if (isFunction(onClose)) {
                    onClose(true);
                }
                return;
            }

            //szervertől jön hibaüzenet
            if (saveResult.errorMessage && !isEmpty(saveResult.errorMessage)) {
                errorAlert(saveResult.errorMessage);
                return;
            }

            errorAlert(translater('default.unsuccessfulOperation', 'Unsuccessful operation'));
        } catch (error) {
            errorAlert(translater('default.unsuccessfulOperation', 'Unsuccessful operation'));
        } finally {
            setIsLoadedForm(true);
        }
    };

    const setFormValue = (key, value) => {
        setValue(key, value, { shouldDirty: true });

        //csak akkor kell trigger, ha történt módosulás
        if (dirtyFields[key] || !isEmpty(errors[key]?.message)) {
            trigger([key]);
        }
    };

    return {
        trigger,
        onSubmit,
        handleSubmit,
        setValue,
        setFormValue,
        getValues,
        watch,
        errors,
        isLoadedForm,
        changeTheme,
    };
}
