export const HTTP_STATUS = {
    CODE_GROUP: {
        INFORMATION: 100,
        SUCCESSFUL: 200,
        REDIRECTION: 300,
        CLIENT_ERROR: 400,
        SERVER_ERROR: 500,
    },
    CODE: {
        OK: 200,
        BAD_REQUEST: 400,
        UNAUTHORIZED: 401,
        FORBIDDEN: 403,
        NOT_FOUND: 404,
        INTERNAL_SERVER_ERROR: 500,
        SERVICE_UNAVAILABLE: 503,
        GATEWAY_TIMEOUT: 504,
    },
};
