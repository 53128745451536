import { isString } from 'lodash';
import { HTTP_STATUS } from '@constants';
import { routerHelper } from '@modules';
import { sessionService } from './sessionService';

export const SERVER_ERROR_SERVICE_UNAVAILABLE = 2;

type MethodType = 'POST' | 'GET' | 'DELETE' | 'PATCH';

function handleServiceError(error: any, statusCode: number, path: string): any {
    return {
        message: error?.message || (isString(error) ? error : 'No response from the node server.'),
        url: error?.url || path,
        statusCode,
        isServerError: true,
    };
}

function getResponseJson(response: any) {
    try {
        return response.json().catch(() => ({}));
    } catch (error) {
        return {};
    }
}

export async function sendServerRequest(method: MethodType, path: string, body?: any): Promise<any> {
    let response: any = null;

    try {
        const bearerAuthToken = sessionService.getAuthToken();

        const options: any = {
            method,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${bearerAuthToken}`,
                lng: sessionService.getLangCode(),
            },
            timeout: 10000,
            body: JSON.stringify(body),
        };

        response = await fetch(path, options);

        if (bearerAuthToken && response.status === HTTP_STATUS.CODE.UNAUTHORIZED) {
            sessionService.removeAuthToken();
        }

        if (!response.ok || (response.status !== HTTP_STATUS.CODE.OK && response.status !== HTTP_STATUS.CODE.UNAUTHORIZED)) {
            throw handleServiceError(response, response.status, path);
        }

        const responseJson = getResponseJson(response);
        return responseJson;
    } catch (error) {
        const responseStatus = response ? response?.status : HTTP_STATUS.CODE.GATEWAY_TIMEOUT;
        throw handleServiceError(error, responseStatus, path);
    }
}

export async function sendServerRequestWithToken(method: MethodType, path: string, authToken: string, body?: any): Promise<any> {
    let response: any = null;

    try {
        const options: any = {
            method,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authToken}`,
                lng: sessionService.getLangCode(),
            },
            timeout: 10000,
            body: JSON.stringify(body),
        };

        response = await fetch(path, options);

        if (!response.ok || (response.status !== HTTP_STATUS.CODE.OK && response.status !== HTTP_STATUS.CODE.UNAUTHORIZED)) {
            throw handleServiceError(response, response.status, path);
        }

        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        const responseStatus = response ? response?.status : HTTP_STATUS.CODE.GATEWAY_TIMEOUT;
        throw handleServiceError(error, responseStatus, path);
    }
}

export { routerHelper };
