import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { isEqual, isFunction } from 'lodash';
import { useTranslate } from '@i18n';
import { SearchBar, Svg } from 'theRising-baseComponents';
import SpaceMenuItem from '../spaceMenuItem/SpaceMenuItem';
import styles from './spaceMenu.module.scss';
import stylesMenuItem from '../spaceMenuItem/spaceMenuItem.module.scss';
// eslint-disable-next-line import/no-cycle
import { filterTreeByName, filterTreeWithoutChildren, findSpaceById, rootItemId } from '../SpaceSelector';

type SpaceMenuPropsType = {
    items?: any,
    selectedId?: number,
    onChange?: (space: any) => void,
};

export default function SpaceMenu(props: SpaceMenuPropsType): React.ReactElement {
    const { items, selectedId, onChange } = props;
    const { translater } = useTranslate();
    const [selectedSpaceId, setSelectedSpaceId] = useState<number>(selectedId || rootItemId);
    const [spaces, setSpaces] = useState(items?.[0]?.children?.map((menuItem: any) => filterTreeWithoutChildren(menuItem))); //default lista
    const [filteredSpaces, setFilteredSpaces] = useState(spaces); //ezek jelennek meg
    const itemsCacheRef = useRef(spaces);

    useEffect(() => {
        if (!isEqual(items, itemsCacheRef.current)) {
            const newSpaces = items?.[0]?.children?.map((menuItem: any) => filterTreeWithoutChildren(menuItem));
            setSpaces(newSpaces);
            setFilteredSpaces(newSpaces);
        }
    }, [items]);

    //ha a kiválasztott space megváltozik
    const selectedIdCacheRef = useRef(selectedId);
    useEffect(() => {
        if (selectedId && selectedId !== selectedIdCacheRef.current) {
            selectedIdCacheRef.current = selectedId;
            setSelectedSpaceId(selectedId);
        }
    }, [selectedId]);

    //ha kiválasztunk egy space-t
    const onClickSpace = (spaceId: number) => {
        setSelectedSpaceId(spaceId);

        if (isFunction(onChange)) {
            if (spaceId === rootItemId) {
                onChange({
                    id: rootItemId,
                    name: translater('spaceSelector.root', 'Root'),
                });
                return;
            }

            const space = findSpaceById(items?.[0], spaceId);
            onChange(space);
        }
    };

    const onChangeSearchValue = (searchValue: string | null) => {
        const spaceList: Array<any> = [];
        spaces?.forEach((menuItem: any) => {
            const newSpaces = filterTreeByName(menuItem, searchValue);
            if (newSpaces) {
                spaceList.push(newSpaces);
            }
        });

        setFilteredSpaces(spaceList);
    };

    return (
        <div className={styles.wrapper} onClick={event => event.stopPropagation()} role="button" tabIndex={0}>
            <SearchBar className={styles.searchBar} onChange={onChangeSearchValue} />

            <div className={styles.rootItemSeparator} />

            <button
                onClick={() => onClickSpace(rootItemId)}
                type="button"
                className={clsx(
                    stylesMenuItem.menuItem,
                    selectedSpaceId === rootItemId && stylesMenuItem.menuItemSelected
                )}
                title={translater('spaceSelector.root', 'Root')}
            >
                {/* Menu item pre icon */}
                <Svg iconId={selectedSpaceId === rootItemId ? 'icon-folder-open' : 'icon-folder'} className={stylesMenuItem.menuItemIcon} />

                {/* Menu name */}
                <span
                    className={clsx(
                        stylesMenuItem.menuItemText,
                        stylesMenuItem.overflowDots,
                        selectedSpaceId === rootItemId && stylesMenuItem.menuItemTextOpened
                    )}
                >
                    {translater('spaceSelector.root', 'Root')}
                </span>
            </button>

            <div className={styles.rootItemSeparator} />

            <div className={styles.spaceListWrapper}>
                {filteredSpaces?.map((menuItem: any, index: number) => (
                    <SpaceMenuItem
                        key={index}
                        menuItem={menuItem}
                        onClick={onClickSpace}
                        selectedSpaceId={selectedSpaceId}
                    />
                ))}
            </div>
        </div>
    );
}
