import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { isArray, isEmpty, isFunction } from 'lodash';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslate } from '@i18n';
import { spaceService, userService } from '@services';
import { useAlert } from 'theRising-baseComponents';
import { SpaceType as DefaultSpaceType } from 'src/types/Space.types';
import { useAuth } from '@screens/login/authProvider/AuthProvider';

export type SpaceType = {
    exceptSpaceIds?: Array<number>,
    id: number | null | undefined,
    name: string | null | undefined,
    description: string | null | undefined,
    parentSpaceId: number,
    isRestrictedAccess: boolean,
    accessUserIds: Array<number>,
    ownerUserId: number,
    ownerFullname: string | null,
    ownerProfilePics: string | null,
};

export type SpaceFormDataToSaveType = {
    id: number | null | undefined,
    name: string,
    description: string | null,
    parentSpaceId: number,
    isRestrictedAccess: boolean,
    ownerUserId: number,
    newAccessUserIds?: Array<number>,
    deleteAccessUserIds?: Array<number>,
};

type UseSpaceModalFormPropsType = {
    id: number,
    parentSpace: DefaultSpaceType | undefined,
    onClose: (isIgnoreOnBeforeClose?: boolean) => void,
    setIsHasUnsavedData: (state: boolean) => void,
};

export default function useSpaceEditModal(props: UseSpaceModalFormPropsType) {
    const { id, parentSpace, onClose, setIsHasUnsavedData } = props;
    const [isLoadedForm, setIsLoadedForm] = useState<boolean>(true);
    const [isLoadedRightSide, setIsLoadedRightSide] = useState<boolean>(true);
    const [usersWithJobPositionList, setUersWithJobPositionList] = useState<Array<any>>([]);
    const isInitializedFormRef = useRef(false);
    const { errorAlert, successAlert } = useAlert();
    const { translater } = useTranslate();
    const { user } = useAuth();

    const [FormSchema] = useState(yup.object().shape({
        id: yup.number().nullable(),
        name: yup
            .string()
            .required(translater('spaceEditModal.spaceNameRequired', 'Space name is a required field')),
        description: yup
            .string()
            .optional()
            .nullable(),
        parentSpaceId: yup
            .number()
            .required(translater('spaceEditModal.parentSpaceRequired', 'Parent space is a required field')),
        isRestrictedAccess: yup
            .boolean()
            .optional(),
        accessUserIds: yup.array(),
        ownerUserId: yup.number(),
        ownerFullname: yup.string().nullable(),
        ownerProfilePics: yup.string().nullable(),
    }));

    const [spaceData, setSpaceData] = useState<SpaceType>({
        exceptSpaceIds: undefined,
        id: null,
        name: null,
        description: null,
        parentSpaceId: parentSpace?.id || 1,
        isRestrictedAccess: false,
        accessUserIds: [],
        ownerUserId: !id ? (user?.id || 0) : 0,
        ownerFullname: !id ? (user?.fullname || null) : null,
        ownerProfilePics: !id ? (user?.profilePicture || null) : null,
    });

    const { watch, reset, trigger, handleSubmit, setValue, getValues, formState } = useForm({
        defaultValues: {
            id: spaceData?.id,
            name: spaceData?.name || '',
            description: spaceData?.description || null,
            parentSpaceId: spaceData?.parentSpaceId || 1,
            isRestrictedAccess: spaceData?.isRestrictedAccess || false,
            accessUserIds: spaceData?.accessUserIds || [],
            ownerUserId: spaceData?.ownerUserId,
            ownerFullname: spaceData?.ownerFullname,
            ownerProfilePics: spaceData?.ownerProfilePics,
        },
        resolver: yupResolver(FormSchema),
    });
    const { errors, dirtyFields } = formState;

    //modal bezárásához beállítjuk a kérdést, hogy biztosan bezárjuk-e, vannak nem mentett adatok
    useEffect(() => {
        if (isInitializedFormRef.current) {
            if (!isEmpty(formState.dirtyFields)) {
                setIsHasUnsavedData(true);
            } else {
                setIsHasUnsavedData(false);
            }
        }
        isInitializedFormRef.current = true;
    }, [formState, setIsHasUnsavedData]);

    //adatok betöltése
    useLayoutEffect(() => {
        let isCancelled = false;

        try {
            (async () => {
                if (!id) {
                    return;
                }

                setIsLoadedForm(false);

                const resultData = await spaceService.getSpaceById({ id });

                if (!isCancelled && resultData) {
                    const formattedDatas = {
                        exceptSpaceIds: resultData?.id ? [resultData?.id] : undefined,
                        id: resultData?.id,
                        name: resultData?.name || '',
                        description: resultData?.description || null,
                        parentSpaceId: resultData?.parentSpaceId || 1,
                        isRestrictedAccess: resultData?.isRestrictedAccess || false,
                        accessUserIds: resultData?.accessUserIds || [],
                        ownerUserId: resultData?.ownerUserId || 0,
                        ownerFullname: resultData?.ownerFullname || null,
                        ownerProfilePics: resultData?.ownerProfilePics || null,
                    };
                    setSpaceData(formattedDatas);
                    reset(formattedDatas);
                }
            })();
        } catch (error) {
            errorAlert(translater('default.unsuccessfulOperation', 'Unsuccessful operation'));
        } finally {
            setIsLoadedForm(true);
        }

        return () => {
            isCancelled = true;
        };
    }, [errorAlert, id, reset, setValue, translater, user?.id]);

    //user lista beosztásokkal betöltése
    useLayoutEffect(() => {
        let isCancelled = false;

        try {
            (async () => {
                setIsLoadedRightSide(false);

                const resultData = await userService.getUsersWithJobPosition();

                if (!isCancelled && resultData) {
                    setUersWithJobPositionList(resultData);
                }
            })();
        } catch (error) {
            errorAlert(translater('default.unsuccessfulOperation', 'Unsuccessful operation'));
        } finally {
            setIsLoadedRightSide(true);
        }

        return () => {
            isCancelled = true;
        };
    }, [errorAlert, translater]);

    //dirtyFields változás figyelése
    const setFormValue = (key: any, value: any) => {
        setValue(key, value, { shouldDirty: true });

        //csak akkor kell trigger, ha történt módosulás
        if (dirtyFields[key] || !isEmpty(errors[key]?.message)) {
            trigger([key]);
        }
    };

    //mentés
    const onSubmit = async (formData: any) => {
        try {
            setIsLoadedForm(false);

            let newAccessUserIds: Array<number> = [];
            let deleteAccessUserIds: Array<number> = [];

            if (isArray(spaceData?.accessUserIds) && isArray(formData?.accessUserIds)) {
                deleteAccessUserIds = spaceData?.accessUserIds?.filter(userId => !formData?.accessUserIds.includes(+userId)); //ezeket a kapcsolatokat törölni kell
                newAccessUserIds = formData?.accessUserIds?.filter(userId => !spaceData?.accessUserIds.includes(+userId)); //ezek az új kapcsolatok amiket menteni kell
            }

            const saveResult = await spaceService.setSpace({
                id: formData.id,
                name: formData.name,
                description: formData.description,
                parentSpaceId: formData.parentSpaceId,
                isRestrictedAccess: formData.isRestrictedAccess,
                ownerUserId: formData.ownerUserId,
                newAccessUserIds,
                deleteAccessUserIds,
            } as SpaceFormDataToSaveType);

            //sikeres mentés
            if (saveResult) {
                successAlert(translater('default.successfulSaving', 'Successful saving'));

                //modal bezárása
                if (isFunction(onClose)) {
                    onClose(true);
                }
                return;
            }

            errorAlert(translater('default.unsuccessfulOperation', 'Unsuccessful operation'));
        } catch (error) {
            errorAlert(translater('default.unsuccessfulOperation', 'Unsuccessful operation'));
        } finally {
            setIsLoadedForm(true);
        }
    };

    return {
        onSubmit,
        handleSubmit,
        setFormValue,
        setValue,
        getValues,
        watch,
        errors,
        isLoadedForm,
        isLoadedRightSide,
        usersWithJobPositionList,
        spaceData,
    };
}
