/* eslint-disable import/no-cycle */
import { useLayoutEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useForm } from 'react-hook-form';
import { useAlert } from 'theRising-baseComponents';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslate } from '@i18n';
import { useAuth } from '@screens/login/authProvider/AuthProvider';
import { systemSettingsService } from '@services';
import { LogHandler } from '@modules';
import { DEFAULT_SPACE_LEVEL, MAX_SPACE_LEVEL, MIN_SPACE_LEVEL } from './DefaultSettings';

export type SystemSettingsFormDataToSaveType = {
    isNonAccentSearch: boolean,
    isUsePeriodicPassword: boolean,
    periodicPasswordInterval: number,
    companyName: string,
    maxSpaceLevel: number,
};

export default function useDefaultSettingsForm() {
    const { systemSettings: defaultSystemSettings, updateSystemSettings } = useAuth();
    const { translater } = useTranslate();
    const { errorAlert, successAlert } = useAlert();
    const [isLoadedForm, setIsLoadedForm] = useState<boolean>(true);

    //validation
    const [FormSchema] = useState(yup.object().shape({
        isNonAccentSearch: yup.boolean(),
        isUsePeriodicPassword: yup.boolean(),
        periodicPasswordInterval: yup.number(),
        companyName: yup
            .string()
            .required(translater('defaultSettings.companyNameRequired', 'Company name is a required field')),
        maxSpaceLevel: yup
            .number()
            .min(MIN_SPACE_LEVEL, translater('defaultSettings.maxSpaceLevelMin', 'Side menu max space level must be at least {min}', { min: MIN_SPACE_LEVEL }))
            .max(
                MAX_SPACE_LEVEL,
                translater('defaultSettings.maxSpaceLevelMax', 'Side menu max space level must be at most {max}', { max: MAX_SPACE_LEVEL })
            )
            .required(translater('defaultSettings.maxSpaceLevelRequired', 'Side menu max space level is a required field'))
            .typeError(translater('defaultSettings.maxSpaceLevelRequired', 'Side menu max space level is a required field')),
    }));

    //hook-form context
    const { trigger, handleSubmit, setValue, getValues, watch, formState: { errors, dirtyFields }, reset } = useForm({
        defaultValues: {
            ...defaultSystemSettings,
            companyName: defaultSystemSettings?.companyName || '',
            maxSpaceLevel: defaultSystemSettings?.maxSpaceLevel || DEFAULT_SPACE_LEVEL,
        },
        resolver: yupResolver(FormSchema),
    });

    //rendszer beállítások betöltése
    useLayoutEffect(() => {
        let isCancelled = false;

        async function loadData() {
            try {
                const systemSettingsData = await systemSettingsService.getSystemSettings();

                if (!isCancelled && systemSettingsData && !isEmpty(systemSettingsData)) {
                    reset(systemSettingsData);
                }
            } catch (error) {
                errorAlert(translater('default.unsuccessfulOperation', 'Unsuccessful operation'));
            } finally {
                setIsLoadedForm(true);
            }
        }

        setIsLoadedForm(false);
        loadData();

        return () => {
            isCancelled = true;
        };
    }, [errorAlert, reset, translater]);

    //mentés
    const onSubmit = async (formData: any) => {
        try {
            setIsLoadedForm(false);

            const isSuccessSave = await systemSettingsService.setSystemSettings(formData as SystemSettingsFormDataToSaveType);

            //sikeres mentés
            if (isSuccessSave) {
                updateSystemSettings(formData);

                successAlert(translater('default.successfulSaving', 'Successful saving'));
                return;
            }

            errorAlert(translater('default.unsuccessfulOperation', 'Unsuccessful operation'));
        } catch (error) {
            LogHandler.Error('Operation error: ', error);
            errorAlert(translater('default.unsuccessfulOperation', 'Unsuccessful operation'));
        } finally {
            setIsLoadedForm(true);
        }
    };

    const setFormValue = (key, value) => {
        setValue(key, value, { shouldDirty: true });

        //csak akkor kell trigger, ha történt módosulás
        if (dirtyFields[key] || !isEmpty(errors[key]?.message)) {
            trigger([key]);
        }
    };

    const onSubmitForm = handleSubmit(onSubmit);

    return {
        trigger,
        onSubmitForm,
        setValue,
        setFormValue,
        getValues,
        watch,
        errors,
        isLoadedForm,
    };
}
