import clsx from 'clsx';
import { Button } from 'theRising-baseComponents';
import { useTranslate } from '@i18n';
import styles from './backButton.module.scss';

type BackButtonPropsType = {
    onClick: () => void,
    title?: string,
    className?: string,
};

export default function BackButton(props: BackButtonPropsType): React.ReactElement {
    const { onClick, className, title } = props;
    const { translater } = useTranslate();

    return (
        <Button
            type="secondary"
            iconId="icon-arrow_back"
            className={clsx(styles.backButton, className)}
            title={title || translater('default.back', 'Back')}
            onClick={onClick}
        />
    );
}
