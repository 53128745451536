import { LoggedInUserType, UserInterfaceSetting } from '@screens/login/authProvider/AuthProvider';
import { UserFormDataToSaveType } from '@screens/mainLayout/header/userProfileModal/useUserProfileForm';
import { sendServerRequest, sendServerRequestWithToken, routerHelper } from '../baseService';

// ---------  login ------------

type LoginPropsType = {
    username: string,
    password: string,
};
type LoginResponeType = {
    access_token: {
        bearerToken: string,
        isUseTwoFactorAuth: boolean,
    },
    token_type: string,
};
/**
 * Authentikációs végpont
 */
async function login(props: LoginPropsType): Promise<LoginResponeType> {
    const response = await sendServerRequest('POST', '/api/oauth2/token', props);
    return response as LoginResponeType;
}

// ---------  getTwoFactorQRCode ------------

type GetTwoFactorQRCodeResponseType = {
    imgSrc: string,
};
/**
 * QR kódot tartalmazó image src-t kérhetjük le
 */
async function getTwoFactorQRCode(): Promise<GetTwoFactorQRCodeResponseType> {
    const response = await sendServerRequest('GET', '/api/user/getTwoFactorQRCode');
    return response as GetTwoFactorQRCodeResponseType;
}

// ---------  getIsValidTwoFactorCode ------------

type GetIsValidTwoFactorCodePropsType = {
    userToken: string,
};
/**
 * 2 lépcsős azonosító kód ellenőrzése
 */
async function getIsValidTwoFactorCode(props: GetIsValidTwoFactorCodePropsType, authToken: string): Promise<boolean> {
    const url = routerHelper.getUrlWithParameters('/api/user/getIsValidTwoFactorCode', props);
    const response = await sendServerRequestWithToken('GET', url, authToken);
    return response as boolean;
}

// ---------  setResetPassword ------------

type SetResetPasswordPropsType = {
    email: string,
};
type SetResetPasswordResponeType = {
    isSuccess: boolean,
    errorMessage?: string,
};
/**
 * Elfelejtett jelszó - reset link
 */
async function setResetPassword(props: SetResetPasswordPropsType): Promise<SetResetPasswordResponeType> {
    const response = await sendServerRequest('POST', '/api/user/setResetPassword', props);
    return response as SetResetPasswordResponeType;
}

// ---------  getNewQrCode ------------

/**
 * Új QR kód küldése emailben
 */
async function getNewQrCode(authToken: string): Promise<boolean> {
    const response = await sendServerRequestWithToken('GET', '/api/user/getNewQrCode', authToken);
    return response as boolean;
}

// ---------  setNewPassword ------------

type SetNewPasswordPropsType = {
    isPeriodicChange?: boolean,
    resetToken?: string,
    password: string,
};
/**
 * Új jelszó beállítása (elfelejtett jelszó)
 */
async function setNewPassword(props: SetNewPasswordPropsType): Promise<boolean> {
    const response = await sendServerRequest('POST', '/api/user/setNewPassword', props);
    return response as boolean;
}

/**
 * Új jelszó beállítása (időszakos jelszó)
 */
async function setPeriodicPassword(props: SetNewPasswordPropsType): Promise<boolean> {
    const response = await sendServerRequest('POST', '/api/user/setPeriodicPassword', props);
    return response as boolean;
}

// ---------  getLoggedInUserDatas ------------

/**
 * Belépett felhasználóhoz tartozó user adatok lekérdezése
 */
async function getLoggedInUserDatas(): Promise<LoggedInUserType> {
    const response = await sendServerRequest('GET', '/api/user/getLoggedInUserDatas');
    return response as LoggedInUserType;
}

// ---------  setUserData ------------

type SetUserDataResponeType = {
    isSuccess: boolean,
    errorMessage?: string,
};

/**
 * Felhasználói adatok frissítése
 */
async function setUserData(props: UserFormDataToSaveType): Promise<SetUserDataResponeType> {
    const response = await sendServerRequest('POST', '/api/user/setUserData', props);
    return response as SetUserDataResponeType;
}

// ---------  setInterfaceSetting ------------

/**
 * Felhasználói adatok frissítése
 */
async function setInterfaceSetting(props: UserInterfaceSetting): Promise<boolean> {
    const response = await sendServerRequest('POST', '/api/user/setInterfaceSetting', props);
    return response as boolean;
}

// ---------  getUsersWithJobPosition ------------

/**
 * Lekéri a felhasználókat a hozzájuk tartozó beosztással
 */
async function getUsersWithJobPosition() {
    const response = await sendServerRequest('GET', '/api/user/getUsersWithJobPosition');
    return response;
}

export const userService = {
    login,
    getTwoFactorQRCode,
    getIsValidTwoFactorCode,
    setResetPassword,
    getNewQrCode,
    setNewPassword,
    setPeriodicPassword,
    getLoggedInUserDatas,
    setUserData,
    setInterfaceSetting,
    getUsersWithJobPosition,
};
