/* eslint-disable no-console */
import { isString, isEmpty, isObject, isUndefined } from 'lodash';
import translationHu from '@i18n/locales/hu.json';
import translationEN from '@i18n/locales/en.json';
import { LANGUAGE } from '@constants';

const projectName = 'TS-';

export default class LogHandler {
    static DevLog(message: string): void {
        if (process.env.NODE_ENV !== 'development') {
            return;
        }

        if (isString(message)) {
            console.info(`[${projectName}LOG]: ${message}`);
            return;
        }
        console.info(`[${projectName}LOG]: `, message);
    }

    static Log(message: string): void {
        if (isString(message)) {
            console.log(`[${projectName}LOG]: ${message}`);
            return;
        }
        console.log(`[${projectName}LOG]: `, message);
    }

    static Error(message: string, params?: any): void {
        if (isString(message)) {
            if (params) {
                console.error(`[${projectName}ERROR]: ${message}`, params);
                return;
            }

            console.error(`[${projectName}ERROR]: ${message}`);
            return;
        }

        console.error(`${projectName}[ERROR]: `, message);
    }

    static Warning(message: string): void {
        if (isString(message)) {
            console.warn(`[${projectName}WARNING]: ${message}`);
            return;
        }
        console.warn(`${projectName}[WARNING]: `, message);
    }

    // --------- Translater módon iratjuk ki ------------

    static replaceTextByIndex(caption: string, params: Array<string>) {
        return caption?.replace(/{(\d+)}/g, (match, index) => (!isUndefined(params[index]) ? params[index] : match));
    }

    static replaceTextByKey(caption: string, params: object) {
        return caption.replace(/{([^}]+)}/g, (match, key) => (!isUndefined(params[key]) ? params[key] : match));
    }

    static getCaption(moduleKey: string, defaultText: string): string {
        const parts = moduleKey?.split('.');
        const module = parts?.[0];
        const key = parts?.[1];

        if (isEmpty(module) || isEmpty(key)) {
            return defaultText;
        }

        let caption = null;

        if (!isEmpty(process.env?.DEV_LANG) && process.env?.DEV_LANG?.toLowerCase() !== LANGUAGE.CODE.HUNGARIAN.toLowerCase()) {
            caption = translationEN?.[module]?.[key];
        } else {
            caption = translationHu?.[module]?.[key];
        }

        //default fallback
        if (!caption) {
            caption = translationEN?.[module]?.[key];
        }

        if (!caption || isEmpty(caption)) {
            return defaultText;
        }

        return caption;
    }

    static LogTrans(moduleKey: string, defaultText: string, params?: Array<string> | object | null, objectParam?: any): void {
        let caption = this.getCaption(moduleKey, defaultText);

        if (params && !isEmpty(params) && !isObject(params)) {
            caption = this.replaceTextByIndex(caption, params as Array<string>);
        } else if (params && !isEmpty(params) && isObject(params)) {
            caption = this.replaceTextByKey(caption, params as object);
        }

        if (objectParam) {
            console.log(`[${projectName}LOG]${caption}`, objectParam);
        } else {
            console.log(`[${projectName}LOG]${caption}`);
        }
    }

    static ErrorTrans(moduleKey: string, defaultText: string, params?: Array<string> | object | null, objectParam?: any): void {
        let caption = this.getCaption(moduleKey, defaultText);

        if (params && !isEmpty(params) && !isObject(params)) {
            caption = this.replaceTextByIndex(caption, params as Array<string>);
        } else if (params && !isEmpty(params) && isObject(params)) {
            caption = this.replaceTextByKey(caption, params as object);
        }

        if (objectParam) {
            console.error(`[${projectName}ERROR]${caption}`, objectParam);
        } else {
            console.error(`[${projectName}ERROR]${caption}`);
        }
    }

    static WarningTrans(moduleKey: string, defaultText: string, params?: Array<string> | object, objectParam?: any): void {
        let caption = this.getCaption(moduleKey, defaultText);

        if (params && !isEmpty(params) && !isObject(params)) {
            caption = this.replaceTextByIndex(caption, params as Array<string>);
        } else if (params && !isEmpty(params) && isObject(params)) {
            caption = this.replaceTextByKey(caption, params as object);
        }

        if (objectParam) {
            console.warn(`[${projectName}WARNING]${caption}`, objectParam);
        } else {
            console.warn(`[${projectName}WARNING]${caption}`);
        }
    }
}
