/* eslint-disable import/no-cycle */
import clsx from 'clsx';
import { isEmpty, isNumber, isUndefined } from 'lodash';
import { Button, Checkbox, FormGroup, Input, LoadingSpinner, Radio } from 'theRising-baseComponents';
import { useTranslate } from '@i18n';
import styles from './defaultSettings.module.scss';
import useDefaultSettingsForm from './useDefaultSettingsForm';

const EVERY_MONTH = 1;
const EVERY_THREE_MONTHS = 3;

export const MIN_SPACE_LEVEL = 1;
export const DEFAULT_SPACE_LEVEL = 2;
export const MAX_SPACE_LEVEL = 3;

export default function DefaultSettings(): React.ReactElement {
    const { translater } = useTranslate();
    const {
        onSubmitForm,
        setValue,
        setFormValue,
        getValues,
        isLoadedForm,
        errors,
    } = useDefaultSettingsForm();

    return (
        <div className={styles.defaultSettings}>
            <form onSubmit={onSubmitForm} className={styles.form}>
                <LoadingSpinner value={isLoadedForm} />

                <div className={styles.formHeader}>
                    <Button text={translater('default.save', 'Save')} className={styles.saveButton} isSubmitButton />
                </div>

                <div className={styles.formBody}>
                    <FormGroup
                        text={translater('defaultSettings.companyName', 'Company name')}
                        className={clsx(styles.formGroup, styles.inputWrapper)}
                        errorMessage={errors.companyName?.message}
                        isInvalid={isEmpty(getValues('companyName'))}
                        isRequired
                    >
                        <Input
                            placeholder={translater('defaultSettings.companyName', 'Company name')}
                            defaultText={getValues('companyName')}
                            onBlur={value => setFormValue('companyName', value)}
                        />
                    </FormGroup>
                    <FormGroup text={translater('defaultSettings.searching', 'Searching')} className={styles.formGroup}>
                        <Checkbox
                            isDefaultChecked={getValues('isNonAccentSearch')}
                            onChange={isChecked => {
                                setValue('isNonAccentSearch', isChecked, { shouldDirty: true });
                            }}
                            text={translater('defaultSettings.nonAccentSearch', 'Non accent search')}
                        />
                    </FormGroup>
                    <FormGroup text={translater('defaultSettings.periodicPassword', 'Periodic password')} className={clsx(styles.formGroup, styles.formGroupWithChild)}>
                        <Checkbox
                            isDefaultChecked={getValues('isUsePeriodicPassword')}
                            onChange={isChecked => {
                                setFormValue('isUsePeriodicPassword', isChecked);
                            }}
                            text={translater('defaultSettings.usePeriodicPassword', 'Use of a periodic password')}
                        />
                        <div className={styles.radioGroup} style={{ display: getValues('isUsePeriodicPassword') ? '' : 'none' }}>
                            <Radio
                                group="periodicPasswordInterval"
                                value={1}
                                isDefaultChecked={getValues('periodicPasswordInterval') === EVERY_MONTH || isUndefined(getValues('periodicPasswordInterval'))}
                                text={translater('defaultSettings.periodicPasswordEveryMonth', 'Every month')}
                                onChange={() => setValue('periodicPasswordInterval', EVERY_MONTH)}
                            />
                            <Radio
                                group="periodicPasswordInterval"
                                value={3}
                                isDefaultChecked={getValues('periodicPasswordInterval') === EVERY_THREE_MONTHS}
                                text={translater('defaultSettings.periodicPasswordEveryThreeMonths', 'Every three months')}
                                onChange={() => setValue('periodicPasswordInterval', EVERY_THREE_MONTHS)}
                            />
                        </div>
                    </FormGroup>
                    <FormGroup
                        text={`${translater('defaultSettings.maxSpaceLevel', 'Side menu max space level')} (${MIN_SPACE_LEVEL} - ${MAX_SPACE_LEVEL})`}
                        className={clsx(styles.formGroup, styles.inputWrapper)}
                        errorMessage={errors.maxSpaceLevel?.message}
                        isInvalid={!isNumber(getValues('maxSpaceLevel'))}
                        isRequired
                    >
                        <Input
                            inputGroupClassName={styles.maxSpaceLevelInput}
                            defaultText={getValues('maxSpaceLevel')?.toString()}
                            onBlur={value => setFormValue('maxSpaceLevel', +value)}
                            type="number"
                            isPositiveOnly
                            isInteger
                            min={MIN_SPACE_LEVEL}
                            max={MAX_SPACE_LEVEL}
                        />
                    </FormGroup>
                </div>
            </form>
        </div>
    );
}
