import { isEmpty, isNumber } from 'lodash';
import { useTranslate } from '@i18n';
import { Button,
    FormGroup,
    Input,
    LoadingSpinner,
    ModalLeftPanel,
    ModalLeftPanelBody,
    ModalLeftPanelFooter,
    ModalLeftPanelHeader,
    ModalWrapper,
    TextBox,
    ToggleSwitch,
    WorkerAvatar } from 'theRising-baseComponents';
import SpaceSelector from '@customComponents/spaceSelector/SpaceSelector';
import { SpaceType } from 'src/types/Space.types';
import styles from './spaceEditModal.module.scss';
import ModalRightSide from './modalRightSide/ModalRightSide';
import useSpaceEditModal from './useSpaceEditModal';

type SpaceEditModalPropsType = {
    onClose: (isIgnoreOnBeforeClose?: boolean) => void,
    setIsHasUnsavedData: (state: boolean) => void,
    id: number,
    name: string,
    spaces: Array<SpaceType>,
    parentSpace?: SpaceType,
};

const TABLE_KEY = 'spaceEditModal';

export default function SpaceEditModal(props: SpaceEditModalPropsType): React.ReactElement {
    const { onClose, setIsHasUnsavedData } = props;
    const { id, name, spaces, parentSpace } = props;
    const { translater } = useTranslate();

    const {
        onSubmit,
        handleSubmit,
        setFormValue,
        setValue,
        getValues,
        watch,
        errors,
        isLoadedForm,
        isLoadedRightSide,
        usersWithJobPositionList,
        spaceData,
    } = useSpaceEditModal({ onClose, setIsHasUnsavedData, id, parentSpace });

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <ModalWrapper>
                <LoadingSpinner value={isLoadedForm} />
                <ModalLeftPanel>
                    <ModalLeftPanelHeader
                        title={id
                            ? translater('spaceEditModal.editSpace', 'Edit space')
                            : translater('spaceEditModal.createSpace', 'Create space')}
                        subTitle={name}
                    />
                    <ModalLeftPanelBody>
                        <FormGroup text={translater('spaceEditModal.spaceOwner', 'Space owner')}>
                            <WorkerAvatar
                                name={getValues('ownerFullname') || undefined}
                                imageUrl={getValues('ownerProfilePics') || undefined}
                                isShowName
                            />
                        </FormGroup>
                        <FormGroup
                            text={translater('spaceEditModal.parentSpace', 'Parent space')}
                            isInvalid={!isNumber(getValues('parentSpaceId'))}
                            errorMessage={errors.parentSpaceId?.message}
                            isRequired
                        >
                            <SpaceSelector
                                spaces={spaces}
                                defaultSelectedSpaceId={getValues('parentSpaceId')}
                                onChange={space => setFormValue('parentSpaceId', space.id)}
                                exceptSpaceIds={spaceData.exceptSpaceIds}
                            />
                        </FormGroup>
                        <FormGroup
                            text={translater('spaceEditModal.name', 'Name')}
                            isInvalid={isEmpty(getValues('name'))}
                            errorMessage={errors.name?.message}
                            isRequired
                        >
                            <Input
                                placeholder={translater('spaceEditModal.name', 'Name')}
                                defaultText={getValues('name')}
                                onBlur={value => setFormValue('name', value)}
                            />
                        </FormGroup>
                        <FormGroup text={translater('spaceEditModal.description', 'Description')}>
                            <TextBox
                                defaultValue={getValues('description') || undefined}
                                className={styles.descriptionTextBox}
                                onBlur={value => setFormValue('description', value)}
                            />
                        </FormGroup>
                        <FormGroup text={translater('spaceEditModal.restrictionOfAccess', 'Restriction of access')}>
                            <ToggleSwitch
                                isChecked={getValues('isRestrictedAccess')}
                                onChange={state => setFormValue('isRestrictedAccess', state)}
                            />
                        </FormGroup>
                    </ModalLeftPanelBody>
                    <ModalLeftPanelFooter>
                        <Button text={translater('default.save', 'Save')} isSubmitButton />
                    </ModalLeftPanelFooter>
                </ModalLeftPanel>
                {watch('isRestrictedAccess') && (
                    <ModalRightSide
                        dataList={usersWithJobPositionList}
                        tableKey={TABLE_KEY}
                        selectedRowIds={getValues('accessUserIds')}
                        setValue={setValue}
                        isLoadedRightSide={isLoadedRightSide}
                    />
                )}
            </ModalWrapper>
        </form>
    );
}
