import { memo, useLayoutEffect } from 'react';
import { sessionService } from '@services';
import { LANGUAGE } from '@constants';
import { dateTimeUtils } from '@utils';
import useTranslaterProvider from '@i18n/useTranslaterProvider';
import { Error503 } from '@screens/errors';

type TranslaterProps = {
    children: React.ReactNode,
};

function TranslaterProvider(props : TranslaterProps): React.ReactElement | null {
    const { children } = props;
    const { hasTranslaterError, i18n } = useTranslaterProvider();

    //set default lang
    useLayoutEffect(() => {
        if (i18n.isInitialized && hasTranslaterError === false) {
            let langCode = sessionService.getLangCode();
            if (langCode) {
                i18n.changeLanguage(langCode);
                dateTimeUtils.setMomentLocale(langCode.toLowerCase());
                return;
            }

            //default - böngésző nyelve
            langCode = i18n?.language || LANGUAGE.DEFAULT_LANGUAGE_CODE.toLowerCase();
            i18n.changeLanguage(langCode);
            dateTimeUtils.setMomentLocale(langCode);
        }
    }, [i18n, hasTranslaterError]);

    //ha hiba történik
    if (hasTranslaterError) {
        return (
            <Error503 />
        );
    }

    if (!i18n.isInitialized) {
        return null;
    }

    return (
        <>
            {children}
        </>
    );
}

export default memo(TranslaterProvider);
