import { routerHelper, sendServerRequest } from '../baseService';

// ---------  getCountries ------------

/**
 * Országok lekérdezése
 */
async function getCountries(): Promise<Array<any>> {
    const response = await sendServerRequest('GET', '/api/dataList/getCountries');
    return response as Array<any>;
}

// ---------  getSettlements ------------

type GetSettlementsPropsType = {
    countryId?: number,
};

/**
 * Települések lekérdezése
 */
async function getSettlements(props: GetSettlementsPropsType): Promise<Array<any>> {
    const url = routerHelper.getUrlWithParameters('/api/dataList/getSettlements', props);
    const response = await sendServerRequest('GET', url);
    return response as Array<any>;
}

// ---------  getJobPositions ------------

/**
 * Települések lekérdezése
 */
async function getJobPositions(): Promise<Array<any>> {
    const response = await sendServerRequest('GET', '/api/dataList/getJobPositions');
    return response as Array<any>;
}

export const dataListService = {
    getCountries,
    getSettlements,
    getJobPositions,
};
