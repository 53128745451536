import { useCallback } from 'react';
import { ConfirmDialogButtonType, renderConfirmDialog, renderModal, useAlert } from 'theRising-baseComponents';
import { useTranslate } from '@i18n';
import { spaceService } from '@services';
import { LogHandler } from '@modules';
import { SpaceType } from 'src/types/Space.types';
import SpaceEditModal from './spaceEditModal/SpaceEditModal';
import styles from './spaces.module.scss';
import TicketListEditModal from './ticketListEditModal/TicketListEditModal';

export default function useSpaceFunctions() {
    const { translater } = useTranslate();
    const { errorAlert, successAlert } = useAlert();

    // --------- Spaces ------------

    const onClickCreateSpace = useCallback((parentSpace?: SpaceType, spaces?: Array<SpaceType>) => {
        renderModal({
            component: SpaceEditModal,
            componentProps: {
                spaces,
                parentSpace,
            },
        });
    }, []);

    const onClickEditSpace = useCallback((spaceId: number, spaceName: string, parentSpace?: SpaceType) => {
        renderModal({
            component: SpaceEditModal,
            componentProps: {
                id: spaceId,
                name: spaceName,
                parentSpace,
            },
        });
    }, []);

    const onClickDeleteSpace = useCallback((spaceId: number) => {
        try {
            renderConfirmDialog({
                iconId: 'icon-bin',
                iconColor: styles.colorThemeDestructive,
                confirmButtonType: ConfirmDialogButtonType.yesCancel,
                questionText: translater('sideMenu.deleteSpace', 'Delete space'),
                questionDescription: translater('sideMenu.deleteSpaceMessage', 'Are you sure you want to delete the selected space?'),
                onConfirm: async () => {
                    const isSuccessDelete = await spaceService.deleteSpace({ id: spaceId });

                    if (isSuccessDelete) {
                        successAlert(translater('default.successfulOperation', 'Successful operation'));
                    } else {
                        errorAlert(translater('default.unsuccessfulOperation', 'Unsuccessful operation'));
                    }
                },
            });
        } catch (error: any) {
            LogHandler.Error(error);
            errorAlert(translater('default.unsuccessfulOperation', 'Unsuccessful operation'));
        }
    }, [errorAlert, successAlert, translater]);

    // --------- Ticket List ------------

    const onClickCreateTicketList = useCallback((parentSpaceId?: number) => {
        renderModal({
            component: TicketListEditModal,
            componentProps: {
                parentSpaceId,
            },
        });
    }, []);

    const onClickEditTicketList = useCallback((ticketListId: number, ticketListName: string, parentSpaceId?: number) => {
        renderModal({
            component: TicketListEditModal,
            componentProps: {
                id: ticketListId,
                name: ticketListName,
                parentSpaceId,
            },
        });
    }, []);

    const onClickDeleteTicketList = useCallback((ticketListId: number) => {
        try {
            renderConfirmDialog({
                iconId: 'icon-bin',
                iconColor: styles.colorThemeDestructive,
                confirmButtonType: ConfirmDialogButtonType.yesCancel,
                questionText: translater('sideMenu.deleteTicketList', 'Delete ticket list'),
                questionDescription: translater('sideMenu.deleteTicketListMessage', 'Are you sure you want to delete the selected ticket list?'),
                onConfirm: async () => {
                    const isSuccessDelete = await spaceService.deleteTicketList({ id: ticketListId });

                    if (isSuccessDelete) {
                        successAlert(translater('default.successfulOperation', 'Successful operation'));
                    } else {
                        errorAlert(translater('default.unsuccessfulOperation', 'Unsuccessful operation'));
                    }
                },
            });
        } catch (error: any) {
            LogHandler.Error(error);
            errorAlert(translater('default.unsuccessfulOperation', 'Unsuccessful operation'));
        }
    }, [errorAlert, successAlert, translater]);

    return {
        onClickEditSpace,
        onClickCreateSpace,
        onClickDeleteSpace,
        onClickCreateTicketList,
        onClickEditTicketList,
        onClickDeleteTicketList,
    };
}
