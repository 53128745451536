import { useTranslate } from '@i18n';
import { HTTP_STATUS } from '@constants';
import BaseError from '../baseError/BaseError';

export default function Error401(): React.ReactElement {
    const { translater } = useTranslate();

    return (
        <BaseError
            errorCode={HTTP_STATUS.CODE.UNAUTHORIZED}
            errorTitle={translater(
                'errorScreen.errorTitle401',
                'Access Denied, Houston!'
            )}
            errorMessage={translater(
                'errorScreen.errorMessage401',
                'We\'re sorry, but it seems you don\'t have the clearance for this area. Please contact mission control for assistance.'
            )}
        />
    );
}
