import { ModuleType } from '../ModuleContentTypes';

export const DASHBOARD: ModuleType = {
    MODULE_ID: 2,
    MODULE_NAME: 'dashboard',
    CONTENTS: {
        INDEX: {
            CONTENT_ID: 1,
            CONTENT_NAME: 'index',
            COMPONENT_URL: 'contents/dashboard/Dashboard',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.dashboards',
                TRANSLATOR_DEFAULT: 'Dashboards',
            },
        },
    },
};
