import { useCallback } from 'react';
import { isFunction } from 'lodash';
import clsx from 'clsx';
import { Svg } from 'theRising-baseComponents';
import styles from './otherFunctionMenuItem.module.scss';

type OtherFunctionMenuItemPropsType = {
    iconId: string,
    text: string,
    onClick: (event: any) => void,
    isDisabled?: boolean,
    isDestructive?: boolean,
    className?: string,
};

export default function OtherFunctionMenuItem(props: OtherFunctionMenuItemPropsType): React.ReactElement {
    const { iconId, text, onClick, isDisabled, className, isDestructive } = props;

    const onClickMenuItem = useCallback((event: any) => {
        event.stopPropagation();
        event.preventDefault();

        if (isFunction(onClick)) {
            onClick(event);
        }

        document.body.click();
    }, [onClick]);

    return (
        <button
            type="button"
            className={clsx(
                styles.otherFunctionItem,
                isDisabled && styles.disabled,
                isDestructive && styles.destructive,
                className
            )}
            title={text}
            onClick={onClickMenuItem}
            disabled={isDisabled}
        >
            <Svg iconId={iconId} className={styles.otherFunctionItemIcon} />
            <span className={styles.otherFunctionItemText}>
                {text}
            </span>
        </button>
    );
}
