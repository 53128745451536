import { ModuleType } from '../ModuleContentTypes';

export const LEAVE_MANAGEMENT: ModuleType = {
    MODULE_ID: 4,
    MODULE_NAME: 'leaveManagement',
    CONTENTS: {
        INDEX: {
            CONTENT_ID: 1,
            CONTENT_NAME: 'index',
            COMPONENT_URL: 'contents/leaveManagement/LeaveManagement',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.leaveManagement',
                TRANSLATOR_DEFAULT: 'Leave management',
            },
        },
    },
};
