import { SystemSettingsFormDataToSaveType } from '@contents/settings/defaultSettings/useDefaultSettingsForm';
import { RightGroupFormDataToSaveType, RightGroupType } from '@contents/settings/rights/rightEditModal/useRightEditModalForm';
import { CountryFormDataToSaveType, CountryType } from '@contents/settings/countries/countryEditModal/useCountryModalForm';
import { SettlementFormDataToSaveType, SettlementType } from '@contents/settings/settlements/settlementEditModal/useSettlementModal';
import { JobPositionFormDataToSaveType, JobPositionType } from '@contents/settings/jobPositions/jobPositionEditModal/useJobPositionModalForm';
import { UserFormDataToSaveType } from '@contents/settings/users/userEditModal/useUserModalForm';
import { SystemSettingsType } from '@screens/login/authProvider/AuthProvider';
import { routerHelper, sendServerRequest } from '../baseService';

// --------- SYSTEM SETTINGS --------------
// --------- getSystemSettings ------------

/**
 * Rendszerbeállítások lekérdezése
 */
async function getSystemSettings(): Promise<SystemSettingsType> {
    const response = await sendServerRequest('GET', '/api/settings/getSystemSettings');
    return response as SystemSettingsType;
}

// --------- setSystemSettings ------------

/**
 * Rendszerbeállítások mentése
 */
async function setSystemSettings(props: SystemSettingsFormDataToSaveType): Promise<boolean> {
    const response = await sendServerRequest('POST', '/api/settings/setSystemSettings', props);
    return response as boolean;
}

// --------- USERS ---------------
// --------- getUsers ------------

/**
 * Felhasználók betöltése
 */
async function getUsers(props: any): Promise<Array<any>> {
    const url = routerHelper.getUrlWithParameters('/api/settings/getUsers', props);
    const response = await sendServerRequest('GET', url);
    return response as Array<any>;
}

// --------- deleteUser ------------

type DeleteUserPropsType = {
    userId: number,
};

/**
 * Felhasználó törlése
 */
async function deleteUser(props: DeleteUserPropsType): Promise<boolean> {
    const response = await sendServerRequest('DELETE', '/api/settings/deleteUser', props);
    return response as boolean;
}

// --------- getUserById ------------

type GetUserByIdPropsType = {
    userId: number,
};

/**
 * Felhasználó adatainak lekérdezése id alapján
 */
async function getUserById(props: GetUserByIdPropsType): Promise<any> {
    const url = routerHelper.getUrlWithParameters('/api/settings/getUserById', props);
    const response = await sendServerRequest('GET', url);
    return response as Array<any>;
}

// --------- setUserData ------------

type SetUserDataResponeType = {
    isSuccess: boolean,
    errorMessage?: string,
};

/**
 * Felhasználó mentése
 */
async function setUserData(props: UserFormDataToSaveType): Promise<SetUserDataResponeType> {
    const response = await sendServerRequest('POST', '/api/settings/setUserData', props);
    return response as SetUserDataResponeType;
}

// --------- RIGHTS -------------------------
// --------- getRightsWithGroups ------------

type GetRightsWithGroupsPropsType = object;

/**
 * Jogosultságok lekérdezése csoportokba rendezve
 */
async function getRightsWithGroups(props: GetRightsWithGroupsPropsType): Promise<any> {
    const url = routerHelper.getUrlWithParameters('/api/settings/getRightsWithGroups', props);
    const response = await sendServerRequest('GET', url);
    return response as Array<any>;
}

// --------- getRightsWithGroups ------------

type GetRightGroupByIdPropsType = {
    groupId: number | null,
};

/**
 * Jog csoport lekérdezése id alapján
 */
async function getRightGroupById(props: GetRightGroupByIdPropsType): Promise<RightGroupType> {
    const url = routerHelper.getUrlWithParameters('/api/settings/getRightGroupById', props);
    const response = await sendServerRequest('GET', url);
    return response as RightGroupType;
}

// --------- setRightGroup ------------

/**
 * Jog csoport mentése
 */
async function setRightGroup(props: RightGroupFormDataToSaveType): Promise<boolean> {
    const response = await sendServerRequest('POST', '/api/settings/setRightGroup', props);
    return response as boolean;
}

// --------- COUNTRIES ---------------
// --------- getCountries ------------

/**
 * Országok betöltése
 */
async function getCountries(props: any): Promise<Array<any>> {
    const url = routerHelper.getUrlWithParameters('/api/settings/getCountries', props);
    const response = await sendServerRequest('GET', url);
    return response as Array<any>;
}

// --------- deleteCountry ------------

type DeleteCountryPropsType = {
    countryId: number,
};

/**
 * Országok törlése
 */
async function deleteCountry(props: DeleteCountryPropsType): Promise<boolean> {
    const response = await sendServerRequest('DELETE', '/api/settings/deleteCountry', props);
    return response as boolean;
}

// --------- getCountryById ------------

type GetCountryByIdPropsType = {
    id: number | null,
};

/**
 * Ország lekérdezése id alapján
 */
async function getCountryById(props: GetCountryByIdPropsType): Promise<CountryType> {
    const url = routerHelper.getUrlWithParameters('/api/settings/getCountryById', props);
    const response = await sendServerRequest('GET', url);
    return response as CountryType;
}

// --------- setCountry ------------

/**
 * Ország mentése
 */
async function setCountry(props: CountryFormDataToSaveType): Promise<boolean> {
    const response = await sendServerRequest('POST', '/api/settings/setCountry', props);
    return response as boolean;
}

// --------- SETTLEMENTS ---------------
// --------- getSettlements ------------

type GetSettlementsPropsType = {
    countryId?: number | null,
};

/**
 * Települések betöltése. Opcionálisan ország szerint szűrve.
 */
async function getSettlements(props: GetSettlementsPropsType): Promise<Array<any>> {
    const url = routerHelper.getUrlWithParameters('/api/settings/getSettlements', props);
    const response = await sendServerRequest('GET', url);
    return response as Array<any>;
}

// --------- deleteSettlement ------------

type DeleteSettlementPropsType = {
    id: number,
};

/**
 * Település törlése
 */
async function deleteSettlement(props: DeleteSettlementPropsType): Promise<boolean> {
    const response = await sendServerRequest('DELETE', '/api/settings/deleteSettlement', props);
    return response as boolean;
}

// --------- getSettlementById ------------

type GetSettlementByIdPropsType = {
    id: number,
};

/**
 * Település betöltése id alapján
 */
async function getSettlementById(props: GetSettlementByIdPropsType): Promise<SettlementType> {
    const url = routerHelper.getUrlWithParameters('/api/settings/getSettlementById', props);
    const response = await sendServerRequest('GET', url);
    return response as SettlementType;
}

// --------- setSettlement ------------

/**
 * Település mentése
 */
async function setSettlement(props: SettlementFormDataToSaveType): Promise<boolean> {
    const response = await sendServerRequest('POST', '/api/settings/setSettlement', props);
    return response as boolean;
}

// --------- JOB POSITIONS --------------
// --------- getJobPositions ------------

/**
 * Beosztások betöltése
 */
async function getJobPositions(props: any): Promise<Array<any>> {
    const url = routerHelper.getUrlWithParameters('/api/settings/getJobPositions', props);
    const response = await sendServerRequest('GET', url);
    return response as Array<any>;
}

// --------- deleteJobPosition ------------

type DeleteJobPositionPropsType = {
    id: number,
};

/**
 * Beosztások törlése
 */
async function deleteJobPosition(props: DeleteJobPositionPropsType): Promise<boolean> {
    const response = await sendServerRequest('DELETE', '/api/settings/deleteJobPosition', props);
    return response as boolean;
}

// --------- getJobPositionById ------------

type GetJobPositionByIdPropsType = {
    id: number,
};

/**
 * Beosztás betöltése id alapján
 */
async function getJobPositionById(props: GetJobPositionByIdPropsType): Promise<JobPositionType> {
    const url = routerHelper.getUrlWithParameters('/api/settings/getJobPositionById', props);
    const response = await sendServerRequest('GET', url);
    return response as JobPositionType;
}

// --------- setSettlement ------------

/**
 * Beosztás mentése
 */
async function setJobPosition(props: JobPositionFormDataToSaveType): Promise<boolean> {
    const response = await sendServerRequest('POST', '/api/settings/setJobPosition', props);
    return response as boolean;
}

export const systemSettingsService = {
    getSystemSettings,
    setSystemSettings,
    //users
    getUsers,
    deleteUser,
    getUserById,
    setUserData,
    //rights
    getRightsWithGroups,
    getRightGroupById,
    setRightGroup,
    //countries
    getCountries,
    deleteCountry,
    getCountryById,
    setCountry,
    //settlements
    getSettlements,
    deleteSettlement,
    getSettlementById,
    setSettlement,
    //job positions
    getJobPositions,
    deleteJobPosition,
    getJobPositionById,
    setJobPosition,
};
