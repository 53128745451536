import { useLayoutEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { SearchDataListItem, Simpleselect, useAlert } from 'theRising-baseComponents';
import { useTranslate } from '@i18n';
import { dataListService } from '@services';

type JobPositionSimpleSelectPropsType = {
    onChange: (selectedItem: SearchDataListItem | null) => void;
    dataList?: Array<SearchDataListItem>;
    selectedKey?: number | string | undefined;
};

export default function JobPositionSimpleSelect(props: JobPositionSimpleSelectPropsType): React.ReactElement {
    const { onChange, dataList, selectedKey } = props;
    const { errorAlert } = useAlert();
    const { translater } = useTranslate();
    const [jobPositions, setJobPositions] = useState<Array<SearchDataListItem>>([]);

    //adatlista betöltése
    useLayoutEffect(() => {
        let isCancelled = false;

        try {
            //ha kívülről kapja meg az adatlistát
            if (!isEmpty(dataList) && !isCancelled) {
                setJobPositions((currentState: Array<SearchDataListItem>) => {
                    if (!isEmpty(currentState)) {
                        return currentState;
                    }
                    return dataList || [];
                });
                return;
            }

            (async () => {
                let jobPositionList = await dataListService.getJobPositions();
                jobPositionList = jobPositionList?.map((resultItem: any) => ({
                    key: resultItem.id,
                    value: resultItem.captionKey ? translater(resultItem.captionKey, resultItem.name) : resultItem.name,
                    resultItem,
                }));

                if (!isCancelled && jobPositionList) {
                    setJobPositions(jobPositionList);
                }
            })();
        } catch (error) {
            errorAlert(translater('default.unsuccessfulOperation', 'Unsuccessful operation'));
        }

        return () => {
            isCancelled = true;
        };
    }, [dataList, errorAlert, translater]);

    return (
        <Simpleselect
            selectedKey={selectedKey}
            dataList={jobPositions}
            onChange={onChange}
        />
    );
}
