import clsx from 'clsx';
import { isFunction } from 'lodash';
import { Svg } from 'theRising-baseComponents';
import styles from './menuItem.module.scss';

type MenuItemPropsType = {
    iconId: string,
    text: string,
    onClick: () => void,
};

export default function MenuItem(props: MenuItemPropsType): React.ReactElement {
    const { iconId, text, onClick } = props;

    const onClickItem = () => {
        if (isFunction(onClick)) {
            document.body.click();
            onClick();
        }
    };

    return (
        <div className={styles.menuItem} onClick={onClickItem} role="button" tabIndex={0}>
            <Svg iconId={iconId} className={styles.menuItemIcon} />
            <span className={clsx(styles.menuItemText, styles.disableSelectionColor)}>
                {text}
            </span>
        </div>
    );
}
