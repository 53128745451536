import { memo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { Svg } from 'theRising-baseComponents';
import { useTranslate } from '@i18n';
import { useAuth } from '@screens/login/authProvider/AuthProvider';
import { routerHelper } from '@modules';
import { CONTACT_INFORMATION, DASHBOARD, HOME, LEAVE_MANAGEMENT, TIME_TRACKING, TRAVEL_LOG } from '@shared/contents';
import styles from './sideMenu.module.scss';
import SideMenuItem from './sideMenuItem/SideMenuItem';
import Spaces from './spaces/Spaces';

function SideMenu(): React.ReactElement {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const { translater } = useTranslate();
    const { systemSettings } = useAuth();
    const location = useLocation().pathname;

    return (
        <div className={clsx(styles.sideMenu, isCollapsed && styles.collapsed)}>
            <div
                className={styles.collapseButton}
                title={isCollapsed ? translater('sideMenu.expandeSidebar', ' Expande sidebar') : translater('sideMenu.collapseSidebar', ' Collapse sidebar')}
                onClick={() => setIsCollapsed(currentState => !currentState)}
                role="button"
                tabIndex={0}
            >
                <Svg
                    iconId={isCollapsed ? 'icon-table_head-show' : 'icon-table_head-hide'}
                    className={styles.collapseIcon}
                />
            </div>

            <div className={clsx(styles.sidebarHeader, styles.disableSelectionColor)}>
                {!isCollapsed && (
                    <span className={styles.overflowDots}>
                        {systemSettings?.companyName}
                    </span>
                )}
            </div>

            <div className={styles.sidebarFixMenuList}>
                <SideMenuItem
                    iconId="icon-stock"
                    text={translater('pageTitle.home', 'Home')}
                    //href={routerHelper.getContentUrl(HOME, HOME.CONTENTS.INDEX)}
                    href="/"
                    isCollapsed={isCollapsed}
                    isSelected={location === routerHelper.getContentUrl(HOME, HOME.CONTENTS.INDEX) || location === '/'}
                />
                <SideMenuItem
                    iconId="icon-dashboard"
                    text={translater('pageTitle.dashboards', 'Dashboards')}
                    href={routerHelper.getContentUrl(DASHBOARD, DASHBOARD.CONTENTS.INDEX)}
                    isCollapsed={isCollapsed}
                    isSelected={location === routerHelper.getContentUrl(DASHBOARD, DASHBOARD.CONTENTS.INDEX)}
                />
                <SideMenuItem
                    iconId="icon-vacation"
                    text={translater('pageTitle.leaveManagement', 'Leave management')}
                    href={routerHelper.getContentUrl(LEAVE_MANAGEMENT, LEAVE_MANAGEMENT.CONTENTS.INDEX)}
                    isCollapsed={isCollapsed}
                    isSelected={location === routerHelper.getContentUrl(LEAVE_MANAGEMENT, LEAVE_MANAGEMENT.CONTENTS.INDEX)}
                /> {/* Szabadság nyilvántartás */}
                <SideMenuItem
                    iconId="icon-vehicle"
                    text={translater('pageTitle.travelLog', 'Travel log')}
                    href={routerHelper.getContentUrl(TRAVEL_LOG, TRAVEL_LOG.CONTENTS.INDEX)}
                    isCollapsed={isCollapsed}
                    isSelected={location === routerHelper.getContentUrl(TRAVEL_LOG, TRAVEL_LOG.CONTENTS.INDEX)}
                /> {/* Útnyilvántartás */}

                {/* TODO: ezt még ki kell találni */}
                <SideMenuItem
                    iconId="icon-time"
                    text={translater('pageTitle.timeTracking', 'Time tracking')}
                    href={routerHelper.getContentUrl(TIME_TRACKING, TIME_TRACKING.CONTENTS.INDEX)}
                    isCollapsed={isCollapsed}
                    isSelected={location === routerHelper.getContentUrl(TIME_TRACKING, TIME_TRACKING.CONTENTS.INDEX)}
                /> {/* Időnyílvántartás */}
                <SideMenuItem
                    iconId="icon-contact-list"
                    text={translater('pageTitle.contactInformation', 'Contact information')}
                    href={routerHelper.getContentUrl(CONTACT_INFORMATION, CONTACT_INFORMATION.CONTENTS.INDEX)}
                    isCollapsed={isCollapsed}
                    isSelected={location === routerHelper.getContentUrl(CONTACT_INFORMATION, CONTACT_INFORMATION.CONTENTS.INDEX)}
                /> {/* Elérhetőségek */}
            </div>

            <Spaces isCollapsed={isCollapsed} />
        </div>
    );
}

export default memo(SideMenu);
