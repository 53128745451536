import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { first, isEmpty, last, size } from 'lodash';
import { Button, Input, LoadingSpinner, useAlert } from 'theRising-baseComponents';
import { dateTimeUtils } from '@utils';
import { useTranslate } from '@i18n';
import { userService } from '@services';
import BackButton from '@baseComponents/buttons/backButton/BackButton';
import { GENERAL, KEY_CODE } from '@constants';
import styles from './resetPasswordPanel.module.scss';

const tokenSize = 32;
const passwordMinCharacter = GENERAL.PASSWORD_MIN_CHARACTER;

export default function ResetPasswordPanel(): React.ReactElement {
    const navigate = useNavigate();
    const location = useLocation();
    const { t, translater } = useTranslate();
    const { errorAlert, successAlert } = useAlert();
    const [isLoading, setIsLoading] = useState(false);
    const [isExpiredDate, setIsExpiredDate] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const formValuesRef = useRef({
        password: '',
        passwordAgain: '',
        resetToken: getResetTokenFromHash(location.hash) || '',
    });

    function setFormValue(key, value) {
        formValuesRef.current[key] = value;
    }

    useEffect(() => {
        const resetToken = getResetTokenFromHash(location.hash);
        setFormValue('resetToken', resetToken);
        const expireDateTimestamp = getTimestampFromHash(location.hash);
        const currentDateTimeInTimestamp = dateTimeUtils.getUTCFormat(dateTimeUtils.getDefaultDateTimeNow() as string) as number;

        if (expireDateTimestamp && expireDateTimestamp < currentDateTimeInTimestamp) {
            setIsExpiredDate(true);
        } else if (isEmpty(resetToken)) {
            setIsExpiredDate(true);
        }
    }, [location.hash]);

    async function onSubmit(event?: any) {
        try {
            setIsLoading(true);
            if (event) {
                event.preventDefault();
            }

            const { password, passwordAgain, resetToken } = formValuesRef.current;

            //hibaüzenetek
            if (password !== passwordAgain) {
                setErrorMessage(translater('resetPassword.passwordsNotMatch', 'The two passwords do not match!'));
                return;
            }

            const numberPattern = /[0-9]/;
            const uppercasePattern = /[A-Z]/;
            if (password.length < passwordMinCharacter || !numberPattern.test(password) || !uppercasePattern.test(password)) {
                setErrorMessage(translater(
                    'resetPassword.passwordError',
                    'The password must be at least 8 characters long and include at least one number, one lowercase letter, and one uppercase letter.'
                ));
                return;
            }

            const newPasswordProps = {
                resetToken,
                password,
            };
            const isSuccessReset = await userService.setNewPassword(newPasswordProps);

            if (isSuccessReset) {
                successAlert(translater('resetPassword.successfullPasswordChange', 'Successful password change!'));
                navigate('/login');
            } else {
                errorAlert(translater('default.unsuccessfulOperation', 'Unsuccessful operation'));
            }
        } catch (error) {
            errorAlert(translater('default.unsuccessfulOperation', 'Unsuccessful operation'));
        } finally {
            setIsLoading(false);
        }
    }

    //vissza gomb - login képernyőre navigál
    const onClickBackToLogin = () => {
        navigate('/login');
    };

    return (
        <div className={styles.resetPasswordPanel}>
            <LoadingSpinner value={isLoading === false} />
            {isExpiredDate ? (
                <>
                    <div className={styles.titleWrapper}>
                        <BackButton onClick={onClickBackToLogin} title={translater('login.backToLogin', 'Back to login')} />
                        <h2 className={styles.resetYourPassword}>
                            {t('resetPassword.enterNewPassword', 'Enter new password')}
                        </h2>
                    </div>
                    <div>
                        <h4 className={styles.expiredTokenText}>
                            {t('resetPassword.expiredLinkText', 'The link associated with the password reset request has expired. Please return to the login interface to request a new link.')}
                        </h4>
                    </div>
                </>
            ) : (
                <form onSubmit={onSubmit}>
                    <div className={styles.titleWrapper}>
                        <BackButton onClick={onClickBackToLogin} title={translater('login.backToLogin', 'Back to login')} />
                        <h2 className={styles.resetYourPassword}>
                            {t('resetPassword.enterNewPassword', 'Enter new password')}
                        </h2>
                    </div>

                    <div className={styles.labelWrapper}>
                        <span className={styles.label}>
                            {t('resetPassword.password', 'Password')}
                        </span>
                    </div>
                    <div className={styles.formInput}>
                        <Input
                            type="password"
                            inputGroupClassName={styles.passwordInput}
                            onBlur={value => setFormValue('password', value)}
                            required
                            isAutoFocus
                            onKeyDown={(_value, event) => (event.code === KEY_CODE.ENTER || event.code === KEY_CODE.NUMPAD_ENTER) && onSubmit()}
                        />
                    </div>

                    <div className={styles.labelWrapper}>
                        <span className={styles.label}>
                            {t('resetPassword.passwordAgain', 'Password again')}
                        </span>
                    </div>
                    <div className={styles.formInput}>
                        <Input
                            type="password"
                            inputGroupClassName={styles.passwordInput}
                            onBlur={value => setFormValue('passwordAgain', value)}
                            required
                            onKeyDown={(_value, event) => (event.code === KEY_CODE.ENTER || event.code === KEY_CODE.NUMPAD_ENTER) && onSubmit()}
                        />
                    </div>

                    {!isEmpty(errorMessage) && (
                        <span className={styles.errorMessage}>
                            {errorMessage}
                        </span>
                    )}

                    <div className={styles.saveButtonWrapper}>
                        <Button text={t('default.save', 'Save')} isSubmitButton />
                    </div>
                </form>
            )}
        </div>
    );
}

function getResetTokenFromHash(hash: string): string | null {
    const hashParts = hash?.slice(1)?.split('-');

    if (!isEmpty(hashParts)) {
        const token = first(hashParts);

        if (size(token) === tokenSize) {
            return token as string;
        }
    }

    return null;
}

function getTimestampFromHash(hash: string): number | null {
    const hashParts = hash.split('-');

    const hashPartCount = 2;
    if (!isEmpty(hashParts) && size(hashParts) === hashPartCount) {
        const timestamp = last(hashParts);

        return +(timestamp as string);
    }

    return null;
}
